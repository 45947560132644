import { RevImgFull } from "../../../components/common/RevImg";
import NewProjectModal from "./NewProjectModal";
import { RevModalOpaqueFullScreen } from "../../../components/ModalDisplay";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { AnchorButton, SpanButton } from "../../../components/common/Buttons";
import Helaas from "../../../components/aboNodig";
import { colorSchemes } from "../projectConstants";
import * as pages from "../../../navigation";
const NewProjectList = ({
  beheerObjecten,
  createProject,
  updateExploitationVtw,
  exploitationData,
  exploitationDataLoading,
  isAuthorized,
}) => {
  const toggleModalTarget = (target) => {
    document.getElementById(target).classList.toggle("is-hidden");
  };
  const toggleModal = (e) => {
    const target = e.currentTarget.dataset.target;
    toggleModalTarget(target);
  };

  const openModalTarget = (target) => {
    document.getElementById(target).classList.toggle("is-hidden");
    updateExploitationVtw(target.replace("-modal", ""));
  };
  const openModal = (e) => {
    const target = e.currentTarget.dataset.target;
    openModalTarget(target);
  };

  const size = beheerObjecten.length - 1;

  let getLeft = (i) => {
    let indexToUse = i - 1;
    if (indexToUse < 0) {
      indexToUse = size;
    }

    const toClose = `${beheerObjecten[i].vtwCode}-modal`;
    const toOpen = `${beheerObjecten[indexToUse].vtwCode}-modal`;
    toggleModalTarget(toClose);
    openModalTarget(toOpen);
  };

  const getRight = (i) => {
    let indexToUse = i + 1;
    if (indexToUse > size) {
      indexToUse = 0;
    }
    const toClose = `${beheerObjecten[i].vtwCode}-modal`;
    const toOpen = `${beheerObjecten[indexToUse].vtwCode}-modal`;
    toggleModalTarget(toClose);
    openModalTarget(toOpen);
  };

  return (
    <div className="container pb-4 rev-flex-container rev-boxes">
      {beheerObjecten.map((beheerObject, i) => {
        const highlightClass = colorSchemes[beheerObject.objectSoortId];
        return (
          <div
            key={beheerObject.id}
            className="rev-flex-item box is-shadowless "
            data-target={`${beheerObject.vtwCode}-modal`}
            onClick={openModal}
          >
            <h1
              className={`has-text-centered has-text-weight-bold rev-title-newproject ${highlightClass}`}
            >
              {beheerObject.searchVtwCode}
            </h1>
            <RevModalOpaqueFullScreen id={`${beheerObject.vtwCode}-modal`}>
              <NewProjectModal
                beheerObject={beheerObject}
                createProject={createProject}
                exploitationData={exploitationData}
                exploitationDataLoading={exploitationDataLoading}
                toggleModal={toggleModal}
                objectIndex={i}
                navigateLeft={getLeft}
                navigateRight={getRight}
                dataTarget={`${beheerObject.vtwCode}-modal`}
                highlightClass={highlightClass}
              />
            </RevModalOpaqueFullScreen>
            <LazyLoadComponent visibleByDefault={false}>
              {beheerObject.image && (
                <div className="has-text-centered">
                  <RevImgFull
                    src={beheerObject.image.src}
                    alt={beheerObject.image.altText}
                    className="modal-button rev-img-fit"
                    data-target="modal-image2"
                  />
                </div>
              )}
            </LazyLoadComponent>
            <div className="content rev-hide-overflow">
              <h4 className="p-2 mb-2 is-size-5 has-text-weight-medium has-text-centered">
                {beheerObject.description}
              </h4>
              <table className="table is-size-6 has-background-white-ter is-mobile">
                <tbody>
                  <tr>
                    <td
                      className="has-text-right has-text-weight-bold"
                      style={{ border: "none" }}
                    >
                      Bouwjaar:
                    </td>
                    <td style={{ border: "none" }}>{beheerObject.bouwjaar}</td>
                  </tr>
                  <tr>
                    <td
                      className="has-text-right has-text-weight-bold"
                      style={{ border: "none" }}
                    >
                      BVO:
                    </td>
                    <td style={{ border: "none" }}>
                      {beheerObject.brutoVloerOppervlak}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="has-text-right has-text-weight-bold"
                      style={{ border: "none" }}
                    >
                      Object type:
                    </td>
                    <td style={{ border: "none" }}>
                      {beheerObject.objectType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="has-text-right has-text-weight-bold"
                      style={{ border: "none" }}
                    >
                      Object soort:
                    </td>
                    <td style={{ border: "none" }}>
                      {beheerObject.objectSoort}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="has-text-centered mb-2 is-vbottom">
                <SpanButton text="Kies object" className="is-rounded" />
              </div>
            </div>
          </div>
        );
      })}
      {isAuthorized && (
        <div
          className="rev-flex-item box is-shadowless "
          //onClick={openModal}
        >
          <h1
            className={`has-text-centered has-text-weight-bold rev-title-newproject rev-highlight-notfound`}
          >
            Niet gevonden wat u zocht?
          </h1>
          <LazyLoadComponent visibleByDefault={false}>
            <div className="has-text-centered">
              <RevImgFull
                src="images/object-not-found.jpg"
                alt="gebouw niet gevonden"
                className="modal-button rev-img-fit"
                data-target="modal-image2"
              />
            </div>
          </LazyLoadComponent>
          <div className="content rev-hide-overflow">
            <h4 className="p-2 mb-2 is-size-5 has-text-weight-medium has-text-centered">
              Probeer de quikstart of mail ons:
            </h4>
            <table className="table is-size-6 has-background-white-ter is-mobile">
              <tbody>
                <tr>
                  <td style={{ border: "none" }} className="has-text-right">
                    <AnchorButton
                      text="Quickstart"
                      className="is-rounded button is-primary"
                      href={pages.quickStartAddress}
                    />
                  </td>
                  <td className="has-text-left" style={{ border: "none" }}>
                    <AnchorButton
                      text={"Email"}
                      className="is-rounded button is-primary"
                      href={"mailto:info@evao.nl"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="has-text-centered mb-2 is-vbottom"></div>
          </div>
        </div>
      )}

      {!isAuthorized && (
        <Helaas
          title="Helaas, u heeft een abonnement nodig om alle project gebouwen te zien."
          content="Om toegang te krijgen tot alle project gebouwen heeft u minimaal een basis abonnement nodig."
        />
      )}
    </div>
  );
};

export default NewProjectList;
