import {
  ClearSessionKey,
  RetrieveFromSession,
  StoreInSession,
} from "../../modules/revSessionStorage";

import * as projProps from "./projectConstants";

export const onderhoudskostenDisplay = "onderhoudskostenDisplay";
export const kenmerkenDisplay = "kenmerkenDisplay";
export const canSaveProject = "CanSaveProject";
export const shouldSaveForReports = "shouldSaveForReports";
export const rapportageDisplay = "rapportageDisplay";
export const projectImageUrl = "projectImageUrl";
export const projectServerImageUrl = "projectServerImageUrl";

export const ClearProjectSessionData = () => {
  ClearSessionKey(onderhoudskostenDisplay);
  ClearSessionKey(kenmerkenDisplay);
  ClearSessionKey(canSaveProject);
  ClearSessionKey(rapportageDisplay);
  ClearSessionKey(projectImageUrl);
  ClearSessionKey(projectServerImageUrl);
};

export const GetRapportageDisplay = () => {
  // set the default here? this is a project
  return RetrieveFromSession(rapportageDisplay, {
    algemeenToggleBox: true,
  });
};

export const SetRapportageDisplay = (value) => {
  // set the default here? this is a project
  StoreInSession(rapportageDisplay, value);
};

export const GetKenmerkenDisplay = () => {
  // set the default here? this is a project
  return RetrieveFromSession(kenmerkenDisplay, {
    locatieToggleBox: false,
  });
};

export const SetProjectServerImage = (value) => {
  StoreInSession(projectServerImageUrl, value);
};

export const GetProjectServerImage = () => {
  return RetrieveFromSession(projectServerImageUrl);
};

export const ClearProjectServerImage = () => {
  ClearSessionKey(projectServerImageUrl);
};

export const SetProjectImage = (value) => {
  StoreInSession(projectImageUrl, value);
};

export const GetProjectImage = () => {
  return RetrieveFromSession(projectImageUrl);
};

export const ClearProjectImage = () => {
  ClearSessionKey(projectImageUrl);
};

export const SetKenmerkenDisplay = (value) => {
  // set the default here? this is a project
  StoreInSession(kenmerkenDisplay, value);
};

export const GetOnderhoudskostenDisplay = () => {
  // set the default here? this is a project
  return RetrieveFromSession(onderhoudskostenDisplay, {
    basisBouwdelenToggleBox: false,
  });
};

export const SetOnderhoudskostenDisplay = (value) => {
  // set the default here? this is a project
  StoreInSession(onderhoudskostenDisplay, value);
};

export const GetShouldSaveForReports = () => {
  return RetrieveFromSession(shouldSaveForReports, false);
};

export const SetShouldSaveForReports = (value) => {
  return StoreInSession(shouldSaveForReports, value);
};

export const GetCanSave = () => {
  return RetrieveFromSession(canSaveProject, false);
};

const projectSaveRequiredForMjop = [
  projProps.bvo,
  projProps.go,
  projProps.inhoud,
];

export const SetCanSave = (value, propertyName) => {
  // set the default here? this is a project
  StoreInSession(canSaveProject, value);
  if (!value) {
    SetShouldSaveForReports(value);
  } else if (
    propertyName &&
    projectSaveRequiredForMjop.includes(propertyName)
  ) {
    SetShouldSaveForReports(value);
  }
};
