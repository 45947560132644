import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RevScrollToTop = ({ scrollToTopEvent }) => {
  return (
    <div
      className="box rev-floating-button rev-floating-button-bottomright"
      onClick={scrollToTopEvent}
    >
      <span className="icon fa-2xl" style={{ color: "#ff8c00" }}>
        <FontAwesomeIcon icon={faArrowUp} />
      </span>
    </div>
  );
};

export default RevScrollToTop;
