import { useEffect } from "react";
import { signinRedirect } from "../oidc/userService";

const RedirectSignin = () => {
  useEffect(() => {
    signinRedirect();
  });
  return <div>Redirecting...</div>;
};

export default RedirectSignin;
