import { useEffect } from "react";
import { AnchorButton } from "../../components/common/Buttons";
const KengetallenContent = ({ categories, filterListOnChange }) => {
  const onButtonClick = () => {
    let contents = document.getElementById(`side-main`);
    contents.classList.toggle("rev-side-page-hidden");
    let placeholder = document.getElementById(`side-pageholder`);
    placeholder.classList.toggle("rev-side-page-hidden");
  };

  useEffect(() => {
    setTimeout(function () {
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      const elements = document.getElementsByClassName(
        `rev-side-page-pageholder`
      );
      Array.from(elements).map((knop, i) => {
        return knop.dispatchEvent(clickEvent);
      });
    }, 2000);
  }, []);

  return (
    <div>
      {categories.length > 1 && (
        <>
          <div
            id={`side-pageholder`}
            className={`rev-side rev-side-pageholder rev-right-slide`}
          >
            {/* eslint-disable-next-line  */}
            <a
              className="rev-side-page-pageholder is-size-6"
              onClick={onButtonClick}
            >
              Filter &nbsp; &nbsp;
            </a>
          </div>
          <div
            id={`side-main`}
            className={`rev-side rev-side-main rev-right-slide`}
          >
            <h2 className="rev-side-article subtitle">Hoofd groepen</h2>

            {categories.map((article, x) => {
              return (
                <div key={x} className="rev-side-article">
                  <AnchorButton
                    className={`has-text-primary ${
                      article.value ? "has-text-warning" : ""
                    }`}
                    onClick={(e) => {
                      onButtonClick(e);
                      filterListOnChange(e);
                    }}
                    text={article.name}
                  />
                  <hr className="rev-side-article-divider"></hr>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default KengetallenContent;
