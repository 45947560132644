import {
  ClearSessionKey,
  RetrieveFromSession,
  StoreInSession,
} from "../../modules/revSessionStorage";

export const toggleFilter = "toggleFilter";

export const SetToggleBoxIsClosed = (value) => {
  StoreInSession(toggleFilter, value);
};

export const GetToggleBoxIsClosed = () => {
  return RetrieveFromSession(toggleFilter);
};

export const ClearToggleBoxIsClosed = () => {
  ClearSessionKey(toggleFilter);
};
