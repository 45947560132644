import { Link } from "react-router-dom";

function ArticleCategory({ article }) {
  return (
    <Link to={article.cmsPath}>
      <div
        className="card medium"
        style={{
          height: "100%",
        }}
      >
        {article.image && (
          <div className="card-image">
            <figure className="image">
              <img
                style={{
                  height: "180px",
                  width: "auto",
                  objectFit: "cover",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={article.image.src}
                alt={article.image.src}
              />
            </figure>
          </div>
        )}

        <div className="card-content">
          <div className="content">
            <p
              style={{ minHeight: "50px" }}
              className="title is-5 is-size-6-touch has-text-weight-bold has-text-centered"
            >
              {article.title}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ArticleCategory;
