import * as states from "../redux/states";
import { AnchorButtonPrimary, AnchorButtonSecondary } from "./common/Buttons";
export default function UserLogin(props) {
  return (
    <>
      {props.userData !== null && (
        <AnchorButtonSecondary onClick={props.logout} text="Uitloggen" />
      )}

      {props.userDataState === states.succeeded && props.userData === null && (
        <AnchorButtonPrimary
          onClick={props.login}
          text="Inloggen"
          className={"button is-primary selenium-inloggen"}
        />
      )}
    </>
  );
}
