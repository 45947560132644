import { Link } from "react-router-dom";

function BreadCrumb({ breadcrumarr, paths, allLinks }) {
  const length = breadcrumarr.length - 1;
  return (
    <div className="section">
      <nav className="is-small">
        <div className="container">
          <div
            className="breadcrumb is-size-7-mobile	rev-breadcrumb"
            aria-label="breadcrumbs"
          >
            <ul>
              {breadcrumarr.map((content, index) => {
                if (length === index && !allLinks) {
                  return (
                    <li className="is-active" key={index}>
                      {/* eslint-disable-next-line */}
                      <a href="#">{paths[index]}</a>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <Link to={content}>{paths[index]}</Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default BreadCrumb;
