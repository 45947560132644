import { useEffect } from "react";
import { signinRedirectCallback, returnUrlKey } from "./userService";
import { useNavigate } from "react-router-dom";
import { RetrieveFromSession } from "../modules/revSessionStorage";

function SigninOidc() {
  const returnUrl = RetrieveFromSession(returnUrlKey);
  const navigate = useNavigate();
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
    }
    signinAsync()
      .then((x) => {
        const timer = setTimeout(() => {
          if (returnUrl) {
            navigate(returnUrl);
          } else {
            navigate("/");
          }
        }, 100);
        return () => clearTimeout(timer);
      })
      .catch((e) => {
        // in debug mode signinRedirectCallback can be fired twice (due to how the demo evironment works.)
        switch (e.message) {
          case "No matching state found in storage":
            // development issue, it seems. Ignore for now
            break;
          case "access_denied":
            // login canceled
            navigate("/");
            break;
          case "No state in response":
            // error is ignored
            //console.log(e);
            break;
          default:
            console.error(e);
            break;
        }
      });
  }, [navigate, returnUrl]);

  return <div>Redirecting...</div>;
}

export default SigninOidc;
