import { useDispatch, useSelector } from "react-redux";
import {
  fetchCmsVersion,
  finishedUpdate,
  selectVersionIsChanged,
} from "../redux/slices/generalSlice";
import React, { useEffect } from "react";
import { resetTypeContent } from "../redux/slices/cmsTypeContentIndexSlice";
import { resetCmsContent } from "../redux/slices/cmsSearchDataSlice";
import { resetCmsMenu } from "../redux/slices/cmsNavMenuSlice";

export const CmsComponentWrapper = (props) => {
  const dispatcher = useDispatch();
  useEffect(() => {
    dispatcher(fetchCmsVersion());
  }, [dispatcher]);
  const versionIsUpdated = useSelector(selectVersionIsChanged);
  useEffect(() => {
    if (versionIsUpdated) {
      dispatcher(resetTypeContent());
      dispatcher(resetCmsContent());
      dispatcher(resetCmsMenu());
      dispatcher(finishedUpdate());
    }
  });

  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        dispatcher,
        ...props,
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};

//https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children

export default CmsComponentWrapper;
