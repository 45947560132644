import { RevImgFull } from "../../components/common/RevImg";
import * as links from "../../externalLinks";
import { LazyLoadComponent } from "react-lazy-load-image-component";
const HomeDetails = () => {
  return (
    <LazyLoadComponent visibleByDefault={false}>
      <section className="section">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column has-text-right has-text-left-mobile">
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Zoek op gebouwtype
              </h2>
              <p>
                Zoek op gebouwtype door het invoeren van de postcode en
                huisnummer van uw vastgoedobject óf zoek en filter in het
                overzicht referentiegebouwen.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                MJOP zelf opstellen
              </h2>
              <p>
                In REV® kunt u voor uw project een meerjaren onderhoudsplanning
                (MJOP) opstellen. Dit kan tot wel 99 jaar vooruit. Exporteer
                naar PDF of Word voor eigen gebruik.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Inzicht in bouwgebreken
              </h2>
              <p>
                REV® bevat een bouwgebreken database, gesorteerd volgens de
                NL-SfB code. Krijg inzicht in de meest voorkomende bouwgebreken
                en de verwachte herstelkosten.
              </p>
            </div>
            <div className="column has-text-centered">
              <a href={links.youtubePlaylist} target="_blank" rel="noreferrer">
                <RevImgFull
                  src="images/400-tablet-scherm-slideshow-web.gif"
                  alt="Tablet slideshow abonnementsvormen"
                  className="image rev-gif"
                />
              </a>
              <br></br>
              {/* eslint-disable-next-line  */}
              <a
                className="button is-primary"
                href={links.youtubePlaylist}
                target={"_blank"}
              >
                Bekijk instructievideo's
              </a>
            </div>
            <div className="column has-text-left">
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Kennisbank aan informatie
              </h2>
              <p>
                De kennisbank bevat informatie over o.a. actuele OZB tarieven en
                rioollasten, opstalverzekeringen en herbouwwaarden, toelichting
                op verduurzamen en meer.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Kengetallen database
              </h2>
              <p>
                Actuele kostenkengetallen voor bouwkundige alsook voor
                installatietechnische bouwdelen. Onderhoudshandelingen zijn
                gerubriceerd conform de NL-SfB methode.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                API koppeling kengetallen
              </h2>
              <p>
                Door middel van een API is het mogelijk om de kengetallen uit
                REV® rechtstreeks in te lezen in uw taxatiesoftware.
              </p>
            </div>
          </div>
        </div>
      </section>
    </LazyLoadComponent>
  );
};

export default HomeDetails;
