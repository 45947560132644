import * as states from "../states";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: states.idle,
  error: null,
};

const createDownload = (response, fileName) => {
  // file download trick. it's weird. solution is from 2015. maybe update it?
  // https://stackoverflow.com/questions/31214677/download-a-reactjs-object-as-a-file
  const blob = response.data;
  const data = new Blob([blob], { type: blob.type });
  const url = window.URL.createObjectURL(data);
  let tempLink = document.createElement("a");
  tempLink.href = url;

  if (response.fileName) {
    tempLink.setAttribute("download", response.fileName);
  } else if (fileName) {
    tempLink.setAttribute("download", fileName);
  }

  tempLink.click();
};

// Thunks!
export const downloadMjop = createAsyncThunk(
  "downloadMjop",
  async (postData) => {
    const response = await client.download(
      "api/project/downloadMjop",
      postData
    );
    createDownload(response);
  }
);

export const downloadProjectWordReport = createAsyncThunk(
  "downloadProjectWordReport",
  async (postData) => {
    const response = await client.download(
      "api/report/GetProjectWordReport",
      postData
    );
    createDownload(response);
  }
);

export const downloadProjectPdfReport = createAsyncThunk(
  "downloadProjectPdfReport",
  async (postData) => {
    const response = await client.download(
      "api/report/GetProjectPdfReport",
      postData
    );
    createDownload(response);
  }
);

export const downloadUserFile = createAsyncThunk(
  "downloadUserFile",
  async (fileName) => {
    const response = await client.download(
      `/api/user/DownloadUserfile`,
      fileName
    );
    createDownload(response);
  }
);

export const deleteUserFile = createAsyncThunk(
  "deleteUserFile",
  async ({ fileName }) => {
    const response = await client.delete(
      `/api/user/DeleteUserfile/${fileName}`
    );
    return JSON.stringify(response.data || "{}");
  }
);

const fileDownloadSlice = createSlice({
  name: "fileDownload",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(downloadUserFile.pending, (state, action) => {
        state.status = states.loading;
        state.error = null;
      })
      .addCase(downloadUserFile.fulfilled, (state, action) => {
        state.status = states.succeeded;
      })
      .addCase(downloadUserFile.rejected, (state, action) => {
        state.status = states.failed;
        const errorData = JSON.parse(action.error.message);
        state.error = errorData.errors;
      })
      .addCase(deleteUserFile.pending, (state, action) => {
        state.status = states.loading;
        state.error = null;
      })
      .addCase(deleteUserFile.fulfilled, (state, action) => {
        state.status = states.succeeded;
      })
      .addCase(deleteUserFile.rejected, (state, action) => {
        state.status = states.failed;
        const errorData = JSON.parse(action.error.message);
        state.error = errorData.errors;
      })
      .addCase(downloadMjop.pending, (state, action) => {
        state.status = states.loading;
        state.error = null;
      })
      .addCase(downloadMjop.fulfilled, (state, action) => {
        state.status = states.succeeded;
      })
      .addCase(downloadMjop.rejected, (state, action) => {
        state.status = states.failed;
        const errorData = JSON.parse(action?.error?.message);
        state.error = errorData.errors;
      })
      .addCase(downloadProjectWordReport.pending, (state, action) => {
        state.status = states.loading;
        state.error = null;
      })
      .addCase(downloadProjectWordReport.fulfilled, (state, action) => {
        state.status = states.succeeded;
      })
      .addCase(downloadProjectWordReport.rejected, (state, action) => {
        state.status = states.failed;
        const errorData = JSON.parse(action.error.message);
        state.error = errorData.errors;
      })
      .addCase(downloadProjectPdfReport.pending, (state, action) => {
        state.status = states.loading;
        state.error = null;
      })
      .addCase(downloadProjectPdfReport.fulfilled, (state, action) => {
        state.status = states.succeeded;
      })
      .addCase(downloadProjectPdfReport.rejected, (state, action) => {
        state.status = states.failed;
        const errorData = JSON.parse(action.error.message);
        state.error = errorData.errors;
      });
  },
});

export default fileDownloadSlice.reducer;

export const selectFileDownloadState = (state) => state.fileDownload.status;
export const selectFileDownloadErrorMessage = (state) =>
  state.fileDownload.error;
