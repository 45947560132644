import { useDispatch, useSelector } from "react-redux";
import { FloatingButtonWrapper } from "../../../components/common/Buttons";
import { useLocation, useNavigate } from "react-router";
import {
  loadVtwData,
  selectVtwLoadStatus,
} from "../../../redux/slices/vtwSlice";
import { useEffect } from "react";
import * as states from "../../../redux/states";
import * as pages from "../../../navigation";
import { selectQuickStartAddressLoadStatus } from "../../../redux/slices/quickStartSlice";
import RevlogoLink from "../../../components/revlogoLink";

function QuickStartWrapper(props) {
  const addressLoadStatus = useSelector(selectQuickStartAddressLoadStatus);
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const location = useLocation();

  const currentState = useSelector(selectVtwLoadStatus);

  useEffect(() => {
    if (
      addressLoadStatus !== states.succeeded &&
      location.pathname.startsWith(pages.quickStart) &&
      location.pathname !== pages.quickStartAddress
    ) {
      navigate(pages.quickStartAddress);
    } else if (
      currentState === states.idle ||
      currentState === states.failedonce
    ) {
      dispatcher(loadVtwData());
    }
  }, [
    dispatcher,
    navigate,
    currentState,
    addressLoadStatus,
    location.pathname,
  ]);

  return (
    <>
      <section
        className="section rev-quickstart"
        style={{ overflowY: "scroll" }}
      >
        <FloatingButtonWrapper
          className={
            "button is-pulled-left is-medium has-text-center rev-floating-button rev-floating-button-topleft rev-floating-button-80x80"
          }
          style={{
            padding: "0px",
            border: "0px",
            backgroundColor: "transparent",
          }}
        >
          <RevlogoLink style={{ backgroundColor: "transparent" }} />
        </FloatingButtonWrapper>
        <div className="container has-text-centered pt-5">{props.children}</div>
      </section>
    </>
  );
}

export default QuickStartWrapper;
