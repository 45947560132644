import * as events from "../../custom-scripts/custom-javascript-events";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as states from "../../redux/states";
import * as pages from "../../navigation";
import { GetCmsType } from "../../modules/revCmsNavigation";

import {
  fetchCmsContentIndexScroll,
  selectCmsContentIndexByType,
  selectCmsContentIndexStateByType,
  selectCmsContentIndexFilterByType,
  selectCmsContentCategoriesByType,
  clearCmsSearch,
} from "../../redux/slices/cmsTypeContentIndexSlice";
import CmsIndex from "./cmsIndex";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { SetToggleBoxIsClosed, GetToggleBoxIsClosed } from "./cmsSession";
import { useLocation } from "react-router";
import { selectCalculationYear } from "../../redux/slices/cmsNavMenuSlice";
const CmsIndexManager = ({ dispatcher }) => {
  useEffect(() => {
    const customEvent = new Event(events.eventToggleBoxOnclick);
    document.dispatchEvent(customEvent);
    const customEventNoCopy = new Event(events.eventPreventCopyAmounts);
    document.dispatchEvent(customEventNoCopy);
  });

  const location = useLocation();
  const calculationYear = useSelector(selectCalculationYear);
  const cmsType = GetCmsType();
  const [currentCmsType, setCurrentCmsType] = useState();
  const [state, setState] = useState();
  const tempCategories = useSelector((state) => {
    return selectCmsContentCategoriesByType(state, cmsType);
  });

  const [categoryList, setCategories] = useState(tempCategories);

  const [filter, setFilter] = useState(
    useSelector((state) => {
      return selectCmsContentIndexFilterByType(state, cmsType);
    })
  );

  const tempstate = useSelector((state) => {
    return selectCmsContentIndexStateByType(state, cmsType);
  });

  useEffect(() => {
    if (cmsType !== currentCmsType && pages.cmsIndexSearch.includes(cmsType)) {
      dispatcher(clearCmsSearch({ cmsType }));
      SetToggleBoxIsClosed(cmsType === "kengetallen");
      setCurrentCmsType(cmsType);
      setFilter("");
      setCategories(tempCategories);
      setState(states.idle);
    } else {
      setState(tempstate);
    }
  }, [
    cmsType,
    dispatcher,
    setCurrentCmsType,
    tempCategories,
    currentCmsType,
    tempstate,
  ]);

  const filterListOnChange = (e) => {
    let tempFilters = JSON.parse(JSON.stringify(categoryList));
    const index = tempFilters.findIndex(
      (obj) => obj.name === e.target.dataset.target
    );
    tempFilters[index].value = e.target.checked;
    setCategories(tempFilters);
    setState(states.searchChanged);
  };

  const searchOnChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setFilter(lowerCase);
    setState(states.searchChanged);
  };

  useEffect(() => {
    if (state === states.idle) {
      setFilter("");
      dispatcher(
        fetchCmsContentIndexScroll({
          cmsType: cmsType,
          filter: filter,
          categories: categoryList,
          continueFromCurrentRecord: true,
        })
      );
    } else if (state === states.searchChanged) {
      const timer = setTimeout(() => {
        dispatcher(
          fetchCmsContentIndexScroll({
            cmsType: cmsType,
            filter: filter,
            categories: categoryList,
            continueFromCurrentRecord: false,
          })
        );
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [state, cmsType, filter, categoryList, dispatcher]);

  const index = useSelector((state) => {
    return selectCmsContentIndexByType(state, cmsType);
  });

  const loadMore = () => {
    dispatcher(
      fetchCmsContentIndexScroll({
        cmsType: cmsType,
        filter: filter,
        categories: categoryList,
        continueFromCurrentRecord: state !== states.searchChanged,
      })
    );
  };

  const loading = state === states.loading;
  const hasNextPage = state !== states.completed;
  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    error: state === states.failed,
    rootMargin: "0px 0px 400px 0px",
  });
  const toggleBoxIsClosed = GetToggleBoxIsClosed();
  const toggleBoxOnClick = () => {
    SetToggleBoxIsClosed(!toggleBoxIsClosed);
  };
  return (
    <CmsIndex
      filter={filter}
      cmsType={cmsType}
      searchOnChange={searchOnChange}
      loading={loading}
      hasNextPage={hasNextPage}
      index={index}
      categoryList={categoryList}
      filterListOnChange={filterListOnChange}
      sentryRef={sentryRef}
      toggleBoxIsClosed={toggleBoxIsClosed}
      toggleBoxOnClick={toggleBoxOnClick}
      calculationYear={
        location.pathname === pages.bouwgebreken ? calculationYear : undefined
      }
    />
  );
};

export default CmsIndexManager;
