import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { AnchorButton } from "../../components/common/Buttons";
import { RevTooltipSimple } from "../../components/common/revTooltip";
import { labelLookup } from "./revEnergyCalculations";

export const DisplayRow = ({ left, right, color, simpleTooltipText }) => {
  return (
    <tr style={{ borderBottom: "1px #EDF9f0 solid" }}>
      <th
        className="is-size-6-mobile"
        style={{ fontSize: "1em", color: `${color}`, padding: "5px 0px" }}
      >
        {left}{" "}
        {simpleTooltipText && (
          <RevTooltipSimple label="i" tooltipText={simpleTooltipText} />
        )}
      </th>
      <th
        className="has-text-right is-size-6-mobile"
        style={{
          fontSize: "1em",
          fontWeight: "500",
          color: `${color}`,
          padding: "5px 0px",
        }}
      >
        {right}
      </th>
    </tr>
  );
};

export const DisplayRowLabel = ({ left, label, color }) => {
  return (
    <tr style={{ borderBottom: "1px #EDF9f0 solid" }}>
      <th style={{ fontSize: "1em", padding: "5px 0px", color: `${color}` }}>
        {left}
      </th>
      <th
        className="has-text-right"
        style={{
          fontSize: "1em",
          color: `${color}`,
          padding: "5px 0px",
        }}
      >
        <EnegyLabel label={label} />
      </th>
    </tr>
  );
};

export const EnegyLabel = ({ label }) => {
  const energyLabelColor = labelLookup[label].color;
  return (
    <span
      style={{
        color: "white",
        backgroundColor: `${energyLabelColor}`,
        width: "fit-sontent",
        padding: "0% 2%",
        height: "100%",
        display: "inline-block",
        textAlign: "center",
      }}
    >
      {label}
    </span>
  );
};

export const CalculationResult = ({ pathname, text, startLabel, endLabel }) => {
  return (
    <div
      className={`rev-responsive-background rev-responsive-opaque-background
         is-hidden-desktop
      `}
    >
      <div className="container">
        <div className={`field is-horizontal`}>
          <strong>Energielabel</strong>&nbsp; van{" "}
          <EnegyLabel label={startLabel} /> naar <EnegyLabel label={endLabel} />
        </div>

        <div className={`field is-horizontal`}>
          <span>
            <strong>Besparing</strong>
            {` € ${text} p.j. `}
          </span>
          <span className="is-pulled-right">
            <AnchorButton
              className={"is-round"}
              icon={faArrowAltCircleDown}
              dataTarget={"toggleDetails"}
              href={`${pathname}#bottom`}
            />
            <AnchorButton
              className={"is-round"}
              icon={faArrowAltCircleUp}
              dataTarget={"toggleDetails"}
              href={`${pathname}#top`}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
