import RedirectSignin from "./redirect-signin";
import * as states from "../redux/states";
import { useSelector } from "react-redux";
import {
  selectIsAuthorized,
  selectUserDataLoadState,
} from "../redux/slices/userSlice";
import { returnUrlKey } from "./userService";
import { StoreInSession } from "../modules/revSessionStorage";
import Loader from "../components/loader";

const RequireAuth = (props) => {
  const state = useSelector(selectUserDataLoadState);
  const isAuthorized = useSelector(selectIsAuthorized);
  if (!isAuthorized && state === states.succeeded) {
    StoreInSession(
      returnUrlKey,
      window.location.pathname + window.location.search
    );
  }

  return (
    <>
      {state !== states.succeeded && <Loader center={true} />}
      {!isAuthorized && state === states.succeeded && <RedirectSignin />}
      {isAuthorized && state === states.succeeded && props.children}
    </>
  );
};

export default RequireAuth;
