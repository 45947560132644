// https://ole.michelsen.dk/blog/using-webp-images-html-css/
export function RevImgFull({ src, alt, className, width, height }) {
  return (
    <picture className={className}>
      <source type="image/webp" srcSet={src} />
      <img
        className={className}
        src={src}
        alt={alt}
        width={width}
        height={height}
      />
    </picture>
  );
}

export function RevImgSelection({
  src,
  alt,
  title,
  onClick,
  index,
  isSelected,
  width = 75,
  height = 75,
  ih = 85,
  iw = 85,
}) {
  let style = {
    width: `${isSelected ? iw : width}px`,
    height: `${isSelected ? ih : height}px`,
    display: "inline-block",
    margin: "5px",
    cursor: "pointer",
    border: `solid ${isSelected ? "#f08708" : "lightgrey"} 1px`,
    borderRadius: "5px",
  };

  return (
    <picture
      className={`image ${isSelected ? "isActive" : ""}`}
      style={style}
      onClick={() => onClick(index)}
    >
      <source type="image/webp" srcSet={src} title={title} />
      <img src={src} alt={alt} title={title} className="rev-image-cover" />
    </picture>
  );
}

export function RevImg64x64({ src, alt }) {
  return (
    <picture className="image is-64x64">
      <source type="image/webp" srcSet={src} />
      <img src={src} alt={alt} className="rev-image-contain" />
    </picture>
  );
}

export function RevImgRepportLogo({ src, alt }) {
  return (
    <picture className="image" style={{ width: "100px", maxHeight: "100px" }}>
      <source type="image/webp" srcSet={src} />
      <img src={src} alt={alt} className="rev-image-contain" />
    </picture>
  );
}

/// gebruikt voor project
export function RevImg480x320Project({ src, alt }) {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">&nbsp;</label>
      </div>
      <div className="field-body">
        <div className="field rev-project-input">
          <p className="control">
            <picture
              className="image"
              style={{ width: "480px", height: "320px", maxWidth: "100%" }}
            >
              <source type="image/webp" srcSet={src} />
              <img src={src} alt={alt} className="rev-image-contain" />
            </picture>
          </p>
        </div>
      </div>
    </div>
  );
}

export function RevImgFromByte({ data, alt, className }) {
  return (
    <picture className={className}>
      <img
        className={className}
        src={`data:image/jpeg;base64,${data}`}
        alt={alt}
      />
    </picture>
  );
}
