import { AsyncRefreshCms } from "../../services/apiFetchService";
import Loader from "../../components/loader";
import { useDispatch } from "react-redux";
import { clear } from "../../redux/slices/generalSlice";
import PageNotFound from "../NotFound";

function RefreshCache() {
  const dispatcher = useDispatch();
  const result = AsyncRefreshCms();
  if (result.state === "success") {
    setTimeout(() => {
      dispatcher(clear());
    }, 100);
    sessionStorage.clear();
  }
  const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";

  return (
    <>
      {isProd && <PageNotFound />}
      {!isProd && (
        <>
          {result.state === "loading" && <Loader center={true} />}
          {result.state === "error" && (
            <div>Error occured: {result.message}</div>
          )}
          {result.state === "success" && (
            <div>Je hebt de cache geupdate. goed gedaan!</div>
          )}
        </>
      )}
    </>
  );
}

export default RefreshCache;
