import Loader from "../../components/loader";
import ArticleSelector from "./articleSelector";
import SearchResults from "./searchResult";
import BreadCrumbManager from "../../components/breadCrumbManager";
import * as states from "../../redux/states";
import SearchBar from "../../components/searchBar";

const FetchCmsLink = ({ state, result, searchOnChange, isAuthorized }) => {
  return (
    <>
      <BreadCrumbManager />
      {(!result ||
        !result.article ||
        (result.article.type !== 1 &&
          result.article.type !== 2 &&
          result.article.type !== 3 &&
          result.article.type !== 4)) && (
        <SearchBar searchOnChange={searchOnChange} />
      )}
      {!result && <Loader center={true} />}
      {state === states.succeeded && result && (
        <>
          {result.article && (
            <ArticleSelector
              content={result.article}
              isAuthorized={isAuthorized}
              state={state}
            />
          )}
          {result.searchResults && result.searchResults.length > 0 && (
            <SearchResults data={result.searchResults} />
          )}
        </>
      )}
    </>
  );
};

export default FetchCmsLink;
