import * as states from "../states";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  navMenu: [],
  calculationYear: undefined,
  status: states.idle,
  error: null,
};

// Thunks!
export const fetchCmsNavMenu = createAsyncThunk("navMenu", async () => {
  const response = await client.get("/api/CmsContent/LoadDefaultData");
  return response.data;
});

const cmsSlice = createSlice({
  name: "cmsNavMenuData",
  initialState,
  reducers: {
    resetCmsMenu: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCmsNavMenu.pending, (state, action) => {
        state.status = states.loading;
      })
      .addCase(fetchCmsNavMenu.fulfilled, (state, action) => {
        state.status = states.succeeded;
        state.navMenu = action.payload.navMenu;
        state.calculationYear = action.payload.calculationYear;
      })
      .addCase(fetchCmsNavMenu.rejected, (state, action) => {
        state.status = states.failed;
        state.error = action.error.message;
      });
  },
});

export default cmsSlice.reducer;

export const { resetCmsMenu } = cmsSlice.actions;

export const selectNavMenu = (state) => state.cmsNavMenuData.navMenu;

export const selectCalculationYear = (state) =>
  state.cmsNavMenuData.calculationYear;

export const selectNavMenuState = (state) => state.cmsNavMenuData.status;
