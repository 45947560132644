const HomeEnvironment = () => {
  const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";
  return (
    <>
      {!isProd && (
        <section className="pb-1">
          <div className="container">
            <small>
              You are running this application in{" "}
              <b>{process.env.REACT_APP_ENVIRONMENT}</b> mode.
            </small>
            <br />
            <small>User agent: {navigator.userAgent};</small>
          </div>
        </section>
      )}
    </>
  );
};

export default HomeEnvironment;
