import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import * as states from "../../../redux/states";
import * as classes from "../../../custom-scripts/custom-javascript-classes";
import * as pages from "../../../navigation";
import UserProjects from "./UserProjects";
import {
  selectUserBeheerObjecten,
  selectUserProjectsLoadedStatus,
  loadUserProjects,
  selectUserComplexes,
  deleteUserProject,
  selectLastProjectRequested,
  copyUserProject,
  deleteNewProject,
} from "../../../redux/slices/userProjectSlice";
import { ClearProjectSessionData } from "../ProjectSession";
import { selectIsAuthorized } from "../../../redux/slices/userSlice";

const UserProjectsManager = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const currentState = useSelector(selectUserProjectsLoadedStatus);
  const currentProjectId = useSelector(selectLastProjectRequested);
  const beheerObjecten = useSelector(selectUserBeheerObjecten);
  const isAuthorized = useSelector(selectIsAuthorized);

  const [sortSettings, setSortSettings] = useState([
    { sort: "name", asc: true },
    { sort: "vtwCode", asc: true },
    { sort: "createDateShort", asc: true },
  ]);

  const hasProjects = beheerObjecten.length !== 0;

  useEffect(() => {
    if (
      !isAuthorized ||
      (currentState === states.completed && beheerObjecten.length === 0)
    ) {
      if (!isAuthorized) {
        dispatcher(deleteNewProject);
        navigate(pages.newproject);
      }
    } else if (currentState === states.idle) {
      dispatcher(loadUserProjects());
    }
  }, [isAuthorized, dispatcher, currentState, beheerObjecten, navigate]);

  const userComplexes = [...useSelector(selectUserComplexes)]
    .sort()
    .map((c) => {
      return { id: c.name, name: c.name };
    });

  const [search, setSearch] = useState("");
  const [currentComplex, setComplex] = useState("");
  const onSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const onSelectComplex = (e) => {
    setComplex(e.target.value);
  };

  const sortOnColumn = (e, sortcolumn) => {
    let tempSortSettings = [...sortSettings];
    const index = sortSettings.findIndex((x) => x.sort === sortcolumn);
    let thing = tempSortSettings.splice(index, 1);
    thing[0]["asc"] = !thing[0]["asc"];
    tempSortSettings.unshift(thing[0]);
    setSortSettings(tempSortSettings);
  };

  const filteredObjects = beheerObjecten.filter((object) => {
    if (currentComplex.length > 0) {
      return (
        // object.name.toLowerCase().includes(search) &&
        object.complexName !== undefined &&
        object.complexName !== null &&
        object.complexName.toLowerCase() === currentComplex.toLowerCase() &&
        (object.name.toLowerCase().includes(search) ||
          object.vtwCode.toLowerCase().includes(search))
      );
    } else {
      return (
        object.name.toLowerCase().includes(search) ||
        object.vtwCode.toLowerCase().includes(search)
      );
    }
  });

  const sortedObjects = filteredObjects.sort((a, b) => {
    for (var i = 0; i < sortSettings.length; i++) {
      const s = sortSettings[i];
      if (a[s.sort] === b[s.sort]) {
        continue;
      }
      if (s.asc) {
        if (a[s.sort].toLowerCase() > b[s.sort].toLowerCase()) return -1;
        else return 1;
      } else {
        if (b[s.sort].toLowerCase() > a[s.sort].toLowerCase()) return -1;
        else return 1;
      }
    }

    return 0;
  });

  function deleteProject(e, id, isCurrentProject) {
    if (isCurrentProject) {
      ClearProjectSessionData();
    }
    const container = e.target.closest(`.${classes.elementWithTarget}`);
    const isNew = container.dataset.isnew;
    if (isNew === "true") {
      dispatcher(deleteNewProject());
    } else {
      dispatcher(deleteUserProject({ id }));
    }
  }

  function copyProject(e, id) {
    const container = e.target.closest(`.${classes.elementWithTarget}`);
    const isNew = container.dataset.isnew;
    if (isNew !== "true") {
      dispatcher(copyUserProject({ id }));
    }
  }

  function openProject(e) {
    const container = e.target.closest(`.${classes.elementWithTarget}`);
    const isNew = container.dataset.isnew;
    if (isNew === "true") {
      navigate(
        `${pages.newproject}/${container.dataset.target}${pages.projectKenmerken}`
      );
    } else {
      navigate(
        `${pages.projectPage}/${container.dataset.target}${pages.projectKenmerken}`
      );
    }
  }

  return (
    <UserProjects
      onSearchChange={onSearchChange}
      getExistingProject={openProject}
      filteredObjects={sortedObjects}
      deleteProject={deleteProject}
      copyProject={copyProject}
      userComplexes={userComplexes}
      onSelectComplex={onSelectComplex}
      currentProjectId={currentProjectId}
      currentComplex={currentComplex}
      sortOnColumn={sortOnColumn}
      sortSettings={sortSettings}
      hasProjects={hasProjects}
    />
  );
};

export default UserProjectsManager;
