import { FormButton } from "../../components/common/FormButton";
import { NumberWithUnitInput } from "../../components/common/NumberInput";
import { SelectInputRow } from "../../components/common/SelectInput";
import BouwdeelHandeling from "./BouwdeelHandeling";
import { ToggleDisplayButton } from "../../components/toggleDisplayButton";
import { RevRoundNumber } from "../../modules/revCalculation";
import { ProjectHeaderDisplayLabel } from "../../components/common/DisplayLabel";

const ProjectBouwdeel = ({
  beheerObjectAmount,
  bouwdeel,
  onChangeBouwdeel,
  onRemovebouwdeel,
  onChangeHandeling,
  yearSelection,
  handleDisplayStateChange,
  displayState,
  onderhoudAccess,
}) => {
  const bouwdeelId = `bouwdeelToggleBox${bouwdeel.source.id}`;

  return (
    <div className="rev-folding-box-secondary">
      <ToggleDisplayButton
        dataTarget={bouwdeelId}
        className={"rev-box-title"}
        isClosed={!displayState[bouwdeelId]}
        onClick={handleDisplayStateChange}
      >
        <ProjectHeaderDisplayLabel
          label={`${bouwdeel.source.name}:`}
          text={`€ ${RevRoundNumber(bouwdeel.total)}`}
        />
      </ToggleDisplayButton>

      <div id={bouwdeelId} className="rev-menu-open">
        <div className="rev-project-box-internal-padding rev-project-box-child-margin">
          {bouwdeel.source.isObsolete && (
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <label className="has-text-danger is-italic">
                      {
                        "Dit bouwdeel is verouderd. Er is mogelijk een nieuwere versie beschikbaar"
                      }
                    </label>
                  </p>
                </div>
              </div>
            </div>
          )}

          <FormButton
            name={"Verwijder bouwdeel"}
            onClick={onRemovebouwdeel}
            dataTarget={bouwdeel.source.id}
            className="button is-danger"
          />
          <NumberWithUnitInput
            label={bouwdeel.source.unit || ""}
            name="amount"
            value={bouwdeel.source.amount}
            onChange={onChangeBouwdeel}
            dataTarget={bouwdeel.source.id}
            placeholder={
              bouwdeel.source.scalesWithParentObject
                ? (beheerObjectAmount || 0).toString()
                : bouwdeel.source.referenceAmount.toString()
            }
          />

          <SelectInputRow
            label="Start jaar"
            name="startYear"
            options={yearSelection}
            value={bouwdeel.source.startYear}
            dataTarget={bouwdeel.source.id}
            onChange={onChangeBouwdeel}
          />

          {bouwdeel.handelingen.map((handeling) => {
            return (
              <div key={`handeling-${handeling.source.id}`}>
                <BouwdeelHandeling
                  handeling={handeling}
                  bouwdeel={bouwdeel}
                  beheerObjectAmount={beheerObjectAmount}
                  onChangeHandeling={onChangeHandeling}
                  handleDisplayStateChange={handleDisplayStateChange}
                  displayState={displayState}
                  onderhoudAccess={onderhoudAccess}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectBouwdeel;
