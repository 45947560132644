import { useEffect } from "react";
import { signoutRedirectCallback } from "./userService";
import { useNavigate } from "react-router-dom";

function SignoutOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      navigate("/");
    }
    signoutAsync().catch((e) => {
      navigate("/");
    });
  }, [navigate]);

  return <div>Redirecting...</div>;
}

export default SignoutOidc;
