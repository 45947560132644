import ArticleTabbed from "./articleTabbed";
import Article from "./article";
import ArticleMaintenance from "./articleMaintenance";
import RevHeader from "../../components/header";
import ArticleNews from "./articleNews";
import RevHelmet from "../../components/RevHelmet";

function ArticleSelector({ content: article, isAuthorized, state }) {
  return (
    <>
      <RevHelmet data={article} />
      {article.type === 0 && <RevHeader {...article} />}
      {article.type === 1 && <Article article={article} />}
      {article.type === 2 && (
        <ArticleTabbed isAuthorized={isAuthorized} article={article} />
      )}
      {article.type === 3 && (
        <ArticleMaintenance
          article={article}
          isAuthorized={isAuthorized}
          state={state}
        />
      )}
      {article.type === 4 && <ArticleNews article={article} />}
    </>
  );
}

export default ArticleSelector;
