import * as projectProps from "./projectConstants";
import PropTypes from "prop-types";
import * as classes from "../../custom-scripts/custom-javascript-classes";
import TextInputField, {
  TextAreaInput,
} from "../../components/common/TextInput";
import { NumberInput } from "../../components/common/NumberInput";
import { RadioButtonGroupProject } from "../../components/common/RadioButtonGroup";
import {
  RevImg480x320Project,
  RevImgFull,
} from "../../components/common/RevImg";
import { ToggleDisplayButton } from "../../components/toggleDisplayButton";
import { DisplayLabel } from "../../components/common/DisplayLabel";
import {
  SelectInputRow,
  SelectInputRowSearch,
} from "../../components/common/SelectInput";
import { PeriodForBeheerObject } from "../../modules/revCalculation";
import { GetKenmerkenDisplay, SetKenmerkenDisplay } from "./ProjectSession";
import { ImageProjectFileInput } from "../../components/common/FileInput";
import { FormButtonProject } from "../../components/common/FormButton";

const ProjectKenmerken = ({
  onProjectChange,
  municipalities,
  yearSelection,
  project,
  errors,
  onSelectImage,
  onGetEnergyLabel,
  projectImage,
  projectServerImage,
  onRadioButtonBoolChange,
  onHerbouwWaardeChange,
  herbouwWaardeInputValue,
  onVolumeChange,
  calculatedValues,
  toggleAutomatic,
  userIsAuthorized,
  municipalityLoading,
  userIsProSubscription,
}) => {
  const getErrorsForProperty = (propertyName) => {
    if (errors) {
      if (errors["project." + propertyName]) {
        return errors["project." + propertyName];
      }

      const upper =
        propertyName.charAt(0).toUpperCase() + propertyName.slice(1);

      if (errors["Project." + upper]) {
        var val = errors["Project." + upper];
        return val;
      }
    }
  };
  const openbareAdressGegevens = "openbareAdressGegevens";

  const canGetEnergyLabel =
    Boolean(project.postalCode) && Boolean(project.houseNumber);
  const addVatToHerbouw = (project) => {
    return (
      project.herbouwKosten *
      (project.includeVat ? (100 + project.vat) / 100 : 1)
    ).toFixed(2);
  };

  const toggleLocatie = async (e) => {
    handleDisplayStateChange(e);
    if (!project[projectProps.publicAddressData] && canGetEnergyLabel) {
      setTimeout(onGetEnergyLabel, 500);
    }
  };

  const onEditPublicRequirementBlur = (e) => {
    if (canGetEnergyLabel) {
      onGetEnergyLabel(e);
    }
  };

  const calculatedHerbouwWithVat = addVatToHerbouw(project);
  const period = PeriodForBeheerObject(project.reference);
  let displayState = GetKenmerkenDisplay();
  const handleDisplayStateChange = async (e) => {
    const box = e.target.closest(`.${classes.toggleBox}`);
    const target = box.dataset.target;
    displayState[target] = !displayState[target];
    SetKenmerkenDisplay(displayState);
  };

  let herbouwLabel = "totaal";
  let herbouwUnit = null;

  if (!calculatedValues) {
    calculatedValues = {};
  }

  const herbouwWaardeReadOnly = Number(project.herbouwWaardeBerekening) === 0;
  switch (Number(project.herbouwWaardeBerekening)) {
    case 2:
      herbouwLabel = "Per kuub";
      herbouwUnit = "m³";
      break;
    case 3:
      herbouwLabel = "Per m² BVO";
      herbouwUnit = "m²";
      break;
    default:
      break;
  }

  return (
    <section className="is-small pb-1">
      <div className="container rev-project-box-child-margin">
        <DisplayLabel
          label={"Vtw code referentie"}
          text={project.reference.searchVtwCode}
          className="rev-is-borderless normal input rev-project-readonly-input"
          isCompact={true}
        />
        <DisplayLabel
          label={"Object type"}
          text={project.reference.objectType}
          className="rev-is-borderless normal input rev-project-readonly-input"
          isCompact={true}
        />
        <DisplayLabel
          label={"Aanmaak datum"}
          text={project.createDate}
          className="rev-is-borderless normal input rev-project-readonly-input"
          isCompact={true}
        />
      </div>
      <div className="container has-text-centered rev-project-box-child-margin">
        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"locatieToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["locatieToggleBox"]}
            type={"locatie"}
            onClick={toggleLocatie}
          >
            {"Locatie"}
          </ToggleDisplayButton>

          <div id="locatieToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding rev-project-box-child-margin">
              <TextInputField
                label="Project naam"
                name={projectProps.name}
                value={project.name}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.name)}
              />

              <TextInputField
                label="Inspecteur"
                name={projectProps.inspector}
                value={project.inspector}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.inspector)}
              />

              <TextInputField
                label="Complex naam"
                name={projectProps.complexName}
                value={project.complexName}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.complexName)}
              />

              {projectImage && <RevImg480x320Project src={projectImage} />}
              {!projectImage && project.image && (
                <RevImg480x320Project src={projectServerImage} />
              )}

              {userIsAuthorized && (
                <>
                  <ImageProjectFileInput
                    label={"selecteer afbeelding"}
                    onChange={onSelectImage}
                    errors={getErrorsForProperty(projectProps.image)}
                  />
                  <br />
                </>
              )}

              <TextInputField
                label="Postcode"
                name={projectProps.postalCode}
                value={project.postalCode}
                onChange={onProjectChange}
                onBlur={onEditPublicRequirementBlur}
                maxLength={"10"}
                error={getErrorsForProperty(projectProps.postalCode)}
              />
              <TextInputField
                label="Huisnummer"
                name={projectProps.houseNumber}
                value={project.houseNumber}
                onChange={onProjectChange}
                onBlur={onEditPublicRequirementBlur}
                maxLength={"10"}
                error={getErrorsForProperty(projectProps.houseNumber)}
              />

              <TextInputField
                label="Straat"
                name={projectProps.addressLine1}
                value={project.addressLine1}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.addressLine1)}
              />

              <TextInputField
                label="Stad"
                name={projectProps.city}
                value={project.city}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.city)}
              />
              {municipalityLoading && (
                <SelectInputRowSearch
                  label="Gemeente"
                  name={projectProps.municipalityId}
                  value={project.municipalityId}
                  onChange={onProjectChange}
                  options={municipalities}
                />
              )}
              {!municipalityLoading && (
                <SelectInputRowSearch
                  label="Gemeente"
                  name={projectProps.municipalityId}
                  value={project.municipalityId}
                  onChange={onProjectChange}
                  options={municipalities}
                />
              )}

              <div className="rev-folding-box-secondary">
                <ToggleDisplayButton
                  dataTarget={openbareAdressGegevens}
                  className={"rev-box-title"}
                  isClosed={!displayState[openbareAdressGegevens]}
                  onClick={handleDisplayStateChange}
                >
                  Toon openbare gegevens
                </ToggleDisplayButton>

                <div id={openbareAdressGegevens} className="rev-menu-open">
                  <div className="rev-project-box-internal-padding rev-project-box-child-margin">
                    <FormButtonProject
                      name={"Haal openbare adres data op"}
                      onClick={(e) => {
                        if (canGetEnergyLabel) {
                          onGetEnergyLabel(e);
                        }
                      }}
                      disabled={!canGetEnergyLabel}
                      className={"button"}
                      simpleTooltipText="Hiermee overschrijft u de gebouw gegevens met de beschikbare openbare gegevens uit het BAG register en uit EP-Online. Om dit te gebruiken zijn postcode en huisnummer nodig."
                      errors={getErrorsForProperty(
                        projectProps.publicAddressData
                      )}
                    />
                    <DisplayLabel
                      label={"Bouwjaar"}
                      text={project.publicAddressData?.bagData?.bouwjaar}
                      addonText={"jaar"}
                    />
                    <DisplayLabel
                      label={"BAG oppervlakte"}
                      text={project.publicAddressData?.bagData?.go}
                      addonText={
                        <span>
                          m<sup>2</sup>
                        </span>
                      }
                    />
                    <DisplayLabel
                      label={"EP oppervlakte"}
                      text={project.publicAddressData?.epData?.go}
                      addonText={
                        <span>
                          m<sup>2</sup>
                        </span>
                      }
                    />
                    <DisplayLabel
                      label={"Gebouw type"}
                      text={project.publicAddressData?.bagData?.type}
                    />
                    <DisplayLabel
                      label={"Geregistreerd energie label"}
                      text={project.publicAddressData?.epData?.energyLabel}
                    />
                    <DisplayLabel
                      label={"Label berekenings type"}
                      text={project.publicAddressData?.epData?.berekeningsType}
                    />
                    <DisplayLabel
                      label={"Energie behoefte"}
                      text={project.publicAddressData?.epData?.energieBehoefte}
                      addonText={
                        <span>
                          {" "}
                          kWh/m<sup>2</sup>&nbsp;jr
                        </span>
                      }
                    />
                    <DisplayLabel
                      label={"Primaire fossieel energiegebruik"}
                      text={
                        project.publicAddressData?.epData
                          ?.primaireFossieleEnergiegebruik
                      }
                      addonText={
                        <span>
                          {" "}
                          kWh/m<sup>2</sup>&nbsp;jr
                        </span>
                      }
                    />
                    <DisplayLabel
                      label={"Aandeel hernieuwbare energie"}
                      text={
                        project.publicAddressData?.epData
                          ?.aandeelHernieuwbareEnergie
                      }
                      addonText={"%"}
                    />
                    <DisplayLabel
                      label={"Registratie datum"}
                      text={
                        project.publicAddressData?.epData?.labelRegistrationDate
                      }
                    />
                    <DisplayLabel
                      label={"Geldig tot"}
                      text={project.publicAddressData?.epData?.labelEndDate}
                    />
                    <div
                      style={{
                        width: "350px",
                        height: "350px",
                        display: "inline",
                      }}
                    >
                      <RevImgFull
                        src={project.publicAddressData?.mapImage?.url}
                      />
                    </div>
                    &nbsp;
                    <div
                      style={{
                        width: "350px",
                        height: "350px",
                        display: "inline",
                      }}
                    >
                      <RevImgFull
                        src={project.publicAddressData?.buildingImage?.url}
                      />
                    </div>
                    &nbsp;
                    <div
                      style={{
                        width: "350px",
                        height: "350px",
                        display: "inline",
                      }}
                    >
                      <RevImgFull
                        src={project.publicAddressData?.sateliteImage?.url}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"eigenschappenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["eigenschappenToggleBox"]}
            type={"eigenschappen"}
            onClick={handleDisplayStateChange}
          >
            {"Eigenschappen"}
          </ToggleDisplayButton>

          <div id="eigenschappenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding">
              <DisplayLabel
                label="Daksoort"
                text={project.reference.roofType}
              />

              <DisplayLabel label="Bouwperiode" text={period.name} />

              <NumberInput
                contextObject={project}
                label="Bouwjaar"
                name={projectProps.bouwjaar}
                value={project.bouwjaar}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.bouwjaar)}
                min={1900}
                max={2999}
                noDecimals={true}
              />

              <SelectInputRow
                label="Energie label"
                name={projectProps.energyLabel}
                value={project.energyLabel}
                onChange={onProjectChange}
                options={[
                  { id: "", name: "Geen selectie gemaakt" },
                  { id: "A++++", name: "A++++" },
                  { id: "A+++", name: "A+++" },
                  { id: "A++", name: "A++" },
                  { id: "A+", name: "A+" },
                  { id: "A", name: "A" },
                  { id: "B", name: "B" },
                  { id: "C", name: "C" },
                  { id: "D", name: "D" },
                  { id: "E", name: "E" },
                  { id: "F", name: "F" },
                  { id: "G", name: "G" },
                ]}
              />

              <NumberInput
                label="BVO"
                name={projectProps.bvo}
                value={project.bvo}
                onChange={onVolumeChange}
                error={getErrorsForProperty(projectProps.bvo)}
                addonText={"m²"}
                noDecimals={true}
              />

              <NumberInput
                label="GO"
                name={projectProps.go}
                value={project.go}
                onChange={onVolumeChange}
                error={getErrorsForProperty(projectProps.go)}
                addonText={"m²"}
                noDecimals={true}
              />

              <NumberInput
                label="Hoogte"
                name={projectProps.hoogte}
                value={project.hoogte}
                onChange={onVolumeChange}
                error={getErrorsForProperty(projectProps.hoogte)}
                addonText={"m"}
                step={0.01}
              />

              <NumberInput
                label="Inhoud"
                name={projectProps.inhoud}
                value={project.inhoud}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.inhoud)}
                addonText={"m³"}
                extraClass="rev-project-readonly-input"
                readOnly={true}
              />

              <NumberInput
                label="Huursom"
                name={projectProps.huursom}
                value={project.huursom}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.huursom)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Koopsom"
                name={projectProps.koopsom}
                value={project.koopsom}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.koopsom)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="WOZ-waarde"
                name={projectProps.wozwaarde}
                value={project.wozwaarde}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.wozwaarde)}
                prefixAddonText={"€"}
              />

              <SelectInputRow
                label="Eigenaar/gebruiker"
                name={projectProps.financialOwner}
                value={project.financialOwner}
                onChange={onProjectChange}
                options={[
                  { id: 0, name: "onbekend" },
                  { id: 1, name: "Eigenaar" },
                  { id: 2, name: "Gebruiker" },
                  { id: 3, name: "Gebruiker en eigenaar" },
                ]}
              />

              <SelectInputRow
                label="Berekening herbouwkosten"
                name={projectProps.herbouwWaardeBerekening}
                value={project.herbouwWaardeBerekening}
                onChange={onProjectChange}
                options={[
                  { id: 0, name: "Automatisch" },
                  { id: 1, name: "Handmatig totaal" },
                  { id: 2, name: "Handmatig inhoud" },
                  { id: 3, name: "Handmatig bvo" },
                ]}
              />
              {herbouwWaardeReadOnly && (
                <NumberInput
                  label="Herbouwkosten"
                  name={projectProps.herbouwKosten}
                  value={calculatedHerbouwWithVat}
                  onChange={onHerbouwWaardeChange}
                  prefixAddonText={"€"}
                  extraClass="rev-project-readonly-input"
                  readOnly={true}
                />
              )}
              {!herbouwWaardeReadOnly && (
                <>
                  <NumberInput
                    prefixAddonText={"€"}
                    label={herbouwLabel}
                    value={herbouwWaardeInputValue}
                    onChange={onHerbouwWaardeChange}
                    addonText={herbouwUnit}
                  />
                  <NumberInput
                    label="Herbouwkosten"
                    name={projectProps.herbouwKosten}
                    value={calculatedHerbouwWithVat}
                    prefixAddonText={"€"}
                    extraClass="rev-project-readonly-input"
                    readOnly={true}
                  />
                </>
              )}

              <SelectInputRow
                label="Berekening Jaar"
                name={projectProps.year}
                options={yearSelection}
                onChange={onProjectChange}
                value={project.year}
                addonText={"jaar"}
              />

              <NumberInput
                label={"Cyclus"}
                value={project.cycle}
                onChange={onProjectChange}
                name={projectProps.cycle}
                noDecimals={true}
                simpleTooltipText="Standaard is het doorexploitatiemodel ingesteld met een cyclus tot en met 99 jaar. Voor het uitpondmodel vult u een waarde in tot en met 25 jaar en worden de kosten van groot onderhoud niet meegenomen."
              />

              <RadioButtonGroupProject
                label="Btw"
                buttons={[
                  {
                    text: "Exclusief",
                    value: false,
                    checked: !project.includeVat,
                  },
                  {
                    text: "Inclusief",
                    value: true,
                    checked: project.includeVat,
                  },
                ]}
                name={projectProps.includeVat}
                value={project.includeVat}
                onChange={onRadioButtonBoolChange}
                isBool={true}
                simpleTooltipText="Met deze selectie wordt aangegeven of btw wel of niet bij de kosten mee berekend wordt"
              />

              <RadioButtonGroupProject
                label="Commercieel beheer"
                buttons={[
                  {
                    text: "Commercieel",
                    value: true,
                    checked: project.commercialProperty,
                  },
                  {
                    text: "Particulier",
                    value: false,
                    checked: !project.commercialProperty,
                  },
                ]}
                name={projectProps.commercialProperty}
                value={project.commercialProperty}
                onChange={onRadioButtonBoolChange}
                isBool={true}
                simpleTooltipText="Met deze selectie wordt aangegeven of het pand in commercieel of particulier beheer is. Particuliere kosten liggen in het algemeen hoger dan commerciele kosten."
              />
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"eenmaligeKostenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["eenmaligeKostenToggleBox"]}
            type={"kosten"}
            onClick={handleDisplayStateChange}
          >
            {"Eenmalige kosten"}
          </ToggleDisplayButton>

          <div id="eenmaligeKostenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding">
              <NumberInput
                label="Makelaarskosten"
                name={projectProps.makelaarsKosten}
                value={project.makelaarsKosten}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.makelaarsKosten)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Notariskosten"
                name={projectProps.notarisKosten}
                value={project.notarisKosten}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.notarisKosten)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Taxatiekosten"
                name={projectProps.taxatieKosten}
                value={project.taxatieKosten}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.taxatieKosten)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Eenmalige kosten 1"
                name={projectProps.extraEenmaligePost1}
                value={project.extraEenmaligePost1}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.extraEenmaligePost1)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Eenmalige kosten 2"
                name={projectProps.extraEenmaligePost2}
                value={project.extraEenmaligePost2}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.extraEenmaligePost2)}
                prefixAddonText={"€"}
              />

              {/* twee eenpalige posten met invoer verlden voor de naam */}
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"jaarlijkseKostenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["jaarlijkseKostenToggleBox"]}
            type={"kosten"}
            onClick={handleDisplayStateChange}
          >
            {"Jaarlijkse kosten"}
          </ToggleDisplayButton>

          <div id="jaarlijkseKostenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding">
              <NumberInput
                label="Servicekosten"
                name={projectProps.serviceKosten}
                value={project.serviceKosten}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.serviceKosten)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Gasverbruik"
                name={projectProps.gasVerbruik}
                value={project.gasVerbruik}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.gasVerbruik)}
                addonText={"m³"}
              />

              <NumberInput
                label="Elektraverbruik"
                name={projectProps.elektraVerbruik}
                value={project.elektraVerbruik}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.elektraVerbruik)}
                addonText={"kWh"}
              />

              <NumberInput
                label="Waterverbruik"
                name={projectProps.waterVerbruik}
                value={project.waterVerbruik}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.waterVerbruik)}
                addonText={"m³"}
              />

              <RadioButtonGroupProject
                label="Waterschapbelasting"
                buttons={[
                  {
                    text: "Automatisch",
                    value: false,
                    checked: project.waterschapBelasting === null,
                  },
                  {
                    text: "Handmatig",
                    value: true,
                    checked: project.waterschapBelasting !== null,
                  },
                ]}
                name={projectProps.waterschapBelasting}
                onChange={toggleAutomatic}
                isBool={true}
                simpleTooltipText="Kies voor een handmatige of automatische invoer van de waterschapsbelasting"
              />

              {project.waterschapBelasting !== null && (
                <NumberInput
                  label=""
                  name={projectProps.waterschapBelasting}
                  value={project.waterschapBelasting}
                  onChange={onProjectChange}
                  error={getErrorsForProperty(projectProps.waterschapBelasting)}
                  prefixAddonText={"€"}
                />
              )}
              {project.waterschapBelasting === null && (
                <TextInputField
                  label=""
                  value={calculatedValues[projectProps.waterRate]}
                  prefixAddonText={"€"}
                  extraClass={"rev-project-readonly-input"}
                  readOnly={true}
                />
              )}
              <RadioButtonGroupProject
                label="Riool"
                buttons={[
                  {
                    text: "Automatisch",
                    value: false,
                    checked: project.riool === null,
                  },
                  {
                    text: "Handmatig",
                    value: true,
                    checked: project.riool !== null,
                  },
                ]}
                name={projectProps.riool}
                onChange={toggleAutomatic}
                isBool={true}
                simpleTooltipText="Kies voor een handmatige of automatische invoer van de riool lasten"
              />

              {project.riool !== null && (
                <NumberInput
                  label=""
                  name={projectProps.riool}
                  value={project.riool}
                  onChange={onProjectChange}
                  error={getErrorsForProperty(projectProps.riool)}
                  prefixAddonText={"€"}
                />
              )}

              {project.riool === null && (
                <TextInputField
                  label=""
                  extraClass="rev-project-readonly-input"
                  value={calculatedValues[projectProps.rioolRate]}
                  prefixAddonText={"€"}
                  readOnly
                />
              )}
              <TextInputField
                label="OZB"
                name={projectProps.ozbRate}
                extraClass="rev-project-readonly-input"
                value={calculatedValues[projectProps.ozbRate]}
                prefixAddonText={"€"}
                readOnly
              />

              <NumberInput
                label="Opstalverzekering"
                name={projectProps.opstalVerzekering}
                value={project.opstalVerzekering}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.opstalVerzekering)}
                addonText={"‰"}
                step={0.01}
              />

              <SelectInputRow
                label="Overdrachtsbelasting"
                name={projectProps.overdrachtsBelasting}
                value={project.overdrachtsBelasting}
                onChange={onProjectChange}
                options={[
                  { id: null, name: "geen selectie gemaakt" },
                  { id: 0, name: "0%" },
                  { id: 2, name: "2%" },
                  { id: 6, name: "6%" },
                  { id: 8, name: "8%" },
                  { id: "10.4", name: "10,4%" },
                ]}
              />

              <NumberInput
                label="Beheerkosten huursom"
                name={projectProps.beheerKostenHuursom}
                value={project.beheerKostenHuursom}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.beheerKostenHuursom)}
                addonText={"%"}
                step={0.1}
              />

              <NumberInput
                label="Extra jaarlijkse kosten post 1"
                name={projectProps.extraJaarlijksePost1}
                value={project.extraJaarlijksePost1}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.extraJaarlijksePost1)}
                prefixAddonText={"€"}
              />

              <NumberInput
                label="Extra jaarlijkse kosten post 2"
                name={projectProps.extraJaarlijksePost2}
                value={project.extraJaarlijksePost2}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.extraJaarlijksePost2)}
                prefixAddonText={"€"}
              />
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"omschrijvingenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["omschrijvingenToggleBox"]}
            type={"omschrijvingen"}
            onClick={handleDisplayStateChange}
          >
            {"Omschrijvingen"}
          </ToggleDisplayButton>

          <div id="omschrijvingenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding">
              <TextAreaInput
                label="Kenmerken"
                name={projectProps.featureText}
                value={project.featureText}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.featureText)}
                placeholder={"gebouw kenmerken"}
              />
              <TextAreaInput
                label="Renovatiedelen"
                name={projectProps.renovationParts}
                value={project.renovationParts}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.renovationParts)}
                placeholder={"gebouw renovatiedelen"}
              />
              <TextAreaInput
                label="Gebreken"
                name={projectProps.faults}
                value={project.faults}
                onChange={onProjectChange}
                error={getErrorsForProperty(projectProps.faults)}
                placeholder={"gebreken"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
ProjectKenmerken.propTypes = {
  project: PropTypes.object,
};

export default ProjectKenmerken;
