export const loading = "loading";
export const succeeded = "succeeded";
export const idle = "idle";
export const searchChanged = "searchChanged";
export const partial = "partial";
export const completed = "completed";
export const failed = "failed";
export const failedonce = "failedonce";
export const rejected = "rejected";
export const unauthorized = "unauthorized";
export const notfound = "notfound";
