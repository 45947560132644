import * as states from "../states";

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { client } from "../../api/client";

const projectAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.name.localeCompare(a.name),
});

// include bouwdelen and handlingen to normalize data
const initialState = projectAdapter.getInitialState({
  bouwdelen: {},
  handelingen: {},
  municipalityRates: [],
  globalRates: [],
  calculationYears: [],
  decenniumCosts: [],
  userComplexes: [],
  waterAuthorities: [],
  projectIdLoaded: undefined,
  newProjectVtwCode: undefined,
  systemYear: 0,
  errors: undefined,
  exploitationData: {},
  status: states.idle,
  exploitationDataStatus: states.idle,
  projectsLoadedStatus: states.idle,
  complexMjopFiles: [],
  complexMjopFilesState: states.idle,
});

// Thunks!
export const getProjectExploitationData = createAsyncThunk(
  "getprojectexploitationdata",
  async ({ vtwCode }) => {
    if (vtwCode) {
      const response = await client.get(
        `/api/project/GetProjectExploitationData/${vtwCode}`
      );
      return JSON.stringify(response.data);
    }
    return "{}";
  }
);

export const getMunicipalityRateForIdAndYear = createAsyncThunk(
  "getMunicipalityRateForIdAndYear",
  async ({ id, year }) => {
    if (!id || isNaN(id)) {
      return JSON.stringify([]);
    }
    const response = await client.get(
      `/api/masterdata/MunicipalityTaxRates/${year}/${id}`
    );
    return JSON.stringify(response.data);
  }
);

export const getWaterAuthorityRatesForPostalcode = createAsyncThunk(
  "getwaterrateforpostalcode",
  async ({ postalcode, year }) => {
    const pattern = /\d{4}\s*[a-zA-Z]{2}/g;
    if (postalcode) {
      postalcode = postalcode.replace(/\s/g, "");
      if (pattern.test(postalcode)) {
        const response = await client.get(
          `/api/masterData/WaterAuthorityRate/${year}/${postalcode}`
        );
        return JSON.stringify(response.data);
      }
    }
    return "[]";
  }
);

export const getNewUserProject = createAsyncThunk(
  "getnewuserproject",
  async (postData) => {
    const response = await client.post(
      `/api/project/GetNewUserProject`,
      postData
    );
    return JSON.stringify(response.data);
  }
);

export const getExistingUserProject = createAsyncThunk(
  "getexistinguserproject",
  async (postData) => {
    const response = await client.post(
      `/api/project/GetExistingUserProject`,
      postData
    );
    return JSON.stringify(response.data);
  }
);

export const loadProjectBouwdelen = createAsyncThunk(
  "loadProjectBouwdelen",
  async ({ userBeheerObjectId }) => {
    const response = await client.get(
      `/api/project/getProjectBouwdelen/${userBeheerObjectId}`
    );
    return JSON.stringify(response.data);
  }
);

export const deleteUserProject = createAsyncThunk(
  "deleteUserProject",
  async ({ id }) => {
    const response = await client.delete(
      `/api/project/deleteUserProject/${id}`
    );
    return JSON.stringify(response.data);
  }
);

export const copyUserProject = createAsyncThunk(
  "copyUserProject",
  async ({ id }) => {
    const response = await client.post(
      `/api/project/copyUserProject/${id}`,
      {}
    );
    return JSON.stringify(response.data);
  }
);

export const loadBouwdeelHandelingen = createAsyncThunk(
  "loadBouwdeelHandelingen",
  async (postData) => {
    const response = await client.post(
      `/api/project/getBouwdeelHandelingen`,
      postData
    );
    return JSON.stringify(response.data);
  }
);

export const changeObjectYear = createAsyncThunk(
  "changeObjectYear",
  async (postData) => {
    const response = await client.post(
      `/api/project/ChangeObjectYear`,
      postData
    );
    return JSON.stringify(response.data);
  }
);

export const getDecenniumCostAverages = createAsyncThunk(
  "GetMjopDecennumCosts",
  async (postData) => {
    const response = await client.post(
      `/api/project/GetMjopDecenniumCosts`,
      postData
    );
    return JSON.stringify(response.data);
  }
);

export const saveUserProject = createAsyncThunk(
  "saveuserproject",
  async (payload) => {
    const response = await client.post(`/api/project/SaveUserProject`, payload);
    if (!response.data.success) {
      return isRejectedWithValue(
        JSON.stringify({
          status: 200,
          message: "Rejection at server",
        })
      );
    }
    return JSON.stringify(response.data);
  }
);

export const loadUserProjects = createAsyncThunk(
  "loaduserprojects",
  async () => {
    const response = await client.post(`/api/project/GetUserProjects`);
    return JSON.stringify(response.data);
  }
);

export const TryGetPublicAddressData = createAsyncThunk(
  "TryGetPublicAddressData",
  async (payload) => {
    const response = await client.get(
      `/api/report/TryGetPublicAddressData?postalcode=${payload.postalCode}&housenumber=${payload.houseNumber}&year=${payload.year}`
    );
    return JSON.stringify(response.data || "{}");
  }
);

export const getComplexMjops = createAsyncThunk("getComplexMjops", async () => {
  const response = await client.get(`/api/project/GetUserGroupMjopComplex`);
  return JSON.stringify(response.data || "{}");
});

export const getYearSelection = createAsyncThunk(
  "GetYearSelection",
  async () => {
    const response = await client.get(`/api/project/GetYearSelection`);
    return JSON.stringify(response.data || "{}");
  }
);

const clearLatestNewProject = (state) => {
  if (state.projectIdLoaded !== "") {
    const prevProj = state.entities[state.projectIdLoaded];
    if (prevProj && prevProj.isNew) {
      projectAdapter.removeOne(state, state.projectIdLoaded);
    }
  }
  state.projectIdLoaded = undefined;
  state.newProjectVtwCode = undefined;
};

const projectLoadSucceededStep = (state, data) => {
  state.bouwdelen = Object.assign({}, data.bouwdelen);
  state.handelingen = Object.assign({}, data.handelingen);
  state.globalRates = data.globalRates;
  state.municipalityRates = data.municipalityRates;
  state.waterAuthorities = data.waterAuthorities;
  state.calculationYears = data.calculationYears;
  state.yearSelection = data.yearSelection;
  state.systemYear = data.systemYear;
  state.status = states.succeeded;
  state.decenniumCosts = [];
};

const updateBouwdeelHandelingen = (
  state,
  data,
  objectBouwdeelIds,
  loadBouwdelen
) => {
  state.handelingen = Object.assign({}, state.handelingen, data.handelingen);
  for (let i = 0; i < objectBouwdeelIds.length; i++) {
    state.bouwdelen[objectBouwdeelIds[i]].active = true;
  }
  if (data.bouwdelen && loadBouwdelen) {
    var localObjectBouwdeelIds = Object.keys(state.bouwdelen);
    // remove non active bouwdelen
    for (let i = 0; i < localObjectBouwdeelIds.length; i++) {
      if (state.bouwdelen[localObjectBouwdeelIds[i]].active !== true) {
        delete state.bouwdelen[localObjectBouwdeelIds[i]];
      }
    }
    // add the new inactive bouwdelen
    Object.assign(state.bouwdelen, data.bouwdelen);
  }
  state.entities[state.projectIdLoaded].herbouwKostenRate =
    data.herbouwKostenRate;
  state.entities[state.projectIdLoaded].vat = data.vat;
  state.globalRates = data.globalRates;
  state.municipalityRates = data.municipalityRates;
  state.waterAuthorities = data.waterAuthorities;
};

const setProjectCanSave = (state, data) => {
  const { id, value } = { ...data };
  if (id === state.newProjectVtwCode) {
    state.entities[state.projectIdLoaded].canSave = value;
  } else {
    state.entities[id].canSave = value;
  }
};

const userProjectSlice = createSlice({
  name: "userProjectData",
  initialState,
  reducers: {
    deleteNewProject: (state, _) => {
      clearLatestNewProject(state);
    },
    updateProject: (state, action) => {
      const { id, name, value } = { ...action.payload };
      if (id === state.newProjectVtwCode) {
        state.entities[state.projectIdLoaded][name] = value;
      } else {
        state.entities[id][name] = value;
      }
      setProjectCanSave(state, { id, value: true });
    },
    updateBouwdeel: (state, action) => {
      const { id, projectId, name, value } = { ...action.payload };
      state.bouwdelen[id][name] = value;
      setProjectCanSave(state, { id: projectId, value: true });
    },
    updateHandeling: (state, action) => {
      const { id, projectId, name, value } = { ...action.payload };
      state.handelingen[id][name] = value;
      setProjectCanSave(state, { id: projectId, value: true });
    },
    addMjopRequest: (state, action) => {
      const { complex } = { ...action.payload };
      state.complexMjopFiles.push({
        canDownload: false,
        fileName: `mjop verzoek voor ${complex}`,
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectExploitationData.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        state.exploitationData[action.meta.arg.vtwCode] = data;
        state.exploitationDataStatus = states.idle;
        state.errors = undefined;
      })
      .addCase(getProjectExploitationData.pending, (state) => {
        state.exploitationDataStatus = states.loading;
      })
      .addCase(getProjectExploitationData.rejected, (state, action) => {
        state.exploitationDataStatus = states.failed;
        state.errors = action.error.message;
      })
      .addCase(deleteUserProject.fulfilled, (state, action) => {
        projectAdapter.removeOne(state, action.meta.arg.id);
        clearLatestNewProject(state);
      })
      .addCase(copyUserProject.pending, (state, action) => {
        state.projectsLoadedStatus = states.loading;
      })
      .addCase(copyUserProject.fulfilled, (state, action) => {
        state.projectsLoadedStatus = states.idle;
      })
      .addCase(getDecenniumCostAverages.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        state.decenniumCosts = data;
      })
      .addCase(loadUserProjects.pending, (state, _) => {
        state.projectsLoadedStatus = states.loading;
        state.errors = undefined;
      })
      .addCase(getMunicipalityRateForIdAndYear.fulfilled, (state, action) => {
        const municipalityRates = JSON.parse(action.payload);
        state.municipalityRates = municipalityRates;
      })
      .addCase(getWaterAuthorityRatesForPostalcode.pending, (state, _) => {
        state.waterAuthorities = [];
        state.errors = undefined;
      })
      .addCase(
        getWaterAuthorityRatesForPostalcode.fulfilled,
        (state, action) => {
          const waterAuthorities = JSON.parse(action.payload);
          state.waterAuthorities = waterAuthorities;
        }
      )
      .addCase(loadUserProjects.fulfilled, (state, action) => {
        state.projectsLoadedStatus = states.succeeded;
        if (action?.payload) {
          const data = JSON.parse(action.payload);
          projectAdapter.upsertMany(state, data.userProjects);
          state.userComplexes = data.userComplexes;
        }
      })
      .addCase(loadUserProjects.rejected, (state, _) => {
        state.projectsLoadedStatus = states.failed;
      })
      .addCase(getExistingUserProject.pending, (state, _) => {
        clearLatestNewProject(state);
      })
      .addCase(getExistingUserProject.rejected, (state, action) => {
        if (action.error.message) {
          const message = JSON.parse(action.error.message);
          if (message.status === 401) {
            state.projectsLoadedStatus = states.unauthorized;
          } else {
            state.projectsLoadedStatus = states.failed;
          }
        }
      })
      .addCase(getComplexMjops.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        state.complexMjopFiles = data;
        state.complexMjopFilesState = states.succeeded;
      })
      .addCase(getComplexMjops.pending, (state, action) => {
        state.complexMjopFilesState = states.loading;
      })
      .addCase(getComplexMjops.rejected, (state, action) => {
        state.complexMjopFilesState = states.failed;
      })
      .addCase(getYearSelection.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);

        state.yearSelection = data.yearSelection;
        state.systemYear = data.systemYear;
      })
      .addCase(TryGetPublicAddressData.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        const id = action.meta.arg.id;
        const project = state.entities[id];

        if (project) {
          project.publicAddressData = data;
          if (data.epData) {
            project.energyLabel = data.epData.energyLabel;
          }
          if (data.bagData?.city) {
            project.city = data.bagData.city;
          }
          if (data.bagData?.street) {
            project.addressLine1 = data.bagData.street;
          }
          if (data.bagData?.bouwjaar) {
            project.bouwjaar = data.bagData.bouwjaar;
          }
          if (data.municipalityId) {
            project.municipalityId = data.municipalityId.toString();
            state.municipalityRates = data.municipalityRates;
          }
        }
      })
      .addCase(getExistingUserProject.fulfilled, (state, action) => {
        clearLatestNewProject(state);
        const data = JSON.parse(action.payload);
        state.projectIdLoaded = data.project.id;
        projectAdapter.upsertOne(state, data.project);

        projectLoadSucceededStep(state, data);
      })
      .addCase(getNewUserProject.pending, (state, _) => {})
      .addCase(getNewUserProject.rejected, (state, action) => {
        if (action.error.message) {
          const message = JSON.parse(action.error.message);
          if (message.status === 401) {
            state.projectsLoadedStatus = states.unauthorized;
          } else {
            state.projectsLoadedStatus = states.failed;
          }
        }
      })
      .addCase(getNewUserProject.fulfilled, (state, action) => {
        clearLatestNewProject(state);
        const data = JSON.parse(action.payload);
        state.projectIdLoaded = data.project.id;
        state.newProjectVtwCode = data.project.reference.vtwCode;
        projectAdapter.addOne(state, data.project);
        projectLoadSucceededStep(state, data);
      })
      .addCase(saveUserProject.pending, (state, _) => {
        state.errors = undefined;
      })
      .addCase(saveUserProject.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        state.status = states.completed;
        state.entities[action.meta.arg.project.id].canSave = false;
        if (data.imageUrl) {
          state.entities[action.meta.arg.project.id].image.src = data.imageUrl;
        }
        if (data.complexCreated) {
          state.userComplexes.push({ name: data.complexCreated });
        }
        state.projectIdLoaded = action.meta.arg.project.id;
        state.entities[action.meta.arg.project.id].isNew = false;
      })
      .addCase(saveUserProject.rejected, (state, action) => {
        const rejection = JSON.parse(action.error.message);
        if (rejection.status === 401) {
          state.projectsLoadedStatus = states.unauthorized;
        }
        state.status = states.failed;
        state.errors = rejection.errors;
      })
      .addCase(changeObjectYear.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        if (!data.changeObjectYear) {
          updateBouwdeelHandelingen(
            state,
            data,
            action.meta.arg.objectBouwdeelIds,
            action.meta.arg.loadBouwdelen
          );
        } else {
          state.handelingen = Object.assign({}, data.handelingen);
          state.bouwdelen = Object.assign({}, data.bouwdelen);
          state.entities[state.projectIdLoaded].herbouwKostenRate =
            data.herbouwKostenRate;
          state.entities[state.projectIdLoaded].vat = data.vat;
          state.globalRates = data.globalRates;
          state.municipalityRates = data.municipalityRates;
          state.waterAuthorities = data.waterAuthorities;
        }
      })
      .addCase(loadBouwdeelHandelingen.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        updateBouwdeelHandelingen(
          state,
          data,
          action.meta.arg.objectBouwdeelIds,
          action.meta.arg.loadBouwdelen
        );
      });
  },
});

export const {
  updateBouwdeel,
  updateHandeling,
  updateProject,
  addMjopRequest,
  deleteNewProject,
} = userProjectSlice.actions;

export default userProjectSlice.reducer;

export const selectErrorMessages = (state) => state.userProjectData.errors;

export const selectDecenniumCosts = (state) =>
  state.userProjectData.decenniumCosts;

export const selectLoadProjectDataStatus = (state) =>
  state.userProjectData.status;

export const selectExploitationDataLoadStatus = (state) =>
  state.userProjectData.exploitationDataStatus;

export const selectUserProjectsLoadedStatus = (state) =>
  state.userProjectData.projectsLoadedStatus;

// can be an guid or a vtwCode
export const selectLastProjectRequested = (state) => {
  if (state.userProjectData.newProjectVtwCode) {
    return state.userProjectData.newProjectVtwCode;
  }
  return state.userProjectData.projectIdLoaded;
};

export const selectProjectBouwdelen = (state) =>
  state.userProjectData.bouwdelen;

export const selectProjectHandelingen = (state) =>
  state.userProjectData.handelingen;

export const selectMunicipalityRates = (state) =>
  state.userProjectData.municipalityRates ?? [];

export const selectWaterAuthorityRates = (state) =>
  state.userProjectData.waterAuthorities ?? [];

export const selectGlobalRates = (state) => state.userProjectData.globalRates;

export const selectUserComplexes = (state) =>
  state.userProjectData.userComplexes;

export const selectUserComplexesMjopFiles = (state) =>
  state.userProjectData.complexMjopFiles;

export const selectUserComplexesMjopFilesState = (state) =>
  state.userProjectData.complexMjopFilesState;

export const selectCalculationYears = (state) =>
  state.userProjectData.yearSelection;

export const selectSystemYear = (state) => state.userProjectData.systemYear;

export const selectBouwdeelHandelingen = (state, objectBouwdeelId) =>
  state.userProjectData.handelingen.filter(
    (obj) => obj.objectBouwdeelId === objectBouwdeelId
  );

export const selectObjectExploitationData = (state, vtwCode) => {
  if (vtwCode) {
    return state.userProjectData.exploitationData[vtwCode];
  }
  return {};
};

export const selectLoadedProject = (state) => {
  if (state.userProjectData.projectIdLoaded) {
    return selectUserBeheerObjectByKey(
      state,
      state.userProjectData.projectIdLoaded
    );
  }
  return undefined;
};

export const {
  selectAll: selectUserBeheerObjecten,
  selectById: selectUserBeheerObjectByKey,
} = projectAdapter.getSelectors((state) => state.userProjectData);
