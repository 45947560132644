import { useNavigate, useParams } from "react-router";
import Loader from "../components/loader";
import { useEffect } from "react";
import {
  selectRedirectUrlStatus,
  selectRedirectUrl,
  getredirecturl,
  selectRedirectError,
} from "../redux/slices/masterDataSlice";
import { useDispatch, useSelector } from "react-redux";

import * as states from "../redux/states";
import * as pages from "../navigation";
import { isUrlRemote } from "../modules/revUrl";

const CmsRedirect = () => {
  const { route } = useParams();
  const url = useSelector((state) => selectRedirectUrl(state, route));
  const urlState = useSelector(selectRedirectUrlStatus);
  const error = useSelector(selectRedirectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!url.hasRoute && urlState !== states.loading) {
      const isValid = route.match(/^([a-zA-Z0-9]{3,6})$/g);
      if (isValid) {
        dispatch(getredirecturl({ route }));
      } else {
        navigate(pages.notfound);
      }
    }
    if (url.hasRoute && urlState === states.succeeded) {
      if (url.routeUrl) {
        if (isUrlRemote(url.routeUrl)) {
          window.location.href = url.routeUrl;
        } else {
          navigate(url.routeUrl);
        }
      } else {
        navigate(pages.notfound);
      }
    }
  }, [url, urlState, dispatch, navigate, route]);

  return (
    <>
      {url.hasRoute && error && (
        <label className="has-text-danger is-italic">{error}</label>
      )}
      <Loader center={true} />
    </>
  );
};

export default CmsRedirect;
