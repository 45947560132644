import { motion } from "framer-motion";

const transition = (OgComponent) => {
  return () => (
    <div className="transition">
      {/* <motion.div
        className="slide-in "
        initial={{ scaleY: 0, scaleX: 0 }}
        animate={{ scaleY: 0, scaleX: 0 }}
        exit={{ scaleY: 1, scaleX: 1 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      ></motion.div>

      <motion.div
        className="slide-out "
        initial={{ scaleY: 1, scaleX: 1 }}
        animate={{ scaleY: 0, scaleX: 0 }}
        exit={{ scaleY: 0, scaleX: 0 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      />
      <OgComponent /> */}
      {/* <motion.div
        className="slide-out "
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25, ease: [0.22, 1, 0.36, 1] }}
      ></motion.div> */}

      <motion.div
        className="slide-in"
        initial={{ skew: 50 }}
        animate={{ skew: 0 }}
        exit={{ skew: 0 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      >
        <OgComponent />
      </motion.div>
    </div>
  );
};

export default transition;
