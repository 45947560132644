export const youtubePlaylist =
  "https://www.youtube.com/playlist?list=PLqlV5XIeMvY01H4qmkcIRQInJtFUlyzyW";

export const shop = "https://www.vaoshop.nl/";
export const shopBasis = "https://www.vaoshop.nl/basis2024";
export const shopBusiness = "https://www.vaoshop.nl/business2024";
export const shopPro = "https://www.vaoshop.nl/pro2024";
export const shopUpgrade = "https://www.vaoshop.nl/upgrade";
export const shopAbonnement = "https://www.vaoshop.nl/abonnement";

export const koetervastgoed = "https://koetervastgoedadviseurs.nl/";

export const signUp =
  "https://evao.us4.list-manage.com/subscribe?u=42dfc9dc0f304ca51b4d477c5&id=7be2dd0ff9";
