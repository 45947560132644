function Loader(props) {
  return (
    <>
      {props.center && (
        <div className="loader-center-modal">
          <div className="loader"></div>
        </div>
      )}
      {!props.center && <span className="bulma-loader-mixin"></span>}
    </>
  );
}
export default Loader;

export const FormLoader = () => {
  return (
    <div className="field is-horizontal">
      <div className="field-body primary-form-loader">
        <div className="loader"></div>
      </div>
    </div>
  );
};
