export const redirectSignIn = "/signin";
export const signIn = "/signin-oidc";
export const signOut = "/signout-callback-oidc";

export const projectPage = "/project";
export const projectKenmerken = "/kenmerken";
export const projectOnderhoudskosten = "/kengetallen";
export const projectRapportage = "/rapportage";
export const projectPlan = "/plan";

export const userProjectsPage = "/projects";
export const userComplexMjopPage = "/projects/complex-mjop";
export const newproject = "/newproject";
export const zonnepaneelcalculatie = "/zonnepaneelcalculatie";
export const warmtepompcalculatie = "/warmtepompcalculatie";
export const lesson = "/lesstof";
export const user = "/user";
export const home = "/";
export const notfound = "/notfound";

/*  algemene paginas */

export const cookies = "/cookies";
export const handigeLinks = "/handige-links";
export const overRev = "/over-rev";
export const realEstateValuator = "/real-estate-valuator";
export const webservices = "/webservices";
export const support = "/support";
export const events = "/events";
export const disclaimer = "/disclaimer";
export const toelichtingOnderhoud = "/toelichting-kengetallen";
export const releaseNotes = "/release-notes";
export const cursussen = "/cursussen";

export const bouwgebreken = "/bouwgebreken";
export const bouwgebrekenbouwkundig = "/bouwgebreken/bouwkundig";
export const bouwgebrekeninstallaties = "/bouwgebreken/installaties";
export const kengetallen = "/kengetallen";
export const all = "/all";
export const kennisbank = "/kennisbank";
export const nieuws = "/nieuws";
export const wikivastgoed = "/wikivastgoed";

export const vtwBeleggingsvastgoed = "/vastgoed-taxatiewijzer-beleggingsvastgoed";
export const vtwMaatschappelijksvastgoed = "/vastgoed-taxatiewijzer-maatschappelijk-vastgoed";
export const vtwBouwgebreken = "/vastgoed-taxatiewijzer-bouwgebreken";
export const vtwOnderhoudskostenkompas = "/vastgoed-taxatiewijzer-onderhoudskostenkompas";

/* quickstart paginas */
export const quickStart = "/quickstart";
export const quickStartAddress = `${quickStart}/address`;
export const quickStartBuildingType = `${quickStart}/buildingtype`;
export const quickStartSelectionList = `${quickStart}/selectionlist`;
export const quickStartDone = `${quickStart}/done`;

/* Nieuws pagina's */
export const webApp = "/nieuws/app";

export const cmsIndexSearch = [
  "all",
  "bouwgebreken",
  "kennisbank",
  "nieuws",
  "wikivastgoed",
];
