import * as classes from "../../../custom-scripts/custom-javascript-classes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DoubleFormButton } from "../../../components/common/FormButton";
import { faEdit, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as pages from "../../../navigation";
import SearchBar from "../../../components/searchBar";
import { RevModalTransparentSmall } from "../../../components/ModalDisplay";
import { SelectInput } from "../../../components/common/SelectInput";
import { Link } from "react-router-dom";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const UserProjects = ({
  onSearchChange,
  currentProjectId,
  filteredObjects,
  getExistingProject,
  deleteProject,
  copyProject,
  userComplexes,
  onSelectComplex,
  currentComplex,
  sortOnColumn,
  sortSettings,
  hasProjects,
}) => {
  const hasProjectAndComplex = hasProjects && userComplexes.length !== 0;

  let primaryProj = filteredObjects.filter(
    (proj) =>
      proj.id === currentProjectId ||
      (proj.isNew && proj.vtwCode === currentProjectId)
  );

  let otherProjects = filteredObjects.filter(
    (proj) => proj.id !== currentProjectId && !proj.isNew
  );

  const toggleModal = (e) => {
    const target = e.currentTarget.dataset.target;
    document.getElementById(target).classList.toggle("is-hidden");
  };

  const getColumnSort = (sort) => {
    const index = sortSettings.findIndex((x) => x.sort === sort);
    const val = sortSettings[index];
    return val.asc;
  };

  const vtwDown = getColumnSort("vtwCode");
  const nameDown = getColumnSort("name");
  const dateDown = getColumnSort("createDateShort");

  const ActionModal = ({
    actionText,
    confirmText,
    toggleModal,
    dataTarget,
    onClick,
  }) => {
    return (
      <RevModalTransparentSmall id={dataTarget}>
        <section className="hero is-fullheight">
          <div className="hero-body has-text-centered">
            <div className="container">
              <div className="box">
                <h4 className="title is-5 is-size-6-mobile">{actionText}</h4>
                <DoubleFormButton
                  primaryOnClick={onClick}
                  primaryText={confirmText}
                  primaryDataTarget={dataTarget}
                  secondaryOnClick={toggleModal}
                  secondaryText="Annuleer"
                  secondaryDataTarget={dataTarget}
                />
              </div>
            </div>
          </div>
        </section>
      </RevModalTransparentSmall>
    );
  };

  const ProjectRow = ({ object, isCurrentProject }) => {
    const id = object.id;
    const targetDelete = `${id}-delete`;
    const targetDuplicate = `${id}-duplicate`;
    return (
      <tr
        key={id}
        className={
          isCurrentProject
            ? `rev-highlight-edited ${classes.elementWithTarget}`
            : classes.elementWithTarget
        }
        data-target={object.isNew ? object.vtwCode : object.id}
        data-isnew={object.isNew}
      >
        <td
          className="is-size-8-mobile is-hidden-touch is-clickable"
          onClick={(e) => getExistingProject(e)}
        >
          {object.complexName}
        </td>
        <td
          className="is-size-8-mobile is-hidden-touch is-clickable"
          onClick={(e) => getExistingProject(e)}
        >
          {object.createDateShort}
        </td>
        <td
          className="is-size-8-mobile is-clickable"
          onClick={(e) => getExistingProject(e)}
        >
          {object.vtwCode}
        </td>
        <td
          className={`is-size-8-mobile is-clickable`}
          onClick={(e) => getExistingProject(e)}
        >
          {object.name}
        </td>
        <td className={"is-clickable"} onClick={(e) => getExistingProject(e)}>
          <span className="icon is-small fa-xl is-left rev-highlight-edit">
            <FontAwesomeIcon icon={faEdit} />
          </span>
          <span className="is-hidden-touch rev-highlight-edit">
            &nbsp;Bewerk
          </span>
        </td>
        <td
          className={"is-clickable"}
          onClick={(e) => {
            !object.isNew ? toggleModal(e) : e.preventDefault();
          }}
          data-target={targetDuplicate}
        >
          <span className="icon is-small fa-xl is-left rev-highlight-copy">
            <FontAwesomeIcon icon={faCopy} />
          </span>
          <span className="is-hidden-touch rev-highlight-copy">
            &nbsp;Dupliceer
          </span>
          {!object.isNew && (
            <ActionModal
              onClick={(e) => {
                toggleModal(e);
                copyProject(e, id, isCurrentProject);
              }}
              toggleModal={toggleModal}
              actionText={`Dupliceer project '${object.name}'`}
              confirmText={"Dupliceer"}
              dataTarget={targetDuplicate}
            />
          )}
        </td>
        <td
          className={"is-clickable"}
          onClick={toggleModal}
          data-target={targetDelete}
        >
          <span className="icon is-small fa-xl is-left rev-highlight-delete">
            <FontAwesomeIcon icon={faTrash} />
          </span>
          <span className="is-hidden-touch rev-highlight-delete">
            &nbsp;Verwijder
          </span>
          <ActionModal
            onClick={(e) => deleteProject(e, id, isCurrentProject)}
            toggleModal={toggleModal}
            actionText={`Verwijder project '${object.name}'`}
            confirmText={"Verwijder"}
            dataTarget={targetDelete}
          />
        </td>
      </tr>
    );
  };

  return (
    <section className="section">
      <div style={{ paddingTop: "10px" }}>
        <div className="container pb-5 is-mobile-pb-1">
          <div className="is-hidden-tablet">
            <h1
              className="title is-size-5-mobile has-text-weight-bold"
              style={{ display: "inline-block" }}
            >
              Projecten
            </h1>
            <br />
            <Link
              className={"button is-primary my-1"}
              to={pages.quickStartAddress}
            >
              Quickstart
            </Link>

            <br />
            <Link className={"button is-primary my-1"} to={pages.newproject}>
              Overzicht Referentiegebouwen
            </Link>
            {hasProjectAndComplex && (
              <>
                <br />
                <Link
                  className={"button is-primary my-1"}
                  to={pages.userComplexMjopPage}
                >
                  Complex mjop
                </Link>
              </>
            )}
          </div>
          <div className="is-hidden-mobile">
            <h1
              className="title is-size-5-mobile has-text-weight-bold"
              style={{ display: "inline-block" }}
            >
              Projecten
            </h1>
            &nbsp; &nbsp; &nbsp;&nbsp;
            <Link className={"button is-primary"} to={pages.quickStartAddress}>
              Quickstart
            </Link>
            &nbsp;&nbsp;
            <Link className={"button is-primary"} to={pages.newproject}>
              Overzicht Referentiegebouwen
            </Link>
            {hasProjectAndComplex && (
              <>
                &nbsp;&nbsp;
                <Link
                  className={"button is-primary"}
                  to={pages.userComplexMjopPage}
                >
                  Complex mjop
                </Link>
              </>
            )}
          </div>
          {hasProjects && (
            <SearchBar
              searchOnChange={onSearchChange}
              placeholder={"zoek op omschrijving"}
            />
          )}
        </div>

        {hasProjects && userComplexes.length !== 0 && (
          <section className="section is-small p-1">
            <div className="container">
              <div className="control">
                <SelectInput
                  name="complex"
                  label={"Zoek op complex"}
                  onChange={onSelectComplex}
                  placeholder={"Kies complex"}
                  value={currentComplex}
                  className={"is-rounded"}
                  options={userComplexes}
                />
              </div>
            </div>
          </section>
        )}

        {hasProjects && (
          <section className="container is-small pt-4 pb-1">
            <table className="table rev-table-projectlist is-fullwidth is-striped is-hoverable">
              <thead>
                <tr className="has-background-primary">
                  <th className="is-size-7-mobile has-text-white is-hidden-touch">
                    Complex
                  </th>
                  <th
                    onClick={(e) => sortOnColumn(e, "createDateShort")}
                    className="is-size-7-mobile has-text-white is-hidden-touch"
                  >
                    Datum
                    {dateDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    )}
                    {!dateDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleUp} />
                      </span>
                    )}
                  </th>
                  <th
                    className="is-size-7-mobile has-text-white is-clickable"
                    onClick={(e) => sortOnColumn(e, "vtwCode")}
                  >
                    Gebouwtype
                    {vtwDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    )}
                    {!vtwDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleUp} />
                      </span>
                    )}
                  </th>

                  <th
                    className="is-size-7-mobile has-text-white is-clickable"
                    onClick={(e) => sortOnColumn(e, "name")}
                  >
                    Omschrijving
                    {nameDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    )}
                    {!nameDown && (
                      <span className={`icon`}>
                        <FontAwesomeIcon icon={faAngleUp} />
                      </span>
                    )}
                  </th>
                  <th colSpan={3}></th>
                </tr>
              </thead>
              {primaryProj && (
                <tbody>
                  {primaryProj.map((object) => (
                    <ProjectRow
                      key={object.id}
                      object={object}
                      isCurrentProject={true}
                    />
                  ))}
                </tbody>
              )}
              {otherProjects && (
                <tbody>
                  {otherProjects.map((object) => (
                    <ProjectRow
                      key={object.id}
                      object={object}
                      isCurrentProject={false}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </section>
        )}
      </div>
    </section>
  );
};

export default UserProjects;
