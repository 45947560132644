import { useState } from "react";
import * as classes from "../custom-scripts/custom-javascript-classes";
import { RevImgSelection } from "./common/RevImg";

const RevImageSelector = ({ images, isAuthorized }) => {
  const [index, setIndex] = useState(0);
  const count = images.length;

  const onClick = (newIndex) => {
    setIndex(newIndex);
  };

  return (
    <>
      {count > 0 && (
        <figure className={classes.image}>
          <img
            src={images[index].src}
            alt={images[index].altText}
            title={images[index].title}
          />
        </figure>
      )}
      {isAuthorized && count > 1 && (
        <>
          <br />
          {images.map((image, i) => {
            return (
              <RevImgSelection
                src={image.src}
                alt={image.altText}
                title={image.title}
                index={i}
                onClick={onClick}
                isSelected={i === index}
                key={image.src}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default RevImageSelector;
