// right now this is only used in display states.

export const StoreInSession = (key, value) => {
  if (value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    ClearSessionKey(key);
  }
};

export const RetrieveFromSession = (key, _default) => {
  const item = sessionStorage.getItem(key);
  if (item !== undefined) {
    if (item !== null) {
      return JSON.parse(item);
    }
  }
  return _default;
};

export const ClearSessionKey = (key) => {
  sessionStorage.removeItem(key);
};

// vat storage for new project
const newProjectVat = "newproject-include-vat";
export const StoreVatInSession = (value) => {
  StoreInSession(newProjectVat, value);
};
export const RetrieveVatFromSession = () => {
  return RetrieveFromSession(newProjectVat, false);
};

// DO not use. Do not overuse as
// export const ClearSession = () => {
//   return sessionStorage.clear();
// };
