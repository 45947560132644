import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
//export class Start extends Component {
export default function SearchBar({
  searchOnChange,
  startValue,
  placeholder = "type hier uw zoekopdracht",
}) {
  return (
    <section className="section is-small p-1">
      <div className="container">
        <div className="control has-icons-left">
          <input
            className="input is-rounded"
            type="text"
            onChange={searchOnChange}
            id="outlined-basic"
            placeholder={placeholder}
            value={startValue}
          />
          <span className="icon has-text-grey is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>
    </section>
  );
}
