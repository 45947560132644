import { combineReducers, configureStore } from "@reduxjs/toolkit";

import cmsReducer from "./slices/cmsNavMenuSlice";
import userReducer from "./slices/userSlice";
import cmsContentReducer from "./slices/cmsSearchDataSlice";
import vtwStamSlice from "./slices/vtwSlice";
import userProjectSlice from "./slices/userProjectSlice";
import masterDataSlice from "./slices/masterDataSlice";
import fileDownloadSlice from "./slices/fileDownloadSlice";
import cmsTypeContentIndexSlice from "./slices/cmsTypeContentIndexSlice";
import seoMetaDataSlice from "./slices/seoMetaDataSlice";
import generalSlice from "./slices/generalSlice";
import quicksStartDataSlice from "./slices/quickStartSlice";
// https://stackoverflow.com/questions/68421040/local-storage-using-redux-toolkit
// session storage on mobile: 5mb
// dit moet kapot getest worden

//MIDDLEWARE
const sesionStorageMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    sessionStorage.setItem("applicationState", JSON.stringify(getState()));
    return result;
  };
};

const reHydrateStore = () => {
  if (sessionStorage.getItem("applicationState") !== null) {
    return JSON.parse(sessionStorage.getItem("applicationState")); // re-hydrate the store
  }
};

const combinedReducer = combineReducers({
  cmsNavMenuData: cmsReducer,
  userData: userReducer,
  cmsContent: cmsContentReducer,
  vtwStamData: vtwStamSlice,
  userProjectData: userProjectSlice,
  masterData: masterDataSlice,
  fileDownload: fileDownloadSlice,
  cmsTypeIndex: cmsTypeContentIndexSlice,
  seoMetaData: seoMetaDataSlice,
  general: generalSlice,
  quicksStartData: quicksStartDataSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "general/clear") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sesionStorageMiddleware),
});
