import * as states from "../states";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  municipalities: [],
  municipalityState: states.idle,
  municpalityError: null,
  redirects: {},
  redirectState: states.idle,
  redirectError: null,
};

// Thunks!
export const loadMunicipalitydata = createAsyncThunk(
  "loadmunicipalitydata",
  async () => {
    const response = await client.get(`/api/masterData/municipalities`);
    return JSON.stringify(response.data);
  }
);

export const getredirecturl = createAsyncThunk(
  "getredirecturl",
  async ({ route }) => {
    const response = await client.get(
      `/api/masterData/getredirecturl/${route}`
    );
    return JSON.stringify(response.data);
  }
);

const masterDataSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadMunicipalitydata.pending, (state, action) => {
        state.municipalityState = states.loading;
      })
      .addCase(loadMunicipalitydata.fulfilled, (state, action) => {
        state.municipalityState = states.succeeded;
        var data = JSON.parse(action.payload);
        data.unshift({ id: "", name: "geen" });
        state.municipalities = data;
      })
      .addCase(loadMunicipalitydata.rejected, (state, action) => {
        state.municipalityState = states.failed;
        state.municpalityError = action.error.message;
      })
      .addCase(getredirecturl.pending, (state, _) => {
        state.redirectState = states.loading;
        state.redirectError = "";
      })
      .addCase(getredirecturl.fulfilled, (state, action) => {
        state.redirectState = states.succeeded;
        var data = JSON.parse(action.payload);
        state.redirects[action.meta.arg.route] = data.route;
      })
      .addCase(getredirecturl.rejected, (state, action) => {
        state.redirectState = states.failed;
        state.redirectError = action.error.message;
      });
  },
});

export default masterDataSlice.reducer;

export const selectMunicipalityStatus = (state) =>
  state.masterData.municipalityState;

export const selectmunicipalities = (state) => state.masterData.municipalities;

export const selectRedirectUrlStatus = (state) =>
  state.masterData.redirectState;

export const selectRedirectError = (state) => state.masterData.redirectError;

export const selectRedirectUrl = (state, route) => {
  var hasRoute = Object.hasOwn(state.masterData.redirects, route);
  return { hasRoute, routeUrl: state.masterData.redirects[route] };
};
