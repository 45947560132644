import { NavLink } from "react-router-dom";
import RevNavLink from "./RevNavLink";

export const LessonNavBarSubjectLink = ({ article, path }) => {
  return (
    <RevNavLink
      text={article.title}
      path={`${path}${article.path}`}
      className={`${
        article.contentRequiresAccount ? "rev-authorized-content" : ""
      }`}
    />
  );
};

export const LessonSubjectLink = ({ article, path }) => {
  return (
    <NavLink
      to={`${path}${article.path}`}
      className={`${
        article.contentRequiresAccount ? "rev-authorized-content" : ""
      }`}
    >
      {article.title}
    </NavLink>
  );
};
