import { useState, useEffect, useCallback } from "react";
import { FloatingFormSaveButton } from "../../components/common/FormButton";
import * as states from "../../redux/states";

import {
  saveUserProject,
  selectProjectBouwdelen,
  selectProjectHandelingen,
  selectLoadProjectDataStatus,
} from "../../redux/slices/userProjectSlice";
import {
  GetCanSave,
  SetCanSave,
  GetProjectImage,
  ClearProjectImage,
} from "./ProjectSession";
import { useDispatch, useSelector } from "react-redux";
import { selectIsProjectAuthorized } from "../../redux/slices/userSlice";

const ProjectSaveButton = ({ project, errors }) => {
  const [saving, setSaving] = useState(false);
  const [image, setImageData] = useState();
  const canSaveBool = GetCanSave();
  const saveText = saving ? "Bezig met opslaan..." : "Opslaan";
  const dispatcher = useDispatch();

  const bouwdelen = useSelector(selectProjectBouwdelen);
  const handelingen = useSelector(selectProjectHandelingen);
  const isAuthorized = useSelector(selectIsProjectAuthorized);

  let errorMessage = "Fout tijdens het opslaan";

  const imageUrl = GetProjectImage();

  const fetchData = useCallback(async () => {
    const response = await fetch(imageUrl)
      .then((x) => x.blob())
      .catch((e) => {
        ClearProjectImage();
      });

    if (response) {
      const reader = new FileReader();
      reader.onload = () => {
        const tempImage = {
          fileName: response.name,
          mimeType: response.type,
          imageData: reader.result,
        };
        setImageData(tempImage);
      };
      reader.readAsDataURL(response);
    }
  }, [imageUrl]);

  const hasFailure = useSelector(selectLoadProjectDataStatus) === states.failed;
  useEffect(() => {
    if (imageUrl) {
      fetchData();
    }
  }, [fetchData, imageUrl]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSaveProjectClick = (event) => {
    // push all the project crap as is over to the server
    event.stopPropagation();
    setSaving(true);
    ClearProjectImage();
    dispatcher(
      saveUserProject({
        project,
        bouwdelen,
        handelingen,
        image,
      })
    ).then(async (e) => {
      SetCanSave(false);
      await delay(500);
      setSaving(false);
    });
  };

  if (errors) {
    const key = Object.keys(errors);
    const value = errors[key];
    // get rid of concatinated BS
    errorMessage = `Gevonden fout: ${value}`;
  }

  return (
    <>
      {isAuthorized && project && canSaveBool && !saving && !hasFailure && (
        <FloatingFormSaveButton
          text={saveText}
          onClick={onSaveProjectClick}
          disabled={saving}
          slide={true}
        />
      )}
      {isAuthorized && project && saving && !hasFailure && (
        <FloatingFormSaveButton
          text={saveText}
          slideBack={true}
          disabled={true}
          id={"saving-button"}
        />
      )}
      {isAuthorized && project && hasFailure && (
        <FloatingFormSaveButton
          text={saveText}
          onClick={onSaveProjectClick}
          disabled={saving}
          extraClassName={"is-danger"}
          extraMessage={errorMessage}
        />
      )}
    </>
  );
};

export default ProjectSaveButton;
