import { useEffect } from "react";
import { GetLessonPath } from "../../modules/revCmsNavigation";
import { useSelector } from "react-redux";
import {
  selectCmsByPath,
  fetchCmsForPath,
  selectCmsContentState,
} from "../../redux/slices/cmsSearchDataSlice";
import LessonSelection from "./LessonSelection";

const LessonSelectionManager = ({ dispatcher }) => {
  const querystring = GetLessonPath({ cmsType: "kennisbank" });

  const result = useSelector((state) => {
    return selectCmsByPath(state, querystring);
  });
  useEffect(() => {
    if (!result) {
      dispatcher(fetchCmsForPath(querystring));
    }
  }, [dispatcher, querystring, result]);

  const state = useSelector(selectCmsContentState);

  return <>{result && <LessonSelection state={state} result={result} />}</>;
};

export default LessonSelectionManager;
