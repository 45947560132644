import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export const ImageProjectFileInput = ({
  label,
  placeholder,
  onChange,
  errors,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">&nbsp;</label>
      </div>
      <div className="field-body">
        <FileInput
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          errors={errors}
          accept="image/png, image/jpeg"
        />
      </div>
    </div>
  );
};

export const FileInput = ({
  label,
  placeholder,
  onChange,
  errors,
  accept = "image/png, image/jpeg",
}) => {
  let className = "file-input";
  if (errors && errors.lenth > 0) {
    className = "is-danger file-input";
  }

  return (
    <div className="file">
      <label className="file-label">
        <input
          type="file"
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          accept={accept}
        />
        <span className="file-cta">
          <span className="file-icon">
            <FontAwesomeIcon icon={faUpload} />
          </span>
          <span className="file-label">{label}</span>
        </span>
      </label>
      {errors && <span className="help is-danger">&nbsp;{errors[0]}</span>}
    </div>
  );
};
