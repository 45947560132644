import { DangerousDiv } from "../../components/dangerousGerneric";
import ComponentInjector from "../cms/components/ComponentInjector";
import LessonContent from "./LessonContent";
import Helaas from "../../components/aboNodig";

const ScrollPage = ({ article, lessons }) => {
  if (article && article.contents && article.contents.length > 0) {
    lessons.unshift(article);
  }

  const show = article || lessons.length > 0;
  const showAbbonementInfo =
    (article && article.contentRequiresAccount) ||
    lessons.filter((l) => l.contentRequiresAccount).length > 0;
  const showSubarticleTitle =
    (article && lessons.length !== 1) || showAbbonementInfo;

  return (
    <>
      {show && (
        <section className="section pt-0">
          {article && (
            <ComponentInjector component={article.path}>
              <div className="container">
                <h1 className="title has-text-weight-bold is-size-4-mobile">
                  {article.title}
                </h1>
              </div>
            </ComponentInjector>
          )}

          <div className="container">
            <LessonContent articles={lessons} />

            {showAbbonementInfo && <Helaas />}
            {lessons.map((article, i) => {
              return (
                <div className="rev-lesson-article" key={i} id={i}>
                  {showSubarticleTitle && (
                    <h2
                      className={`subtitle is-size-5-mobile has-text-weight-bold is-4 ${
                        article.contentRequiresAccount
                          ? "rev-authorized-content has-text-grey-lighter"
                          : "has-text-primary"
                      }`}
                    >
                      {article.title}
                    </h2>
                  )}

                  <DangerousDiv
                    content={
                      article.contents
                        ? article.contents[0]?.content
                        : undefined
                    }
                    className="content"
                  />
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default ScrollPage;
