import * as states from "../../redux/states";
import { useSelector } from "react-redux";
import ProjectRapportage from "./ProjectRapportage";

import {
  selectFileDownloadState,
  downloadProjectWordReport,
  downloadProjectPdfReport,
  selectFileDownloadErrorMessage,
} from "../../redux/slices/fileDownloadSlice";
import { userHasBasicSubscription } from "../../redux/slices/userSlice";
import { GetCanSave } from "./ProjectSession";
import { useEffect, useState } from "react";

const ProjectRapportageManager = ({ id, project, dispatcher, errors }) => {
  const [isWorking, setIsWorking] = useState(false);
  const [, setCanSave] = useState(false);
  const downloadWord = () => {
    setIsWorking(true);
    dispatcher(
      downloadProjectWordReport({
        projectId: project.id,
        postalCode: project.postalCode,
        houseNumber: project.houseNumber,
        year: project.year,
        name: `REV Rapport ${project.name}`,
      })
    ).finally((x) => setIsWorking(false));
  };

  const downloadPdf = () => {
    setIsWorking(true);
    dispatcher(
      downloadProjectPdfReport({
        projectId: project.id,
        name: `REV Rapport ${project.name}`,
      })
    ).finally((x) => setIsWorking(false));
  };

  const tempCanSave = GetCanSave();
  useEffect(() => {
    if (project.canSave !== tempCanSave) {
      setCanSave(tempCanSave);
    } else {
      setCanSave(project.canSave);
    }
  }, [project, tempCanSave]);
  const state = useSelector(selectFileDownloadState);
  const message = useSelector(selectFileDownloadErrorMessage);

  const userIsBasicSubscription = useSelector(userHasBasicSubscription);

  return (
    <>
      {userIsBasicSubscription && (
        <ProjectRapportage
          downloadWord={downloadWord}
          downloadPdf={downloadPdf}
          downloadFailed={state === states.failed}
          downloadMessage={message}
          canSave={tempCanSave}
          isWorking={isWorking}
          project={project}
        />
      )}
    </>
  );
};

export default ProjectRapportageManager;
