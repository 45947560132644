import { useState } from "react";
import Loader from "../../../components/loader";
import * as states from "../../../redux/states";
import NewProjectList from "./NewProjectList";
import NewProjectSelection from "./NewProjectSelection";
import {
  PeriodsForBeheerObjecten,
  FilterForPeriod,
  PeriodOverlappingChoices,
  RoofTypesForBeheerObjecten,
  RoofTypes,
  FilterForRoofType,
  FilterBeheerObjectenForObjectType,
} from "../../../modules/revCalculation";
import RevHeader from "../../../components/header";

function NewProjectPage({
  beheerObjecten,
  objectSoorten,
  state,
  createProject,
  updateExploitationVtw,
  exploitationData,
  exploitationDataLoading,
  isAuthorized,
  calculationYear,
}) {
  const [objectSoortId, setObjectSoortId] = useState(0);
  const [vtwCode, setVtwCode] = useState("");
  const [objectTypeId, setObjectTypeId] = useState(0);
  const [periodId, setPeriodId] = useState(0);
  const [roofId, setRoofId] = useState(0);
  let objectTypen = [];

  const selectObjectSoort = (e) => {
    setVtwCode("");
    setObjectSoortId(Number(e.target.value));
    setObjectTypeId(0);
    setRoofId(0);
    setPeriodId(0);
  };

  const changeVtwCode = (e) => {
    setVtwCode(e.target.value);
    setObjectSoortId(0);
  };

  const selectObjectType = (e) => {
    setObjectTypeId(Number(e.target.value));
    setPeriodId(0);
    setRoofId(0);
  };

  const selectPeriod = (e) => {
    setPeriodId(Number(e.target.value));
  };

  const selectRoofType = (e) => {
    setRoofId(Number(e.target.value));
  };

  if (vtwCode !== "") {
    beheerObjecten = beheerObjecten.filter((gebouw) => {
      return (
        gebouw.vtwCode.toLowerCase().startsWith(vtwCode.toLowerCase()) ||
        gebouw.fotoWijzerCode.toLowerCase().startsWith(vtwCode.toLowerCase()) ||
        gebouw.searchVtwCode.toLowerCase().startsWith(vtwCode.toLowerCase())
      );
    });
  }

  if (objectSoortId !== 0) {
    beheerObjecten = beheerObjecten.filter(
      (gebouw) => gebouw.objectSoortId === objectSoortId
    );
  }

  // filter for object type
  let periods = PeriodOverlappingChoices;
  let roofTypes = RoofTypes;

  if (objectTypeId !== 0) {
    beheerObjecten = FilterBeheerObjectenForObjectType(
      beheerObjecten,
      objectTypeId
    );
    periods = PeriodsForBeheerObjecten(beheerObjecten);
    roofTypes = RoofTypesForBeheerObjecten(beheerObjecten);
  }

  const currentSoort = objectSoorten.find(
    (soort) => soort.id === objectSoortId
  );

  const isWoning = objectSoortId === 1 || objectSoortId === 8;

  if (currentSoort) {
    objectTypen = currentSoort.objectTypen;
  }

  if (periodId !== 0) {
    beheerObjecten = FilterForPeriod(beheerObjecten, periodId);
  }

  if (roofId !== 0) {
    beheerObjecten = FilterForRoofType(beheerObjecten, roofId);
  }

  return (
    <>
      {state !== states.succeeded && <Loader center={true} />}
      <RevHeader
        title={`Overzicht referentiegebouwen`}
        subSubTitle={`Prijspeil: januari ${calculationYear}`}
      />
      <section className="section">
        <div className="container has-text-centered">
          {state === states.succeeded && (
            <div>
              <NewProjectSelection
                isSelection={true}
                className="is-rounded"
                onChange={selectObjectSoort}
                label="Kies object soort"
                value={objectSoortId}
                placeholder="Object soort"
                options={objectSoorten}
              />
              {objectSoortId === 0 && (
                <NewProjectSelection
                  onChange={changeVtwCode}
                  className="is-rounded"
                  label="Kies vtw / foto wijzer code"
                  placeholder="Vtw / foto wijzer"
                  value={vtwCode}
                />
              )}
            </div>
          )}
          {state === states.succeeded && objectSoortId !== 0 && (
            <NewProjectSelection
              isSelection={true}
              className="is-rounded"
              onChange={selectObjectType}
              label="Kies object type"
              value={objectTypeId}
              placeholder="Object type"
              options={objectTypen}
            />
          )}
          {state === states.succeeded && objectTypeId !== 0 && (
            <>
              {isWoning && (
                <NewProjectSelection
                  isSelection={true}
                  className="is-rounded"
                  onChange={selectPeriod}
                  label="Kies bouwperiode"
                  value={periodId}
                  options={periods}
                />
              )}
              <NewProjectSelection
                isSelection={true}
                className="is-rounded"
                onChange={selectRoofType}
                label="Kies daktype"
                value={roofId}
                options={roofTypes}
              />
            </>
          )}
        </div>
      </section>
      {state === states.succeeded && (
        <NewProjectList
          beheerObjecten={beheerObjecten}
          createProject={createProject}
          updateExploitationVtw={updateExploitationVtw}
          exploitationData={exploitationData}
          exploitationDataLoading={exploitationDataLoading}
          isAuthorized={isAuthorized}
        />
      )}
    </>
  );
}

export default NewProjectPage;
