import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  version: "",
  versionIsUpdated: false,
  error: null,
};

export const fetchCmsVersion = createAsyncThunk(
  "cms/fetchcmsversion",
  async () => {
    const response = await client.get(`/api/cmscontent/getversion`);
    return response.data;
  }
);

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    clear: (state) => {
      // From here we can take action only at this "general" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
    finishedUpdate: (state) => {
      state.versionIsUpdated = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCmsVersion.fulfilled, (state, action) => {
      var data = action.payload;
      if (state.version === "") {
        state.version = data.version;
      } else if (state.version !== data.version) {
        state.version = data.version;
        state.versionIsUpdated = true;
      } else {
        state.versionIsUpdated = false;
      }
    });
  },
});

export const selectVersionIsChanged = (state) => state.general.versionIsUpdated;

export const { clear, finishedUpdate } = generalSlice.actions;

export default generalSlice.reducer;
