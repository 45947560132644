export const toggleBox = "rev-toggle-box-control";
export const carret = "rev-carret";
export const toggleTab = "rev-toggle-tab";
export const elementWithTarget = "rev-target-container";

// these have css aplied to them
export const tabbedNavigation = "rev-tabbed-nav";
export const tab = "rev-tab";
export const hiddenTab = "rev-hidden-tab";
export const shrinkTab = "rev-shrink-tab";
export const hiddenOnTouch = "rev-hidden-on-touch";

export const image = "rev-image";
export const imageLimiter = "rev-image-limiter";
