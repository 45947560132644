import React from "react";

function Burger() {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <label
        role="button"
        className="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="revtogglemenu"
        htmlFor="navbar-burger-toggle"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </label>
    </>
  );
}

export default Burger;
