import { ClearProjectSessionData, SetCanSave } from "./ProjectSession";
import {
  getExistingUserProject,
  getNewUserProject,
} from "../../redux/slices/userProjectSlice";
import * as pages from "../../navigation";
import * as states from "../../redux/states";
import {
  RetrieveVatFromSession,
  StoreInSession,
} from "../../modules/revSessionStorage";
import { returnUrlKey } from "../../oidc/userService";
import { deletePublicData } from "../../redux/slices/quickStartSlice";

const RetrieveProject = (
  projectId,
  idIsGuid,
  dispatcher,
  navigate,
  setLoading,
  searchParams,
  publicData
) => {
  if (idIsGuid) {
    // id is a guid. load existing project
    dispatcher(
      getExistingUserProject({
        id: projectId,
      })
    )
      .then((result) => {
        if (result.meta.requestStatus === states.rejected) {
          navigate(pages.notfound);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    //get new project, because it's an vtwCode (probably)
    let includeVat = RetrieveVatFromSession();
    SetCanSave(true);
    let projectParameters = { includeVat, cycle: 99 };
    if (searchParams && searchParams.size > 0) {
      projectParameters = {
        cycle: 99,
        includeVat,
        ...Object.fromEntries([...searchParams]),
      };

      if (projectParameters.cycle) {
        if (projectParameters.cycle === "uitponden") {
          projectParameters.cycle = 25;
        } else if (projectParameters.cycle === "doorexploitatie") {
          projectParameters.cycle = 99;
        } else if (!isNaN(projectParameters.cycle)) {
          projectParameters.cycle = Number(projectParameters.cycle);
        }
      }
      if (
        projectParameters.includeVat.toString() === "1" ||
        projectParameters.includeVat.toString() === "0"
      ) {
        projectParameters.includeVat =
          projectParameters.includeVat === "1" ? true : false;
      }
    } else if (publicData) {
      projectParameters = { ...publicData };
    }

    projectParameters.vtwCode = projectId.toUpperCase();

    dispatcher(getNewUserProject({ ...projectParameters }))
      .then((result) => {
        if (result.meta.requestStatus === states.rejected) {
          navigate(pages.notfound);
        } else {
          setLoading(false);
          dispatcher(deletePublicData());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const PostalCodeIsValid = (postalCode) => {
  if (postalCode) {
    return Boolean(postalCode.match(/^[1-9][0-9]{3}[ ]?[a-zA-Z]{2}$/g));
  }
  return false;
};
export const HouseNumberIsValid = (houseNumber) => {
  if (houseNumber) {
    return Boolean(
      houseNumber.match(
        /^[1-9][0-9]{0,3}[^a-zA-Z0-9]?[a-zA-Z0-9]{0,4}?[a-zA-Z]?$/g
      )
    );
  }
  return false;
};

export const GetOrRequestProject = (
  projectId,
  projectLoaded,
  tempProject,
  dispatcher,
  navigate,
  setLoading,
  setProject,
  loading,
  searchParams,
  publicData
) => {
  const idIsGuid = projectId.length === 36;

  // logged in user project
  if (projectLoaded === projectId) {
    if (tempProject) {
      setProject(tempProject);
    } else {
      navigate(pages.notfound);
    }
  } else if (projectLoaded === projectId.toUpperCase() && !idIsGuid) {
    let newPathName = window.location.pathname.replace(
      projectId,
      projectLoaded
    );
    navigate(newPathName);
  } else {
    if (!loading) {
      ClearProjectSessionData();
      setLoading(true);
      RetrieveProject(
        projectId,
        idIsGuid,
        dispatcher,
        navigate,
        setLoading,
        searchParams,
        publicData
      );
    }
  }
};

export const RedirectToLoginWithReturnUrl = (navigate) => {
  if (!navigate?.pathname?.startsWith(pages.redirectSignIn)) {
    StoreInSession(
      returnUrlKey,
      window.location.pathname + window.location.search
    );
    navigate(pages.redirectSignIn);
  }
};
