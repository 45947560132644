import Burger from "./Burger";
import { ExternRevLink } from "../components/common/Buttons";
import * as links from "../externalLinks";
import * as pages from "../navigation";
import {
  faMagnifyingGlass,
  faUser,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import RevNavLink from "./RevNavLink";
import RevlogoLink from "../components/revlogoLink";

function Brand({ userData }) {
  return (
    <>
      <input
        id="navbar-burger-toggle"
        className="rev-menu-toggle-checkbox"
        type="checkbox"
        defaultChecked={false}
      />
      <div className="navbar-brand is-active">
        <RevlogoLink className="navbar-item" />
        <RevNavLink
          className={"is-hidden-desktop"}
          icon={faMagnifyingGlass}
          path={pages.all}
        />

        {userData && userData.given_name && (
          <RevNavLink
            className={"is-hidden-desktop"}
            icon={faUser}
            path={pages.user}
          />
        )}
        <ExternRevLink
          className={"is-hidden-desktop"}
          icon={faShoppingBag}
          path={links.shop}
          target={"_blank"}
          rel={"noopener"}
        />

        <Burger />
      </div>
    </>
  );
}

export default Brand;
