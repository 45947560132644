import store from "../redux/store";
import { UserManager } from "oidc-client";
import { setUserData } from "../redux/slices/userSlice";
import { loadUserProjects } from "../redux/slices/userProjectSlice";
import { clear } from "../redux/slices/generalSlice";
import {
  ClearSessionKey,
  RetrieveFromSession,
  StoreInSession,
} from "../modules/revSessionStorage";

// constants
export const returnUrlKey = "UserManager-signin-returnurl";
export const accesTokenExpiring = "UserManager-access-token-expiring";

const env = process.env.REACT_APP_ENVIRONMENT;

const client_scope = "openid profile";
const response_type = "code";
const config = {
  authority: process.env[`REACT_APP_${env}_STS_AUTHORITY`],
  client_id: process.env[`REACT_APP_${env}_CLIENT_ID`],
  silent_redirect_uri: process.env[`REACT_APP_${env}_SILENT_REDIRECT_URL`], // window.location.origin + '/silentrenew',
  redirect_uri: process.env[`REACT_APP_${env}_REDIRECT_URL`], // window.location.origin + '/signin-oidc',
  post_logout_redirect_uri: process.env[`REACT_APP_${env}_LOGOFF_REDIRECT_URL`], // window.location.origin + '/signout-callback-oidc',
  response_type: response_type,
  scope: client_scope,
};

const userManager = new UserManager(config);

userManager.events.addAccessTokenExpiring(() => {
  // store the return url to the session storage
  StoreInSession(returnUrlKey, window.location.pathname);
  StoreInSession(accesTokenExpiring, true);
  async function renewSigninAsync() {
    await userManager.signinRedirect();
  }

  renewSigninAsync()
    .then((x) => {})
    .catch((e) => {
      // in debug mode signinRedirectCallback can be fired twice (due to how the demo evironment works.)
      switch (e.message) {
        case "access_denied":
          // login canceled
          signoutRedirectCallback();
          break;
        default:
          console.error(e.message);
        //debugger;
      }
    });
});

userManager.events.addUserSignedIn(() => {});

userManager.events.addAccessTokenExpired(() => {
  // token is expired, sign out
  signoutRedirectCallback();
});

// add add user loaded event
userManager.events.addUserLoaded((user) => {
  // storage should be cleared if user logged in, but remain intact if it is a session refresh
  if (user) {
    const isFromExpiring = RetrieveFromSession(accesTokenExpiring, false);
    // clear the store

    if (!isFromExpiring) {
      store.dispatch(clear());
      ClearSessionKey(accesTokenExpiring);
    }

    // clear the return url
    ClearSessionKey(returnUrlKey);

    // then set the user data
    store.dispatch(setUserData(JSON.stringify(user)));

    // also set the user projects
    store.dispatch(loadUserProjects());
  }
});

export async function getUserAsync() {
  return await userManager.getUser();
}

export function getUser() {
  return userManager.getUser();
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export async function signinRedirectCallbackAsync() {
  return await userManager.signinRedirectCallback();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  // clear store because of logout
  store.dispatch(clear());
  return userManager.signoutRedirectCallback();
}

export default userManager;
