import CmsIndexArticle from "./cmsIndexArticle";
import Loader from "../../components/loader";
import SearchBar from "../../components/searchBar";
import RevHeader from "../../components/header";
import { ToggleDisplayLink } from "../../components/toggleDisplayButton";
import CmsIndexNewsArticle from "./news/cmsIndexNewsArticle";

const CmsIndex = ({
  filter,
  cmsType,
  index,
  loading,
  hasNextPage,
  sentryRef,
  searchOnChange,
  categoryList,
  filterListOnChange,
  toggleBoxIsClosed,
  toggleBoxOnClick,
  calculationYear,
}) => {
  const names = {
    kennisbank: "Kennisbank",
    bouwgebreken: "Bouwgebreken",
    wikivastgoed: "WikiVastgoed",
    nieuws: "Nieuws",
    all: "Doorzoek gehele website",
    kengetallen: "Kengetallen", //kengetallen
  };

  const kengetallen = "kengetallen";
  const nieuws = "nieuws";

  let displayType;
  if (cmsType === kengetallen) {
    displayType = cmsType;
  }

  if (cmsType === nieuws) {
    displayType = cmsType;
  }

  const IndexFilterCheckbox = ({ name, value, onSelect }) => {
    return (
      <label className="checkbox" style={{ width: "100%" }}>
        <input
          type="checkbox"
          onChange={onSelect}
          data-target={name}
          checked={value}
        />
        &nbsp;
        {name}
      </label>
    );
  };

  const ExpandableCategoryFilter = ({
    categoryList,
    filterListOnChange,
    cmsType,
  }) => {
    return (
      <div
        className="box is-shadowless has-background-white-bis	 is-vcentered"
        style={{
          display: "block",
          marginBottom: "2rem",
          border: "1px solid #d7d7d7",
          borderRadius: "5px",
        }}
      >
        <ToggleDisplayLink
          text={"Categorieën"}
          className={"has-text-weight-bold"}
          dataTarget={"categories"}
          isClosed={toggleBoxIsClosed}
          onClick={toggleBoxOnClick}
          type={cmsType}
        />
        <ul
          id="categories"
          className={`bd-category-list rev-menu-open ${
            toggleBoxIsClosed ? "rev-shrink-tab" : ""
          }`}
        >
          {categoryList &&
            categoryList.map((categoryFilter) => {
              return (
                <li key={categoryFilter.name}>
                  <IndexFilterCheckbox
                    name={categoryFilter.name}
                    value={categoryFilter.value}
                    onSelect={filterListOnChange}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <RevHeader
        title={names[cmsType]}
        subSubTitle={
          calculationYear ? `Prijspeil: januari ${calculationYear}` : undefined
        }
      />
      <SearchBar searchOnChange={searchOnChange} startValue={filter} />

      <section className="section">
        <div className="container">
          <div className="columns">
            {!displayType && (
              <>
                <div className={`column rev-boxes rev-boxes-left is-4`}>
                  <ExpandableCategoryFilter
                    categoryList={categoryList}
                    filterListOnChange={filterListOnChange}
                    cmsType={cmsType}
                  />
                </div>
                <div className={`column rev-boxes is-8`}>
                  {!index && <Loader center={true} />}
                  {index &&
                    index.map((article) => {
                      return (
                        <CmsIndexArticle article={article} key={article.id} />
                      );
                    })}
                  {(loading || hasNextPage) && (
                    <div ref={sentryRef}>
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            )}
            {displayType === nieuws && (
              <div
                className={`columns is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-stretch`}
              >
                {!index && <Loader center={true} />}
                {index &&
                  index.map((article, i) => {
                    return (
                      <div
                        key={i}
                        className="is-flex-grow-0"
                        style={{ width: "675px", padding: "5px" }}
                      >
                        <CmsIndexNewsArticle article={article} />
                      </div>
                    );
                  })}
                {(loading || hasNextPage) && (
                  <div ref={sentryRef}>
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CmsIndex;
