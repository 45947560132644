import * as states from "../states";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const quickStartAdapter = createEntityAdapter();
const initialState = quickStartAdapter.getInitialState({
  status: states.idle,
  error: null,
  loadedAddressData: undefined,
});

// Thunks!

export const getquickstartaddressdata = createAsyncThunk(
  "getquickstartaddressdata",
  async (payload) => {
    const response = await client.get(
      `/api/report/TryGetPublicAddressData?postalcode=${payload.postalCode}&housenumber=${payload.houseNumber}`
    );
    return JSON.stringify(response.data || "{}");
  }
);

const quicksStartDataSlice = createSlice({
  name: "quicksStartData",
  initialState,
  reducers: {
    updatePublicData: (state, action) => {
      state.loadedAddressData = action.payload.publicData;
    },
    deletePublicData: (state) => {
      state.loadedAddressData = undefined;
      state.status = states.idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getquickstartaddressdata.pending, (state, action) => {
        state.status = states.loading;
        state.error = {};
        state.loadedAddressData = undefined;
      })
      .addCase(getquickstartaddressdata.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        if (data && data !== "{}") {
          if (data.bagData) {
            data.go = data.bagData.go;
            data.goSource = data.bagData.source;
            data.bouwjaar = data.bagData.bouwjaar;
          }
          if (data.epData && data.epData.go && data.epData.go !== 0) {
            data.go = data.epData.go;
            data.goSource = data.epData.source;
          }
          state.loadedAddressData = data;
        }
        state.status = states.succeeded;
      })
      .addCase(getquickstartaddressdata.rejected, (state, action) => {
        state.status = states.failed;
        state.error = action.error.message;
      });
  },
});

export const { updatePublicData, deletePublicData } =
  quicksStartDataSlice.actions;

export default quicksStartDataSlice.reducer;

export const selectQuickStartAddressLoadStatus = (state) => {
  return state.quicksStartData.status;
};

export const selectPublicAddressData = (state) =>
  state.quicksStartData.loadedAddressData;
