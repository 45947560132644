import * as pages from "../navigation";

const RevlogoLink = ({ className, style }) => {
  return (
    <a className={className} style={style} href={pages.home}>
      <img
        src="images/rev-logo.png"
        title="Home"
        alt="rev logo"
        className="is-hidden-touch"
      />
      <img
        src="images/rev-logo.png"
        className="is-hidden-desktop"
        title="Home"
        alt="rev logo"
        style={{ width: "45px", height: "45px" }}
      />
    </a>
  );
};

export default RevlogoLink;
