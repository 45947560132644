export const isNew = "isNew";
export const reference = "reference";

// 1 = eigenaar, 2 = user, 3 = both
export const financialOwner = "financialOwner";
export const id = "id";

// rename?
export const bvo = "bvo";
export const go = "go";
export const inhoud = "inhoud";
export const hoogte = "hoogte";

export const createDate = "createDate";
export const bouwjaar = "bouwjaar";
export const name = "name";
export const inspector = "inspector";

export const complexName = "complexName";
export const image = "image";
export const year = "year";
export const commercialProperty = "commercialProperty";
export const potentialCommercialAdjustment = "potentialCommercialAdjustment";
export const includeVat = "includeVat";
export const cycle = "cycle";
export const addressLine1 = "addressLine1";
export const addressLine2 = "addressLine2";
export const houseNumber = "houseNumber";
export const postalCode = "postalCode";
export const city = "city";
export const countryCode = "countryCode";
export const municipalityId = "municipalityId";
export const featureText = "featureText";
export const renovationParts = "renovationParts";
export const faults = "faults";
export const repportRequired = "repportRequired";
export const anonymousAccess = "anonymousAccess";

export const herbouwWaardeBerekening = "herbouwWaardeBerekening";

export const energyLabel = "energyLabel";

export const huursom = "huursom";
export const koopsom = "koopsom";
export const wozwaarde = "wozwaarde";
export const herbouwKosten = "herbouwKosten";
export const opstalVerzekering = "opstalVerzekering";
export const overdrachtsBelasting = "overdrachtsBelasting";
export const beheerKostenHuursom = "beheerKostenHuursom";

export const makelaarsKosten = "makelaarsKosten";
export const notarisKosten = "notarisKosten";
export const taxatieKosten = "taxatieKosten";
export const extraEenmaligePost1 = "extraEenmaligePost1";
export const extraEenmaligePost2 = "extraEenmaligePost2";

export const serviceKosten = "serviceKosten";
export const gasVerbruik = "gasVerbruik";
export const elektraVerbruik = "elektraVerbruik";
export const waterVerbruik = "waterVerbruik";
export const waterschapBelasting = "waterschapBelasting";
export const riool = "riool";
export const extraJaarlijksePost1 = "extraJaarlijksePost1";
export const extraJaarlijksePost2 = "extraJaarlijksePost2";

export const ozbRate = "ozbRate";
export const rioolRate = "rioolRate";
export const waterRate = "waterRate";
export const isHousing = "isHousing";

export const publicAddressData = "publicAddressData";
export const hasLatestPublicData = "hasLatestPublicData";

export const colorSchemes = {
  1: "rev-highlight-woningen",
  2: "rev-highlight-kantoren",
  3: "rev-highlight-bedrijfsgebouwen",
  4: "rev-highlight-winkels",
  5: "rev-highlight-argrarish",
  6: "rev-highlight-overig",
  7: "rev-highlight-garages",
  8: "rev-highlight-woningen",
  9: "rev-highlight-mav-2", //deel 2 zorggebouwen - ZW, DV, MC, WZ, VH, ZH
  10: "rev-highlight-mav-3", //deel 3 schoolgebouwen - PO, VO, SO, PRO, HO, GZ
  11: "rev-highlight-overig",
  12: "rev-highlight-herbestemming",
  13: "rev-highlight-mav-1", //deel 1 gemeentelijk vastgoed - GK, CG, BG, GB, GP
};
