export const RevTooltipSimple = ({ label, tooltipText }) => {
  const onClick = (e) => {
    const tooltipContainer = e.target.closest(".rev-tooltip ");
    let child = tooltipContainer.lastChild;
    if (child.classList.contains("rev-tooltip-mobile-show")) {
      child.classList.remove("rev-tooltip-mobile-show");
    } else {
      child.classList.add("rev-tooltip-mobile-show");
    }
  };

  return (
    <span
      className="rev-tooltip rev-information rev-information-small rev-information-circle "
      onClick={onClick}
    >
      {label}
      <span className="rev-tooltiptext">{tooltipText}</span>
    </span>
  );
};
