import { AnchorButton, RevPageLink } from "../../../components/common/Buttons";
import { FloatingButtonWrapper } from "../../../components/common/FormButton";
import * as pages from "../../../navigation";
import { useNavigate } from "react-router";

export const QuickStartFloatingBackButton = () => {
  const navigate = useNavigate();
  const onClickPrevious = () => {
    navigate(-1);
  };
  return (
    <FloatingButtonWrapper>
      <AnchorButton
        onClick={onClickPrevious}
        text="Vorige"
        className="button has-background-warning has-text-white"
      />
      &nbsp;
      <RevPageLink
        path={pages.newproject}
        text={"Overzicht referentiegebouwen"}
        className={"button"}
      />
    </FloatingButtonWrapper>
  );
};

const QuickStartNavigationButtons = ({
  path,
  nextText = "Volgende",
  chooseText = "Overzicht Referentiegebouwen",
  showChoose = true,
  isActive,
  PrefixChild,
  bagUrl,
}) => {
  const navigate = useNavigate();
  const onClickPrevious = () => {
    navigate(-1);
  };

  const ButtonSelection = ({ showChoose, isActive, PrefixChild }) => {
    return (
      <div className="rev-mb-small">
        <AnchorButton
          onClick={onClickPrevious}
          text="Vorige"
          className="button has-background-warning has-text-white"
        />
        &nbsp;
        {PrefixChild && (
          <>
            <PrefixChild className="button is-success" />
            &nbsp;
          </>
        )}
        {!PrefixChild && (
          <>
            <RevPageLink
              path={path}
              text={nextText}
              isActive={isActive}
              state={path}
              className={"button is-success"}
            />
            &nbsp;
          </>
        )}
        {showChoose && (
          <>
            <RevPageLink
              path={pages.newproject}
              text={chooseText}
              className={"button is-success"}
            />
            &nbsp;
          </>
        )}
        {bagUrl && (
          <>
            <AnchorButton
              href={bagUrl}
              text={"Bekijk op Bag"}
              className={"button is-primary"}
              target="_blank"
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="is-hidden-touch">
        <ButtonSelection
          showChoose={showChoose}
          isActive={isActive}
          PrefixChild={PrefixChild}
        />
        <div style={{ height: "100px", width: "100%" }}>&nbsp;</div>
      </div>
      <div className="is-hidden-desktop">
        <div style={{ height: "200px", width: "100%" }}>&nbsp;</div>
        <FloatingButtonWrapper>
          <ButtonSelection
            showChoose={showChoose}
            isActive={isActive}
            PrefixChild={PrefixChild}
          />
        </FloatingButtonWrapper>
      </div>
    </>
  );
};

export default QuickStartNavigationButtons;
