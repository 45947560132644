import { Link } from "react-router-dom";
import * as pages from "../../navigation";

const ProjectNavigation = ({
  id,
  highlight,
  showReporting,
  showPlan,
  userIsAuthorized,
}) => {
  const projectType = userIsAuthorized ? pages.projectPage : pages.newproject;
  return (
    <section
      className="section is-small has-background-white-ter"
      style={{ paddingTop: "1em", paddingBottom: "1em" }}
    >
      <div className="buttons is-centered">
        {userIsAuthorized && (
          <div>
            <Link
              className={`button rev-project-button has-text-weight-bold has-text-white-bis rev-highlight-back`}
              to={`${pages.userProjectsPage}`}
            >
              Projecten
            </Link>
          </div>
        )}
        <div id="rev-kenmerken-btn">
          <Link
            className={`button rev-project-button has-text-weight-bold has-text-white-bis rev-highlight-kenmerken ${
              highlight === pages.projectKenmerken ? "is-active" : ""
            }`}
            to={`${projectType}/${id}${pages.projectKenmerken}`}
          >
            Kenmerken
          </Link>
        </div>
        <div id="rev-onderhoudkosten-btn">
          <Link
            className={`button rev-project-button has-text-weight-bold has-text-white-bis rev-highlight-onderhoudskosten ${
              highlight === pages.projectOnderhoudskosten ? "is-active" : ""
            }`}
            to={`${projectType}/${id}${pages.projectOnderhoudskosten}`}
          >
            Exploitatiekosten
          </Link>
        </div>
        {showPlan && (
          <div id="rev-mjop-btn">
            <Link
              className={`button rev-project-button has-text-weight-bold has-text-white-bis rev-highlight-mjop ${
                highlight === pages.projectPlan ? "is-active" : ""
              }`}
              to={`${projectType}/${id}${pages.projectPlan}`}
            >
              MJOP
            </Link>
          </div>
        )}
        {showReporting && (
          <div id="rev-rapportage-btn">
            <Link
              className={`button rev-project-button has-text-weight-bold has-text-white-bis rev-highlight-rapportage ${
                highlight === pages.projectRapportage ? "is-active" : ""
              }`}
              to={`${projectType}/${id}${pages.projectRapportage}`}
            >
              Rapport
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProjectNavigation;
