import PropTypes from "prop-types";

import { RevTooltipSimple } from "./revTooltip";

const nextOnEnter = (e) => {
  if (e.key === "Enter") {
    const inputs = Array.from(document.querySelectorAll("input,select"));
    let index = inputs.indexOf(e.target) + 1;
    if (index === inputs.length) {
      index = 0;
    }
    inputs[index].focus();
  }
};

export const RadioButtonGroup = ({ buttons, name, className, onChange }) => {
  const classes = className ? className : "control";

  return (
    <div className={classes}>
      {buttons.map((button) => {
        return (
          <label className="radio" key={button.value}>
            <RadioButton
              text={button.text}
              name={name}
              value={button.value}
              defaultChecked={button.checked}
              onChange={onChange}
            />
          </label>
        );
      })}
    </div>
  );
};

const RadioButton = ({
  text,
  name,
  value,
  defaultChecked,
  onChange,
  onBlur,
}) => {
  return (
    <>
      <input
        type="radio"
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={nextOnEnter}
      />
      &nbsp;
      {text}
    </>
  );
};

export const RadioButtonGroupProject = ({
  buttons,
  name,
  label,
  className,
  onChange,
  simpleTooltipText,
  onBlur,
}) => {
  const classes = className ? className : "control";

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">
          {label}{" "}
          {simpleTooltipText && (
            <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
          )}
        </label>
      </div>
      <div className="field-body">
        <div className="field has-addons rev-project-input is-normal">
          <div className={classes}>
            {buttons.map((button) => {
              return (
                <label className="radio rev-project-radio" key={button.value}>
                  <RadioButton
                    text={button.text}
                    name={name}
                    value={button.value}
                    defaultChecked={button.checked}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

RadioButtonGroupProject.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  buttons: PropTypes.array.isRequired,
  className: PropTypes.string,
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  buttons: PropTypes.array.isRequired,
  className: PropTypes.string,
};

RadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
