import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  selectErrorMessages,
  selectLastProjectRequested,
  selectLoadedProject,
  selectUserProjectsLoadedStatus,
} from "../../redux/slices/userProjectSlice";
import {
  GetOrRequestProject,
  RedirectToLoginWithReturnUrl,
} from "./projectFunctions";

import * as states from "../../redux/states";
import * as pages from "../../navigation";
import * as events from "../../custom-scripts/custom-javascript-events";
import {
  loadMunicipalitydata,
  selectMunicipalityStatus,
} from "../../redux/slices/masterDataSlice";
import Loader from "../../components/loader";
import ProjectNavigation from "./ProjectNavigation";
import ProjectSaveButton from "./ProjectSaveButton";
import {
  selectIsProjectAuthorized,
  userHasBasicSubscription,
  userHasProSubscription,
} from "../../redux/slices/userSlice";
import { selectPublicAddressData } from "../../redux/slices/quickStartSlice";

const ProjectComponentWrapper = (props) => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});
  const [errors, setErrors] = useState();
  let tempProject = useSelector(selectLoadedProject);
  const projectLoaded = useSelector(selectLastProjectRequested);
  const projectLoadState = useSelector(selectUserProjectsLoadedStatus);
  const municipalityStatus = useSelector(selectMunicipalityStatus);
  const tempErrors = useSelector(selectErrorMessages);
  const publicData = useSelector(selectPublicAddressData);
  const location = useLocation();

  const paths = location.pathname.split("/");
  const currentPath = `/${paths[paths.length - 1]}`;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (projectLoadState === states.unauthorized)
      RedirectToLoginWithReturnUrl(navigate);
  }, [projectLoadState, navigate]);

  useEffect(() => {
    if (municipalityStatus === states.idle) {
      if (!loading) {
        setLoading(true);
      }
      dispatcher(loadMunicipalitydata()).then(() => {
        setLoading(false);
      });
    } else {
      GetOrRequestProject(
        id,
        projectLoaded,
        tempProject,
        dispatcher,
        navigate,
        setLoading,
        setProject,
        loading,
        searchParams,
        publicData
      );
    }
    if (errors !== tempErrors) {
      setErrors(tempErrors);
    }
  }, [
    projectLoaded,
    id,
    loading,
    dispatcher,
    navigate,
    tempProject,
    searchParams,
    publicData,
    municipalityStatus,
    errors,
    tempErrors,
  ]);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        id,
        project,
        setProject,
        loading,
        setLoading,
        dispatcher,
        errors,
        setErrors,
      });
    }
    return child;
  });

  const userIsProSubscription = useSelector(userHasProSubscription);
  const userIsBasicSubscription = useSelector(userHasBasicSubscription);
  const userIsAuthorized = useSelector(selectIsProjectAuthorized);
  const emptyProject = Object.keys(project).length === 0;

  useEffect(() => {
    if (currentPath !== pages.projectOnderhoudskosten) {
      const customEvent = new Event(events.eventToggleBoxOnclick);
      document.dispatchEvent(customEvent);
    }
  });

  return (
    <>
      {(loading || emptyProject) && <Loader center={true} />}
      {!loading && !emptyProject && (
        <>
          <ProjectNavigation
            id={id}
            highlight={currentPath}
            showPlan={project?.anonymousAccess || userIsProSubscription}
            showReporting={userIsBasicSubscription}
            userIsAuthorized={userIsAuthorized}
          />
          {childrenWithProps}
          <ProjectSaveButton project={project} errors={errors} />
        </>
      )}
    </>
  );
};

//https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children

export default ProjectComponentWrapper;
