import * as links from "../externalLinks";

const Helaas = ({
  title = "Helaas, u heeft een abonnement nodig om alle informatie in te zien.",
  content = "Om toegang te krijgen tot deze inhoud heeft u een abonnement nodig op een van onze Vastgoed Taxatiewijzers.",
}) => {
  return (
    <div className="container has-background-danger-light p-2">
      <h3 className="subtitle is-size-6-mobile has-text-weight-bold has-text-danger is-5">
        {title}
      </h3>
      <div className="content ">{content}</div>
      <a
        className="button is-danger"
        href={links.shopAbonnement}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Meer info op VAOshop.nl
      </a>
    </div>
  );
};

export default Helaas;
