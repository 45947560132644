import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loader from "../../components/loader";
import {
  fetchCmsForPath,
  selectCmsByPath,
} from "../../redux/slices/cmsSearchDataSlice";
import GeneralCmsPage from "./generalCmsPage";
import RevHelmet from "../../components/RevHelmet";

const GeneralCmsPageManager = ({ dispatcher }) => {
  const location = useLocation();
  const pathname = "/general" + location.pathname;

  const result = useSelector((state) => {
    return selectCmsByPath(state, pathname);
  });
  useEffect(() => {
    if (!result) {
      dispatcher(fetchCmsForPath(pathname));
    }
  }, [dispatcher, pathname, result]);

  return (
    <>
      {(!result || !result.article) && <Loader center={true} />}
      {result && result.article && (
        <>
          <RevHelmet data={result.article} />
          <GeneralCmsPage article={result.article} />
        </>
      )}
    </>
  );
};

export default GeneralCmsPageManager;
