function PageNotFound(props) {
  const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";
  return (
    <div>
      <small>Pagina niet gevonden</small>
      {!isProd && (
        <>
          <br />
          <small>pathname: {window.location.pathname}</small>
        </>
      )}
    </div>
  );
}

export default PageNotFound;
