import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as states from "../../../redux/states";
import * as pages from "../../../navigation";
import {
  selectBeheerObjecten,
  selectObjectSoorten,
  selectVtwLoadStatus,
  loadVtwData,
} from "../../../redux/slices/vtwSlice";
import {
  selectObjectExploitationData,
  getProjectExploitationData,
  deleteNewProject,
} from "../../../redux/slices/userProjectSlice";
import NewProjectPage from "./NewProjectPage";
import { selectIsAuthorized } from "../../../redux/slices/userSlice";
import { StoreVatInSession } from "../../../modules/revSessionStorage";
import { selectCalculationYear } from "../../../redux/slices/cmsNavMenuSlice";
import { deletePublicData } from "../../../redux/slices/quickStartSlice";
function NewProjectPageManager() {
  // use state?
  const [exploitationVtw, setExploitationVtw] = useState("");
  const [exploitationDataLoading, setExploitationDataLoading] = useState();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const objectSoorten = useSelector(selectObjectSoorten);
  const calculationYear = useSelector(selectCalculationYear);

  const beheerObjecten = useSelector(selectBeheerObjecten);

  const currentState = useSelector(selectVtwLoadStatus);

  const updateExploitationVtw = (vtwCode) => {
    setExploitationVtw(vtwCode);
  };

  const isAuthorized = useSelector(selectIsAuthorized);
  const exploitationData = useSelector((state) =>
    selectObjectExploitationData(state, exploitationVtw)
  );

  useEffect(() => {
    if (currentState === states.idle || currentState === states.failedonce) {
      dispatcher(loadVtwData());
    } else {
      dispatcher(deleteNewProject());
    }
    if (exploitationData === undefined) {
      //  currentExploitationVtwCode !== exploitationVtw) {
      setExploitationDataLoading(true);
      dispatcher(getProjectExploitationData({ vtwCode: exploitationVtw })).then(
        () => {
          setExploitationDataLoading(false);
        }
      );
    }
  }, [dispatcher, navigate, currentState, exploitationVtw, exploitationData]);

  if (currentState === states.failed) {
    // failed twice, send to logout
    navigate(pages.signOut);
  }

  function createProject(event) {
    dispatcher(deletePublicData());
    StoreVatInSession(event.target.includeVat.checked);
    event.preventDefault();
    navigate(
      `${pages.newproject}/${event.target.vtwCode.value}${pages.projectKenmerken}`
    );
  }

  return (
    <NewProjectPage
      updateExploitationVtw={updateExploitationVtw}
      exploitationData={exploitationData}
      exploitationDataLoading={exploitationDataLoading}
      beheerObjecten={beheerObjecten}
      objectSoorten={objectSoorten}
      state={currentState}
      createProject={createProject}
      isAuthorized={isAuthorized}
      calculationYear={calculationYear}
    />
  );
}

export default NewProjectPageManager;
