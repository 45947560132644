import { ExternRevLink } from "../components/common/Buttons";
import Brand from "./Brand";
import UserLogin from "../components/UserLogin";
import * as pages from "../navigation";
import * as links from "../externalLinks";
import { LessonNavBarSubjectLink } from "./LessonSubjectLink";
import {
  faMagnifyingGlass,
  faUser,
  faShoppingBag,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import RevNavLink from "./RevNavLink";

function NavMenu(props) {
  return (
    <>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <Brand userData={props.userData} />

          <div id="revtogglemenu" className="navbar-menu">
            <div className="navbar-start">
              {/* kennis bank menu */}
              {props.cmsMenuItems.length !== 0 && (
                <>
                  <input
                    className="rev-menu-toggle-checkbox"
                    id="headCategories"
                    type="checkbox"
                    defaultChecked={false}
                  />
                  <div
                    className="navbar-item has-dropdown is-hoverable"
                    data-target="headCategories"
                    htmlFor="headCategories"
                  >
                    {/* eslint-disable-next-line  */}
                    <label
                      className="navbar-link is-hidden-desktop"
                      htmlFor="headCategories"
                    >
                      Kennisbank
                    </label>
                    <RevNavLink
                      icon={faChevronDown}
                      text={"Kennisbank"}
                      path={pages.kennisbank}
                      className={"is-hidden-touch"}
                    />

                    <div
                      id="headCategories"
                      className="navbar-dropdown rev-menu-open-mobile"
                    >
                      {props.cmsMenuItems.map((content, index) => {
                        return (
                          <LessonNavBarSubjectLink
                            key={index}
                            article={content}
                            path={pages.lesson}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              <RevNavLink text={"Kengetallen"} path={pages.kengetallen} />
              <input
                className="rev-menu-toggle-checkbox"
                id="bouwgebreken-dropdown"
                type="checkbox"
                defaultChecked={false}
              />

              <div
                className="navbar-item has-dropdown is-hoverable"
                data-target="bouwgebreken-dropdown"
                htmlFor="bouwgebreken-dropdown"
              >
                {/* eslint-disable-next-line  */}
                <label
                  className="navbar-link is-hidden-desktop"
                  htmlFor="bouwgebreken-dropdown"
                >
                  Bouwgebreken
                </label>
                <RevNavLink
                  icon={faChevronDown}
                  text={"Bouwgebreken"}
                  path={pages.bouwgebreken}
                  className={"is-hidden-touch"}
                />

                <div id="bouwgebreken-dropdown" className={`navbar-dropdown`}>
                  <RevNavLink
                    text={"Overzicht"}
                    path={pages.bouwgebreken}
                    className="is-hidden-desktop"
                  />
                  <RevNavLink
                    text={"Bouwkundig"}
                    path={pages.bouwgebrekenbouwkundig}
                  />
                  <RevNavLink
                    text={"Installaties"}
                    path={pages.bouwgebrekeninstallaties}
                  />
                </div>
              </div>
            </div>

            <div className="navbar-end">
              <RevNavLink
                className={"is-hidden-touch"}
                icon={faMagnifyingGlass}
                path={pages.all}
              />

              {props.userData && props.userData.given_name && (
                <RevNavLink
                  className={"is-hidden-touch"}
                  icon={faUser}
                  path={pages.user}
                />
              )}
              <ExternRevLink
                className={"is-hidden-touch"}
                icon={faShoppingBag}
                path={links.shop}
                target={"_blank"}
                rel={"noopener"}
              />
              <div className="navbar-item">
                <div className="buttons">
                  <RevNavLink
                    className={`button is-primary ${
                      props.userData ? "" : "is-light"
                    }`}
                    path={pages.userProjectsPage}
                    text="Projecten"
                  />

                  <UserLogin
                    userData={props.userData}
                    userDataState={props.userDataState}
                    login={props.login}
                    logout={props.logout}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavMenu;
