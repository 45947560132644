// source
// https://techjugaar.com/js-format-date-yyyy-mm-dd/

export function formatDateWithLocale(timestamp, config = {}, locale = "nl-NL") {
  let { format = "DD MM YYYY" } = config;
  if (!timestamp) return "No Date specified";
  const date = new Date(timestamp);
  const _date = date.toLocaleString(locale, { day: "numeric" });
  const _month = date.toLocaleString(locale, { month: "long" });
  const _fullYear = date.toLocaleString(locale, { year: "numeric" });
  format = format.replace("DD", _date);
  format = format.replace("MM", _month);
  format = format.replace("YYYY", _fullYear);
  return format;
}

export function formatDate(timestamp, config = {}) {
  let { format = "YYYY/MM/DD" } = config;
  if (!timestamp) return "No Date specified";
  const date = new Date(timestamp);
  format = format.replace("DD", date.getDate());
  format = format.replace("MM", date.getMonth() + 1);
  format = format.replace("YYYY", date.getFullYear());
  return format;
}
