import { useEffect, useState } from "react";
import { client } from "../../../api/client";

export const OzbEnRiool = () => {
  const [ozbData, setOzbData] = useState();

  const openClass = "rev-toggle-open";
  const closeClass = "rev-toggle-close";

  useEffect(() => {
    const fetchData = async () => {
      await client
        .get("api/cmscontent/GetOzbEnRiool")
        .then((d) => {
          setOzbData(d.data);
        })
        .catch((e) => console.error(e));
    };
    fetchData();
  }, []);

  // javascript function added to maintain
  const toggleBox = (e) => {
    const row = document.getElementById(`${e?.target?.dataset?.target}`);
    if (row) {
      row.classList.toggle(openClass);
      row.classList.toggle(closeClass);
    }
  };

  const width = "70px";

  const TH = (props) => {
    return (
      <td
        style={{
          maxWidth: width,
        }}
      >
        {props.children}
      </td>
    );
  };

  return (
    <div className="table-container" style={{ width: "fitContent" }}>
      <table className="rev-css-toggle-box" style={{ overflow: "hidden" }}>
        {ozbData &&
          ozbData.provincies.map((d) => {
            return (
              <tbody key={d.name}>
                <tr
                  className={closeClass}
                  id={`row-${d.name}`}
                  onClick={toggleBox}
                  data-target={`row-${d.name}`}
                >
                  <td
                    colSpan={ozbData.rates.length + 2}
                    data-target={`row-${d.name}`}
                  >
                    <label data-target={`row-${d.name}`}>{d.name}</label>
                  </td>
                </tr>

                <tr key={`${d.name}_headers`} className="rev-toggle-header">
                  <TH />
                  {ozbData &&
                    ozbData.rates.map((x) => (
                      <TH key={x.id}>
                        <div title={x.name} style={{}}>
                          {x.name}
                        </div>
                      </TH>
                    ))}
                </tr>

                {d.gemeentes.map((g, i) => {
                  return (
                    <tr key={`${d.name}_${g.name}`} className="rev-toggle-row">
                      <td>{g.name}</td>
                      {g.rates.map((r) => (
                        <td
                          className="has-text-right"
                          key={`${d.name}_${g.name}_${r.id}`}
                          width={width}
                          style={{
                            maxWidth: width,
                            fontSize: "16px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          <div title={r.name}>{r.rate}</div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
      </table>
    </div>
  );
};
