import { DisplayLabel } from "../../components/common/DisplayLabel";
import { DoubleFormButton } from "../../components/common/FormButton";
import Loader from "../../components/loader";
const ProjectRapportage = ({
  downloadWord,
  downloadPdf,
  downloadFailed,
  downloadMessage,
  canSave,
  project,
  isWorking,
}) => {
  return (
    <section className="is-small pb-1">
      <div className="container rev-project-box-child-margin">
        <DisplayLabel
          label={"Vtw code referentie"}
          text={project.reference.searchVtwCode}
          className="rev-is-borderless"
          isCompact={true}
        />
        <DisplayLabel
          label={"Object type"}
          text={project.reference.objectType}
          className="rev-is-borderless"
          isCompact={true}
        />
        <DisplayLabel
          label={"Aanmaak datum"}
          text={project.createDate}
          className="rev-is-borderless"
          isCompact={true}
        />
      </div>
      <div className="container has-text-centered rev-project-box-child-margin">
        {isWorking && <Loader center={true} />}
        {!isWorking && (
          <>
            <DoubleFormButton
              primaryOnClick={downloadWord}
              primaryText="word"
              primaryDisabled={canSave}
              secondaryOnClick={downloadPdf}
              secondaryText="pdf"
              secondaryDisabled={canSave}
              showWarning={canSave}
              warningText={"Sla het project eerst op."}
            />
          </>
        )}
        {downloadFailed && (
          <DisplayLabel label={"Fout melding"} text={downloadMessage} />
        )}
      </div>
    </section>
  );
};

export default ProjectRapportage;
