import HeaderSlider from "./headerSlider";
import HomeFeatures from "./features";
import HomeReview from "./review";
import HomeDetails from "./details";
import HomeEnvironment from "./environment";
import { selectIsAuthorized } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import HomeTitleAuthorized from "./titleAuthorized";
import VastgoedTaxatiewijzer from "./vastgoedTaxatiewijzer";
function Home(props) {
  const isAuthorized = useSelector(selectIsAuthorized);

  return (
    <>
      <HomeEnvironment />
      {isAuthorized && <HomeTitleAuthorized />}
      {!isAuthorized && <HeaderSlider />}
      <HomeFeatures />
      <HomeReview />
      <HomeDetails />
      <VastgoedTaxatiewijzer />
    </>
  );
}

export default Home;
