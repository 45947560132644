import * as events from "./custom-javascript-events";
import * as classes from "./custom-javascript-classes";
import * as pages from "../navigation";

const bulmaIsHidden = "is-hidden";

export function addEventListersAndMobileSupport() {
  const revToggleBoxCaptureEvent = (e) => {
    var tabWithTarget = e.target.closest(`.${classes.toggleBox}`);
    if (tabWithTarget) {
      let box = document.querySelector(`#${tabWithTarget.dataset.target}`);
      box.style.maxHeight = `${box.scrollHeight}px`;

      new Promise((res) => setTimeout(res, 1)).then((x) => {
        if (box.classList.contains(classes.shrinkTab)) {
          box.classList.remove(classes.shrinkTab);
        } else {
          box.classList.add(classes.shrinkTab);
        }

        let carrets = tabWithTarget.getElementsByClassName(classes.carret);
        Array.prototype.forEach.call(carrets, (carret) => {
          if (carret.classList.contains(bulmaIsHidden)) {
            carret.classList.remove(bulmaIsHidden);
          } else {
            carret.classList.add(bulmaIsHidden);
          }
        });
      });
    }
  };

  document.addEventListener(events.eventPreventCopyAmounts, (e) => {
    const replaceCopyText = (e) => {
      const paths = [pages.kengetallen];
      if (
        paths.find((x) => window.location.pathname.toLowerCase().startsWith(x))
      ) {
        e.clipboardData.setData(
          "text/plain",
          "Wilt u de kengetallen digitaal ontvangen? Neem contact op met info@evao.nl."
        );
        e.preventDefault();
      } else {
        document.removeEventListener("copy", replaceCopyText);
      }
    };

    document.removeEventListener("copy", replaceCopyText);
    document.addEventListener("copy", replaceCopyText);
  });

  // menu load event
  document.addEventListener(events.eventToggleBoxOnclick, (e) => {
    // Get all "navbar-burger" elements

    const $toggleBoxes = Array.prototype.slice.call(
      document.querySelectorAll(`.${classes.toggleBox}`),
      0
    );

    if ($toggleBoxes.length > 0) {
      // Add a click event on each of them
      $toggleBoxes.forEach((el) => {
        el.removeEventListener("click", revToggleBoxCaptureEvent, true);
        el.addEventListener("click", revToggleBoxCaptureEvent, true);
      });
    }
  });
}
