import * as classes from "../../custom-scripts/custom-javascript-classes";
import RevHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMagnifyingGlass,
  faKitMedical,
  faHand,
  faEuroSign,
  faFileCircleQuestion,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { DangerousDiv } from "../../components/dangerousGerneric";
import Helaas from "../../components/aboNodig";

const ArticleTabbed = ({ article, isAuthorized }) => {
  // sort is for the order of the tabs to appear on the page
  const iconLookup = {
    "plaats van het gebrek": { icon: faLocationDot, sort: 1 },
    kenmerken: { icon: faMagnifyingGlass, sort: 3 },
    oorzaken: { icon: faFileCircleQuestion, sort: 5 },
    herstel: { icon: faKitMedical, sort: 7 },
    preventie: { icon: faHand, sort: 9 },
    herstelkosten: { icon: faEuroSign, sort: 11 },
    werkwijze: { icon: faHammer, sort: 13 },
    kosten: { icon: faEuroSign, sort: 15 },
  };

  //let tabOrder = ["plaats van het gebrek", "kenmerken", "oorzaken","herstel", "werkwijze", "preventie" , "herstelkosten", "kosten"];

  const TabForPane = ({ content }) => {
    const contentName = content.name;
    let icon = null;
    if (iconLookup.hasOwnProperty(contentName.toLowerCase())) {
      icon = iconLookup[contentName.toLowerCase()].icon;
    }
    return (
      <li>
        <label htmlFor={contentName.toLowerCase()}>
          {/* eslint-disable-next-line  */}
          <a>
            <span className="icon is-small">
              <FontAwesomeIcon icon={icon} />
            </span>
            {contentName}
          </a>
        </label>
      </li>
    );
  };

  const PaneForTab = ({ id, article, active, content }) => {
    const contentName = content.name;
    let icon = null;
    if (iconLookup.hasOwnProperty(contentName.toLowerCase())) {
      icon = iconLookup[contentName.toLowerCase()].icon;
    }

    return (
      <>
        <div className={`${classes.tabbedNavigation} is-hidden-desktop`}>
          <div className="tabs is-toggle is-centered is-fullwidth mb-0">
            <ul>
              <TabForPane content={content} />
            </ul>
          </div>
        </div>

        <div
          id={`content-${contentName.toLowerCase()}`}
          className={`content ${classes.toggleTab}`}
        >
          <div className="rev-full-width">
            <h2 className="is-size-4 has-text-weight-bold is-hidden-touch">
              <FontAwesomeIcon icon={icon} className="has-text-primary" />{" "}
              &nbsp;
              {contentName}
            </h2>
            {article.contentRequiresAccount && <Helaas />}

            {!article.contentRequiresAccount && (
              <DangerousDiv content={content.content} />
            )}
          </div>
        </div>
      </>
    );
  };

  // here the tabs get sorted by the order defined in the iconlookup
  const sortedContent = [...article.contents].sort((a, b) => {
    const aname = a.name.toLowerCase();
    const bname = b.name.toLowerCase();
    const aint = iconLookup[aname] ? iconLookup[aname].sort : 99;
    const bint = iconLookup[bname] ? iconLookup[bname].sort : 99;
    return aint > bint ? 1 : bint > aint ? -1 : 0;
  });

  return (
    <>
      <RevHeader isAuthorized={isAuthorized} {...article} />
      <input
        type="radio"
        id="plaats van het gebrek"
        name="rev-tabbedpage-radiobutton"
        defaultChecked={true}
      />
      <input type="radio" id="kenmerken" name="rev-tabbedpage-radiobutton" />
      <input type="radio" id="oorzaken" name="rev-tabbedpage-radiobutton" />
      <input type="radio" id="herstel" name="rev-tabbedpage-radiobutton" />
      <input type="radio" id="preventie" name="rev-tabbedpage-radiobutton" />
      <input
        type="radio"
        id="herstelkosten"
        name="rev-tabbedpage-radiobutton"
      />
      <input type="radio" id="werkwijze" name="rev-tabbedpage-radiobutton" />
      <input type="radio" id="kosten" name="rev-tabbedpage-radiobutton" />
      <div className={`${classes.tabbedNavigation} is-hidden-touch`}>
        <div className="tabs is-toggle is-centered is-fullwidth mb-0">
          <div className="container has-background-link-light">
            <ul>
              {sortedContent.map((content, i) => (
                <TabForPane
                  key={`tabpane${i}`}
                  active={i === 0}
                  id={i}
                  content={content}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <section className="hero is-small">
        <div className="hero-body" style={{ alignItems: "start" }}>
          <div className="container">
            {sortedContent.map((content, i) => (
              <PaneForTab
                key={`PaneForTab${i}`}
                active={i === 0}
                id={i}
                article={article}
                content={content}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticleTabbed;
