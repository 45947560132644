import CmsIndexManager from "./cmsIndexManager";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as states from "../../redux/states";
import Loader from "../../components/loader";

import {
  fetchCmsContentFilters,
  selectCmsContentCategoriesState,
} from "../../redux/slices/cmsTypeContentIndexSlice";

const CmsContentIndexPage = ({ dispatcher }) => {
  const state = useSelector(selectCmsContentCategoriesState);

  const ready = state === states.completed;
  useEffect(() => {
    if (state === states.idle) {
      dispatcher(fetchCmsContentFilters());
    }
  }, [state, dispatcher]);

  return (
    <>
      {!ready && <Loader center={true} />}
      {ready && <CmsIndexManager dispatcher={dispatcher} />}
    </>
  );
};

export default CmsContentIndexPage;
