export const DangerousDiv = ({ content, className }) => {
  return (
    <>
      {content && (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
      {!content && <div className={className} />}
    </>
  );
};
