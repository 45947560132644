import * as states from "../states";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const seoPageDataAdapter = createEntityAdapter();

const initialState = seoPageDataAdapter.getInitialState({
  status: states.idle,
  error: null,
});

// Thunks!
export const loadSeoMetaData = createAsyncThunk("loadSeoMetaData", async () => {
  return fetch("seodata.json").then(function (response) {
    return response.text();
  });
});

const seoMetaDataSlice = createSlice({
  name: "seoMetaData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadSeoMetaData.pending, (state, action) => {
        state.status = states.loading;
      })
      .addCase(loadSeoMetaData.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload);
        seoPageDataAdapter.addMany(state, data);
        state.status = states.succeeded;
      })
      .addCase(loadSeoMetaData.rejected, (state, action) => {
        state.status = states.failed;
        state.Error = action.error.message;
      });
  },
});

export const { metaDataExists } = seoMetaDataSlice.actions;

export default seoMetaDataSlice.reducer;

export const selectSeoMetaDataStatus = (state) => state.seoMetaData.status;

export const { selectById: selectSeoMetaDataForPath } =
  seoPageDataAdapter.getSelectors((state) => state.seoMetaData);
