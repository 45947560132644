const lowestLabel = "G";

const labelData = [
  {
    label: "A++++",
    van: -999999,
    tot: 0,
    gemmiddeld: 0,
    verschil: 0,
  },
  {
    label: "A+++",
    van: 0,
    tot: 50,
    gemmiddeld: 25,
    verschil: 50,
  },
  {
    label: "A++",
    van: 50.01,
    tot: 75,
    gemmiddeld: 62.505,
    verschil: 25,
  },
  {
    label: "A+",
    van: 75.01,
    tot: 105,
    gemmiddeld: 90.05,
    verschil: 30,
  },
  {
    label: "A",
    van: 105.01,
    tot: 160,
    gemmiddeld: 132.505,
    verschil: 55,
  },
  {
    label: "B",
    van: 160.01,
    tot: 190,
    gemmiddeld: 175.005,
    verschil: 30,
  },
  {
    label: "C",
    van: 190.01,
    tot: 250,
    gemmiddeld: 220.005,
    verschil: 60,
  },
  {
    label: "D",
    van: 250.01,
    tot: 290,
    gemmiddeld: 270.005,
    verschil: 40,
  },
  {
    label: "E",
    van: 290.01,
    tot: 335,
    gemmiddeld: 312.505,
    verschil: 0,
  },
  {
    label: "F",
    van: 335.01,
    tot: 380,
    gemmiddeld: 357.505,
    verschil: 45,
  },
  {
    label: "G",
    van: 380.01,
    tot: 380000000,
    gemmiddeld: 380.01,
    verschil: 45,
  },
];

export const labelLookup = {
  "A++++": { gemiddeld: 0, color: "#009900" },
  "A+++": { gemiddeld: 25, color: "#009900" },
  "A++": { gemiddeld: 62.505, color: "#009900" },
  "A+": { gemiddeld: 90.005, color: "#009900" },
  A: { gemiddeld: 132.505, color: "#009900" },
  B: { gemiddeld: 175.005, color: "#33CC33" },
  C: { gemiddeld: 220.005, color: "rgb(169, 221, 169)" },
  D: { gemiddeld: 270.005, color: "rgb(213, 213, 75)" },
  E: { gemiddeld: 312.505, color: "#FFC000" },
  F: { gemiddeld: 357.505, color: "#FF0000" },
  G: { gemiddeld: 380, color: "#C00000" },
};

export const pompSoorten = [
  { id: 1, name: "Hybride", factor: 0.4 },
  { id: 2, name: "All electric", factor: 0.8 },
];

export const defaultSolarPanelProjectProperties = {
  GO: 100,
  aantal: 10,
  vermogen: 450,
  factor: 0.85,
  kosten: 1.5,
  btw: 0,
  cyclus: 25,
  tarief: 0.4,
  energyLabel: lowestLabel,
};

// TODO: determine if this will be used in the future
export const heatPumpTypes = {
  "Goedkoop is duurkoop": {
    kosten: 5000,
    rendement: 350,
    besparing: 55,
    cycle: 15,
  },
  "Lucht-water 4kw": { kosten: 6000, rendement: 400, besparing: 60, cycle: 15 },
  "Duure pomp": { kosten: 8000, rendement: 450, besparing: 70, cycle: 18 },
};

export const updateSolarPanelSustanibilityProperties = (newProjectValues) => {
  newProjectValues["rendement"] = (
    newProjectValues.vermogen * newProjectValues.factor
  ).toFixed(2);

  newProjectValues["opgewektVermogen"] = (
    newProjectValues.aantal * newProjectValues.rendement
  ).toFixed(2);

  newProjectValues["energiebesparing"] = (
    newProjectValues.opgewektVermogen / newProjectValues.GO
  ).toFixed(2);

  if (newProjectValues.energyLabel) {
    newProjectValues.huidigEp2 =
      labelLookup[newProjectValues.energyLabel].gemiddeld;
  } else {
    newProjectValues.huidigEp2 = labelLookup[lowestLabel].gemiddeld;
  }

  newProjectValues.nieuwEp2 =
    newProjectValues.huidigEp2 - newProjectValues.energiebesparing;

  const newLabel = labelData.find(
    (x) =>
      x.van < newProjectValues.nieuwEp2 && x.tot >= newProjectValues.nieuwEp2
  );

  if (newLabel) {
    newProjectValues.newEnergyLabel = newLabel.label;
  } else {
    newProjectValues.newEnergyLabel = lowestLabel;
  }

  newProjectValues.dakoppervlak = (newProjectValues.aantal * 2).toFixed(1);

  newProjectValues.total =
    newProjectValues.kosten *
    newProjectValues.vermogen *
    newProjectValues.aantal *
    (1.0 + newProjectValues.btw / 100);

  newProjectValues.reservering = (
    newProjectValues.total / newProjectValues.cyclus
  ).toFixed();

  newProjectValues.huidig = (
    newProjectValues.tarief *
    newProjectValues.huidigEp2 *
    newProjectValues.GO
  ).toFixed();
  newProjectValues.besparing = (
    newProjectValues.tarief *
    newProjectValues.energiebesparing *
    newProjectValues.GO
  ).toFixed();
  newProjectValues.terugverdientijd = (
    newProjectValues.total / newProjectValues.besparing
  ).toFixed(1);

  return newProjectValues;
};

export const updateHeatPumpSustanibilityProperties = (
  newProjectValues,
  updatePump
) => {
  if (updatePump || !newProjectValues.pomp) {
    newProjectValues.pomp = heatPumpTypes[newProjectValues.pompNaam];

    newProjectValues.pompRendement = newProjectValues.pomp.rendement;
    newProjectValues.pompBesparing = newProjectValues.pomp.besparing;
    newProjectValues.pompCycle = newProjectValues.pomp.cycle;
    newProjectValues.pompKosten = newProjectValues.pomp.kosten;
  }

  const typeOfPump = pompSoorten.find(
    (x) => Number(x.id) === Number(newProjectValues.typeWarmtepomp)
  );

  newProjectValues.totaalGasVerwarmen = (
    newProjectValues.totaalGas - newProjectValues.totaalGasKoken
  ).toFixed(2);

  newProjectValues.totaalKostenVervarmingEnWarmWater = (
    newProjectValues.totaalGasVerwarmen * newProjectValues.kostenGas
  ).toFixed(2);

  newProjectValues.totaalEnergieVervarmingEnWarmWater = (
    newProjectValues.totaalGasVerwarmen * newProjectValues.factorGasElectra
  ).toFixed(2);

  newProjectValues.besparingGasPerjaar = (
    (newProjectValues.totaalGasVerwarmen * newProjectValues.pompBesparing) /
    100.0
  ).toFixed(2);

  newProjectValues.besparingEnergiePerJaar = (
    (newProjectValues.totaalEnergieVervarmingEnWarmWater *
      newProjectValues.pompBesparing) /
    100.0
  ).toFixed(2);

  newProjectValues.electriciteitPompGebruik = (
    (newProjectValues.besparingEnergiePerJaar /
      newProjectValues.pompRendement) *
    100.0
  ).toFixed(2);

  newProjectValues.electriciteitPompKosten = (
    newProjectValues.electriciteitPompGebruik * newProjectValues.kostenElectra
  ).toFixed(2);

  newProjectValues.energiebesparing = (
    (newProjectValues.besparingGasPerjaar * newProjectValues.factorGasElectra) /
    newProjectValues.GO
  ).toFixed(2);

  newProjectValues.besparingKostenGasPerjaar = (
    newProjectValues.besparingGasPerjaar * newProjectValues.kostenGas
  ).toFixed(2);

  newProjectValues.nettoBesparingEnergieKosten = (
    newProjectValues.besparingKostenGasPerjaar -
    newProjectValues.electriciteitPompKosten
  ).toFixed(2);

  newProjectValues.pompKostenNaSubsidie = (
    newProjectValues.pompKosten - newProjectValues.subsidy
  ).toFixed(2);

  newProjectValues.afschrijvingPerJaar = (
    newProjectValues.pompKostenNaSubsidie / newProjectValues.pompCycle
  ).toFixed(2);
  //
  newProjectValues.nettoBesparingTotaal = (
    newProjectValues.nettoBesparingEnergieKosten -
    newProjectValues.afschrijvingPerJaar
  ).toFixed(2);

  newProjectValues.terugverdientijd = (
    newProjectValues.pompKostenNaSubsidie /
    newProjectValues.nettoBesparingTotaal
  ).toFixed(2);

  newProjectValues.requiredCapacity =
    ((newProjectValues.totaalGasVerwarmen - newProjectValues.bewoners * 100) /
      200) *
    typeOfPump.factor;

  newProjectValues.opmerking =
    Number(newProjectValues.terugverdientijd) <
    Number(newProjectValues.pompCycle)
      ? "Rendabel"
      : "Niet rendabel";

  // label calculation
  if (newProjectValues.energyLabel) {
    newProjectValues.huidigEp2 =
      labelLookup[newProjectValues.energyLabel].gemiddeld;
  } else {
    newProjectValues.huidigEp2 = labelLookup[lowestLabel].gemiddeld;
  }

  newProjectValues.nieuwEp2 =
    newProjectValues.huidigEp2 - newProjectValues.energiebesparing;

  const newLabel = labelData.find(
    (x) =>
      x.van < newProjectValues.nieuwEp2 && x.tot >= newProjectValues.nieuwEp2
  );

  if (newLabel) {
    newProjectValues.newEnergyLabel = newLabel.label;
  } else {
    newProjectValues.newEnergyLabel = lowestLabel;
  }

  return newProjectValues;
};
