import ReactDOM from "react-dom/client";
import { useEffect } from "react";
import * as components from "./InjectableCmsComponents";

// Input string with spaces

// Function to convert into camel Case
function camelCase(str) {
  // Using replace method with regEx
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "")
    .replace(/\W/g, "");
}

const ComponentInjector = (props) => {
  useEffect(() => {
    if (props.component) {
      const componentName = camelCase(props.component);
      const rootElement = document.getElementById(componentName);
      if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);
        switch (componentName) {
          default:
          case "OzbEnRiool":
            root.render(<components.OzbEnRiool />);
            break;
        }
      }
    }
  });

  return <>{props.children}</>;
};

export default ComponentInjector;
