import { useEffect, useState } from "react";
import TextInputField from "../../../components/common/TextInput";
import { CompositeHeader } from "../../../components/header";
import * as projFields from "../projectConstants";
import * as pages from "../../../navigation";
import * as states from "../../../redux/states";
import { DisplayLabel } from "../../../components/common/DisplayLabel";
import {
  deletePublicData,
  getquickstartaddressdata,
  selectPublicAddressData,
  selectQuickStartAddressLoadStatus,
} from "../../../redux/slices/quickStartSlice";
import { useDispatch, useSelector } from "react-redux";

import QuickStartNavigationButtons from "./quickStartNavigationButtons";
import transition from "./transition";
import { deleteNewProject } from "../../../redux/slices/userProjectSlice";
import { HouseNumberIsValid, PostalCodeIsValid } from "../projectFunctions";
import Loader from "../../../components/loader";
import { AnchorButton } from "../../../components/common/Buttons";

const QuickStartSelectAddress = () => {
  const [errors, setErrors] = useState({});

  const [addressIsUpdated, setAddressIsUpdated] = useState();
  const dispatcher = useDispatch();
  const publicData = useSelector(selectPublicAddressData);
  const addressLoadStatus = useSelector(selectQuickStartAddressLoadStatus);
  const [projectParams, setProjectParams] = useState({
    [projFields.postalCode]: "",
    [projFields.houseNumber]: "",
  });

  useEffect(() => {
    // fires each time the user comes to the address page
    dispatcher(deleteNewProject());
    dispatcher(deletePublicData());
  }, [dispatcher]);

  const postalCodeIsValid = PostalCodeIsValid(projectParams.postalCode);
  const houseNumberIsValid = HouseNumberIsValid(projectParams.houseNumber);

  const updateProjectParams = (e) => {
    const { name, value } = e.target;
    setProjectParams({ ...projectParams, [name]: value });
    setAddressIsUpdated(true);
  };

  const onBlur = (e) => {
    let tempErrors = {};
    if (postalCodeIsValid && houseNumberIsValid) {
      dispatcher(
        getquickstartaddressdata({
          postalCode: projectParams.postalCode,
          houseNumber: projectParams.houseNumber,
        })
      );
      setAddressIsUpdated(false);
    } else {
      if (!postalCodeIsValid && projectParams.postalCode) {
        tempErrors[projFields.postalCode] = [
          "Postcode heeft een onjuiste invoer",
        ];
      }
      if (!houseNumberIsValid && projectParams.houseNumber) {
        tempErrors[projFields.houseNumber] = [
          "Huisnummer heeft een onjuiste invoer",
        ];
      }
    }
    setErrors(tempErrors);
  };

  const searchButton = ({ className }) => {
    if (postalCodeIsValid && houseNumberIsValid) {
      return (
        <AnchorButton text={"Zoek"} onClick={onBlur} className={className} />
      );
    } else {
      return (
        <AnchorButton text={"Zoek"} disabled={true} className={className} />
      );
    }
  };

  return (
    <>
      <CompositeHeader title="Kies het project adres" />
      <TextInputField
        label="Postcode"
        name={projFields.postalCode}
        value={projectParams.postalCode}
        onChange={updateProjectParams}
        error={errors[projFields.postalCode]}
      />

      <TextInputField
        label="Huisnummer"
        name={projFields.houseNumber}
        value={projectParams.houseNumber}
        onChange={updateProjectParams}
        error={errors[projFields.houseNumber]}
      />

      <DisplayLabel
        label={"Straat"}
        text={publicData?.bagData?.street}
        className={"has-background-light"}
      />

      <DisplayLabel
        label={"Stad"}
        text={publicData?.bagData?.city}
        className={"has-background-light"}
      />

      {!publicData && addressLoadStatus === states.succeeded && (
        <div className="is-danger">
          <div className="is-horizontal has-text-danger">
            {" "}
            Geen adres gevonden. U kunt uit de referentiegebouwen lijst zoeken{" "}
          </div>
        </div>
      )}

      {addressLoadStatus === states.loading && <Loader center={true} />}

      <QuickStartNavigationButtons
        path={pages.quickStartBuildingType}
        PrefixChild={
          addressIsUpdated || (!addressIsUpdated && !publicData)
            ? searchButton
            : undefined
        }
        showChoose={!publicData && addressLoadStatus === states.succeeded}
      />
    </>
  );
};

export default transition(QuickStartSelectAddress);
