const preventDefault = (e) => {
  //if (e.target.form === undefined) {
  e.stopPropagation();
  return false;
  //}
};

export const RevModalOpaqueFullScreen = ({ id, className, ...props }) => {
  return (
    <div id={id} className={`is-hidden rev-modal`}>
      <div
        className={`rev-modal-content-fullscreen ${className}`}
        onClick={preventDefault}
      >
        {props.children}
      </div>
    </div>
  );
};

export const RevModalTransparentSmall = ({ id, className, ...props }) => {
  return (
    <div id={id} className={`is-hidden rev-modal`}>
      <div
        className={`rev-modal-content-half ${className}`}
        onClick={preventDefault}
      >
        {props.children}
      </div>
    </div>
  );
};
