import store from "../redux/store";
// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
export async function client(endpoint, { body, ...customConfig } = {}, file) {
  const headers = {
    "Content-Type": "application/json",
    "Rev-React-Client": "1.0",
  };

  // best way so far to add bearer token header to all api fetch and post requests. Store should be immutable outside reducers
  const token = store.getState().userData.token;
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...(!token ? {} : { Authorization: `Bearer ${token}` }),
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  if (file) {
    try {
      const response = await window.fetch(endpoint, config);
      if (response.ok) {
        data = await response.blob();
        let fileName;
        let itterator = response.headers.entries();
        let header;
        if (body) {
          do {
            header = itterator.next();

            if (header.value && header.value[0] === "content-disposition") {
              const extension = header.value[1].match(
                /(.pdf|.docx|.doc|.xlsx|.xls|.txt|.csv|.json|.jpg|.jpeg|.png|.svg)/g
              )[0];

              if (body.name) {
                fileName = `${body.name}${extension}`;
              } else if (body.fileName) {
                fileName = `${body.fileName}${extension}`;
              }

              break;
            }
          } while (!header.done);
        }
        // Return a result object similar to Axios
        return {
          status: response.status,
          data,
          fileName,
          headers: response.headers,
          url: response.url,
        };
      }
      if (response.status >= 500) {
        return Promise.reject(
          JSON.stringify({
            status: response.status,
            message: response.statusText,
          })
        );
      }
      data = await response.json();
      return Promise.reject(
        JSON.stringify({
          status: response.status,
          message: response.statusText,
          errors: data.message,
        })
      );
    } catch (err) {
      return Promise.reject(err.message ? err.message : data);
    }
  } else {
    try {
      const response = await window.fetch(endpoint, config);
      try {
        if (response.status !== 204) {
          data = await response.json();
        } else {
          data = undefined;
        }
      } catch (err) {
        console.error("error deserializing to json: " + err);
      }
      // Return a result object similar to Axios
      if (response.ok) {
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        };
      }
      if (data) {
        // the data will contain a specific error message. used for validations
        return Promise.reject(
          JSON.stringify({
            status: data.status,
            message: data.title,
            errors: data.errors,
          })
        );
      }
      return Promise.reject(
        JSON.stringify({
          status: response.status,
          message: response.statusText,
        })
      );
    } catch (err) {
      console.error(err);
      return Promise.reject(err.message ? err.message : data);
    }
  }
}

client.downloadGet = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET", body }, true);
};

client.download = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body }, true);
};

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body });
};

client.delete = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "DELETE" });
};
