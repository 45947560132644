import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
// buttons
const defaultPrimaryButtonClasses = "button is-primary";
const defaultSecondaryButtonClasses = "button is-primary is-light";
const defaultNavbarClasses = "navbar-item";

export const SubmitButtonPrimary = ({ text, className }) => (
  <SubmitButton
    className={`${defaultPrimaryButtonClasses} ${className}`}
    text={text}
  />
);

export const FloatingButtonWrapper = (props) => {
  const { className, style } = { ...props };
  return (
    <div className={`${className}`} style={style}>
      {props.children}
    </div>
  );
};

export const SubmitButton = ({ className, text }) => {
  const classes = className ? className : defaultPrimaryButtonClasses;
  return <input type="submit" className={classes} value={text} />;
};

export const FloatingAnchorButton = ({
  onClick,
  text,
  disabled,
  className,
}) => {
  return (
    <AnchorButton
      onClick={onClick}
      disabled={disabled}
      text={
        <>
          <FontAwesomeIcon icon={faArrowLeftLong} />
          {text && <>&nbsp; {text} </>}
        </>
      }
      className={`button is-pulled-left is-medium has-text-center rev-floating-button rev-round is-primary ${className}`}
    />
  );
};

export const AnchorButtonPrimary = ({
  text,
  dataTarget,
  onClick,
  disabled,
  className,
}) => {
  const classes = className ? className : defaultPrimaryButtonClasses;

  return (
    <AnchorButton
      onClick={disabled ? () => {} : onClick}
      className={classes}
      disabled={disabled}
      dataTarget={dataTarget}
      text={text}
    />
  );
};

export const AnchorButtonSecondary = ({
  className,
  text,
  dataTarget,
  onClick,
  disabled,
}) => {
  return (
    <AnchorButton
      onClick={disabled ? () => {} : onClick}
      className={`${defaultSecondaryButtonClasses} ${className}`}
      disabled={disabled}
      dataTarget={dataTarget}
      text={text}
    />
  );
};

export const AnchorButton = ({
  className,
  text,
  dataTarget,
  onClick,
  disabled,
  icon,
  href,
  target = "_self",
}) => {
  const classes = className ? className : defaultPrimaryButtonClasses;
  return (
    <>
      <a
        onClick={onClick}
        className={classes}
        disabled={disabled}
        data-target={dataTarget}
        href={href}
        target={target}
      >
        {icon && (
          <>
            <FontAwesomeIcon icon={icon} /> &nbsp;
          </>
        )}
        {text}
      </a>
    </>
  );
};

export const SpanButton = ({ className, text, dataTarget }) => {
  const classes = className
    ? defaultPrimaryButtonClasses.concat(" ", className)
    : defaultPrimaryButtonClasses;

  return (
    <span className={classes} data-target={dataTarget}>
      {text}
    </span>
  );
};

export const FooterRevLinkExternal = ({ text, path, icon, target }) => {
  return (
    <p className="mb-1">
      <a href={path} target={target}>
        <span className="icon-text">
          <span className="icon mr-1">
            {icon && <FontAwesomeIcon icon={icon} />} &nbsp;
          </span>
          {text}
        </span>
      </a>
    </p>
  );
};

export const FooterRevLinkInternal = ({
  className,
  activeClass,
  text,
  path,
  icon,
}) => {
  return (
    <p className="mb-1">
      <NavLink className={className} activeclassname={activeClass} to={path}>
        <span className="icon-text">
          <span className="icon mr-1">
            {icon && <FontAwesomeIcon icon={icon} />} &nbsp;
          </span>
          {text}
        </span>
      </NavLink>
    </p>
  );
};

export const ExternRevLink = ({
  className,
  text,
  path,
  activeClass,
  icon,
  target,
}) => {
  const classes = className
    ? defaultNavbarClasses.concat(" ", className)
    : defaultNavbarClasses;

  return (
    <a
      className={classes}
      activeclassname={activeClass}
      href={path}
      target={target}
    >
      {text}
      {icon && <FontAwesomeIcon icon={icon} />}
    </a>
  );
};

export const RevEmptyLink = ({ className, text, path }) => {
  return (
    <Link className={className} to={path}>
      {text}
    </Link>
  );
};

export const RevPageLink = ({
  className,
  text,
  path,
  state,
  isActive = true,
}) => {
  const classes = className
    ? defaultPrimaryButtonClasses.concat(" ", className)
    : defaultPrimaryButtonClasses;
  return (
    <>
      {isActive && (
        <Link className={classes} to={path} state={state}>
          {text}
        </Link>
      )}
      {!isActive && (
        <Link className={classes} to={"#"} state={state} disabled>
          {text}
        </Link>
      )}
    </>
  );
};

export const RevChangePasswordNavbarLink = ({ text }) => {
  return <RevIdentityLinkLink text={text} className={defaultNavbarClasses} />;
};

export const RevIdentityLinkLink = ({ className, text, set2FA }) => {
  const pathname = useLocation();
  let returnUrl;
  if (typeof window !== "undefined") {
    returnUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      pathname.pathname;
  }
  const env = process.env.REACT_APP_ENVIRONMENT;
  let passwordUrl;
  if (set2FA) {
    passwordUrl = process.env[`REACT_APP_${env}_REGISTER_2FA_URL`];
  } else {
    passwordUrl = process.env[`REACT_APP_${env}_CHANGEPASSWORD_URL`];
  }

  return (
    <>
      {returnUrl && (
        <a
          className={className}
          href={`${passwordUrl}?returnurl=${encodeURIComponent(returnUrl)}`}
        >
          {text}
        </a>
      )}
    </>
  );
};
