import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const NewProjectNavigationButton = ({
  objectIndex,
  navigateLeft,
  navigateRight,
}) => {
  return (
    <>
      {navigateLeft && (
        <div
          onClick={() => navigateLeft(objectIndex)}
          className="is-hidden-touch rev-project-new-arrow rev-project-new-arrow-left"
        >
          <span className="icon rev-project-new-fa">
            <FontAwesomeIcon icon={faAngleLeft} />
          </span>
        </div>
      )}
      {navigateRight && (
        <div
          onClick={() => navigateRight(objectIndex)}
          className="is-hidden-touch rev-project-new-arrow rev-project-new-arrow-right"
        >
          <span className="icon rev-project-new-fa">
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </div>
      )}
    </>
  );
};

export default NewProjectNavigationButton;
