import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectBouwdelen,
  updateBouwdeel,
  updateHandeling,
  loadBouwdeelHandelingen,
  selectProjectHandelingen,
  selectSystemYear,
} from "../../redux/slices/userProjectSlice";
import ProjectOnderhoudskosten from "./ProjectOnderhoudskosten";
import { SetCanSave, SetShouldSaveForReports } from "./ProjectSession";
import { hasOnderhoudAccess } from "../../redux/slices/userSlice";
import * as events from "../../custom-scripts/custom-javascript-events";
// when opening the ProjectFormManager, it is expected that at least all the selected project bouwdelen are present.

const ProjectBouwdelenFormManager = ({
  id,
  project,
  loading,
  dispatcher,
  errors,
}) => {
  const [bouwdeelId, setBouwdeelId] = useState(0);
  const [bouwdelen, setBouwdelen] = useState({});
  const [handelingen, setHandelingen] = useState({});
  const startYear = useSelector(selectSystemYear);
  const tempBouwdelen = useSelector(selectProjectBouwdelen);
  const tempHandelingen = useSelector(selectProjectHandelingen);

  useEffect(() => {
    const customEvent = new Event(events.eventToggleBoxOnclick);
    document.dispatchEvent(customEvent);
  });

  useEffect(() => {
    setBouwdelen(tempBouwdelen);
    setHandelingen(tempHandelingen);
  }, [tempBouwdelen, tempHandelingen]);

  const onDispatchSelectBouwdeel = (e) => {
    SetCanSave(true);
    SetShouldSaveForReports(true);
    setBouwdeelId(e.target.value);
    dispatcher(
      loadBouwdeelHandelingen({
        year: project.year,
        objectBouwdeelIds: [e.target.value],
        vtwCode: project.reference.vtwCode,
      })
    );
  };

  const onRemoveBouwdeel = (e) => {
    SetCanSave(true);
    SetShouldSaveForReports(true);
    dispatcher(
      updateBouwdeel({
        id: e.target.dataset.target,
        projectId: project.id,
        name: "active",
        value: false,
      })
    );
  };

  const onChangeBouwdeel = (e) => {
    // get id of bouwdeel and use dispatch updateBouwdeel
    SetCanSave(true);
    SetShouldSaveForReports(true);
    const id = e.target.dataset.target;
    const { name, value } = e.target;
    dispatcher(updateBouwdeel({ id, projectId: project.id, name, value }));
  };

  const onChangeHandeling = (e) => {
    // get id of handeling and use dispatch updateHandeling
    SetCanSave(true);
    SetShouldSaveForReports(true);
    const id = e.target.dataset.target;
    const { name, value } = e.target;
    dispatcher(updateHandeling({ id, name, value }));
  };

  const onderhoudAccess = useSelector(hasOnderhoudAccess);

  return (
    <>
      {project && (
        <ProjectOnderhoudskosten
          project={project}
          bouwdelen={bouwdelen}
          handelingen={handelingen}
          bouwdeelId={bouwdeelId}
          onChangeBouwdeel={onChangeBouwdeel}
          onChangeHandeling={onChangeHandeling}
          onSelectBouwdeel={onDispatchSelectBouwdeel}
          onRemovebouwdeel={onRemoveBouwdeel}
          onderhoudAccess={onderhoudAccess}
          startYear={startYear}
        />
      )}
    </>
  );
};

export default ProjectBouwdelenFormManager;
