import { useState } from "react";
import { NumberInputRekenhulp } from "../../components/common/NumberInput";
import { SelectInputRowRekenhulp } from "../../components/common/SelectInput";
import { useLocation } from "react-router";
import { RevNumberToString } from "../../modules/revCalculation";
import {
  defaultSolarPanelProjectProperties,
  updateSolarPanelSustanibilityProperties,
} from "./revEnergyCalculations";
import { CalculationResult, DisplayRow, DisplayRowLabel } from "./components";

function Zonnepaneel() {
  const location = useLocation();

  const [project, setProject] = useState(
    updateSolarPanelSustanibilityProperties(defaultSolarPanelProjectProperties)
  );

  const handleChange = (e) => {
    let updatedValue = {};
    updatedValue[e.target.name] = e.target.value;
    const newProjectValues = updateSolarPanelSustanibilityProperties({
      ...project,
      ...updatedValue,
    });

    setProject(newProjectValues);
  };

  const TableDisplay = () => {
    return (
      <div className="card-content" style={{ padding: "20px 0px" }}>
        <table
          width="100%"
          style={{
            tableLayout: "fixed",
          }}
        >
          <tbody>
            <DisplayRow
              left={"Gebruikersoppervlakte woning (GO)"}
              right={`${project.GO} m²`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Aantal zonnepanelen"}
              right={`${project.aantal} stuks`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Vermogen per zonnepaneel"}
              right={`${RevNumberToString(project.vermogen)} Wp`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Factor rendement"}
              right={`${RevNumberToString(project.factor)}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Rendement per zonnepaneel"}
              right={`${RevNumberToString(project.rendement)} kWh`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Totaal opgewekt vermogen"}
              right={`${RevNumberToString(project.opgewektVermogen)} kWh p.j.`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Energiebesparing"}
              right={`${RevNumberToString(
                project.energiebesparing
              )} kWh/m² GO jaar`}
              color={"#195B2D"}
            />
            <DisplayRowLabel
              left={"Huidig energielabel"}
              label={`${project.energyLabel}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Huidig EP2"}
              right={`${RevNumberToString(
                project.huidigEp2.toFixed(2)
              )} kWh/m² GO jaar`}
              color={"#195B2D"}
              simpleTooltipText={"Primair fossiel energiegebruik"}
            />

            <DisplayRow
              left={"Vermindering EP2"}
              right={`${RevNumberToString(
                project.nieuwEp2.toFixed(2)
              )} kWh/m² GO jaar`}
              color={"#195B2D"}
            />
            <DisplayRowLabel
              left={"Nieuw energielabel"}
              label={`${project.newEnergyLabel}`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Benodigd netto dakoppervlak"}
              right={`${RevNumberToString(project.dakoppervlak)} m²`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Kosten Pv panelen"}
              right={`€ ${RevNumberToString(project.kosten)} per Wp`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"BTW"}
              right={`${project.btw} %`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Totale kosten"}
              right={`€ ${project.total.toFixed(0)}`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Levensduur"}
              right={`${project.cyclus} jaar`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Reservering"}
              right={`€ ${project.reservering} p.j.`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Energietarief"}
              right={`€ ${RevNumberToString(project.tarief)} per kWh`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Besparing"}
              right={`€ ${project.besparing} p.j.`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Terugverdientijd"}
              right={`${project.terugverdientijd} jaar`}
              color={"#195B2D"}
            />
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <section className="is-small pb-6" style={{ backgroundColor: "#D4E3D9" }}>
      <div className="container">
        <h1 className="title is-size-4-mobile is-3 is-flex-touch has-text-weight-bold p-4 m-4">
          Rekentool energielabel verbeteren door zonnepanelen
        </h1>
        <div className="columns is-vcentered m-0">
          <div className="column" id="top">
            <div
              className="card"
              style={{ padding: "10px", borderRadius: "20px" }}
            >
              <div className="card-content">
                <NumberInputRekenhulp
                  label="Gebruikersoppervlakte woning (GO)"
                  name={"GO"}
                  value={project.GO}
                  onChange={handleChange}
                  addonText={"m²"}
                  noDecimals={true}
                  simpleTooltipText={
                    "Betreft het energetisch gebruiksoppervlak (zie energielabel)"
                  }
                />

                <NumberInputRekenhulp
                  label="Aantal zonnepanelen"
                  name={"aantal"}
                  value={project.aantal}
                  onChange={handleChange}
                  addonText={"stuks"}
                  noDecimals={true}
                />

                <NumberInputRekenhulp
                  label="Vermogen zonnepaneel"
                  name={"vermogen"}
                  value={project.vermogen}
                  onChange={handleChange}
                  addonText={"Wp"}
                  noDecimals={true}
                  simpleTooltipText={"Zie specificatie fabrikanten"}
                />

                <NumberInputRekenhulp
                  label="Factor rendement"
                  name={"factor"}
                  value={project.factor}
                  onChange={handleChange}
                  noDecimals={false}
                  step={0.01}
                  simpleTooltipText={
                    "Is sterk afhankelijk van de locatie in Nederland, de hellingshoek van het paneel en de oriëntatie t.o.v. de zon."
                  }
                />

                <SelectInputRowRekenhulp
                  label="Huidig energielabel"
                  name={"energyLabel"}
                  simpleTooltipText={
                    "De beste rekenresultaten worden bereikt indien gebruik gemaakt wordt van een NTA 8800 energielabel. Bij een oud label kan er een afwijking ontstaan."
                  }
                  value={project.energyLabel}
                  onChange={handleChange}
                  options={[
                    { id: "A++++", name: "A++++" },
                    { id: "A+++", name: "A+++" },
                    { id: "A++", name: "A++" },
                    { id: "A+", name: "A+" },
                    { id: "A", name: "A" },
                    { id: "B", name: "B" },
                    { id: "C", name: "C" },
                    { id: "D", name: "D" },
                    { id: "E", name: "E" },
                    { id: "F", name: "F" },
                    { id: "G", name: "G" },
                  ]}
                />

                <NumberInputRekenhulp
                  label="Kosten Pv panelen"
                  simpleTooltipText={
                    "Totale kosten in het werk per Wattpiek (Wp)."
                  }
                  name={"kosten"}
                  value={project.kosten}
                  onChange={handleChange}
                  noDecimals={false}
                  prefixAddonText={"€"}
                  addonText={"per Wp"}
                  step={0.01}
                />

                <SelectInputRowRekenhulp
                  label="BTW"
                  name={"btw"}
                  value={project.btw}
                  onChange={handleChange}
                  options={[
                    { id: 0, name: "0 %" },
                    { id: 9, name: "9 %" },
                    { id: 21, name: "21 %" },
                  ]}
                />

                <NumberInputRekenhulp
                  label="Levensduur"
                  name={"cyclus"}
                  value={project.cyclus}
                  onChange={handleChange}
                  noDecimals={true}
                  addonText={"jaar"}
                  simpleTooltipText={
                    "Betreft de geschatte levensduur van het zonnesysteem."
                  }
                />

                <NumberInputRekenhulp
                  label="Energietarief (per kWh)"
                  name={"tarief"}
                  value={project.tarief}
                  onChange={handleChange}
                  step={0.01}
                  noDecimals={false}
                  prefixAddonText={"€"}
                  simpleTooltipText={
                    "Het energietarief dat betaald wordt aan de energieleverancier incl. energiebelasting, BTW en opslag duurzame energie. Er is in de berekening geen rekening gehouden met de salderingsregeling."
                  }
                />
              </div>
            </div>
          </div>

          <CalculationResult
            pathname={location.pathname}
            text={project.besparing}
            startLabel={project.energyLabel}
            endLabel={project.newEnergyLabel}
          />

          <div className="column" id="bottom">
            <div
              className="card is-shadowless"
              style={{
                padding: "10px",
                borderRadius: "20px",
                backgroundColor: "#D4E3D9",
              }}
            >
              <TableDisplay />
            </div>
          </div>
        </div>

        <p className="content is-italic p-4 m-4">
          <span className="has-text-weight-bold">Disclaimer:</span>
          <br></br>
          De berekeningsmethodiek is naar beste weten samengesteld, maar VAO,
          Koëter Vastgoed Adviseurs en eventuele andere auteurs zijn op geen
          enkele wijze aansprakelijk voor de juistheid of volledigheid van de
          geleverde informatie en/of kostenkengetallen. Met de berekeningen
          worden er handreikingen gegeven die gericht zijn op de benodigde
          investeringen voor een labelsprong. De lezer/gebruiker kan aan de
          uitkomsten geen rechten ontlenen, noch er in het economisch en
          maatschappelijk verkeer vertrouwen aan ontlenen. De berekeningen zijn
          deels vereenvoudigd en zijn niet te vergelijken met de volledige
          berekeningen van een gecertificeerde EPA-U of EPA-W adviseur.
        </p>
      </div>
    </section>
  );
}

export default Zonnepaneel;
