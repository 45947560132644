import BreadCrumbManager from "../../components/breadCrumbManager";
import Loader from "../../components/loader";
import { LessonSubjectLink } from "../../navbar/LessonSubjectLink";
import * as states from "../../redux/states";
import * as pages from "../../navigation";
import ScrollPage from "./ScrollPage";

const LessonSelection = ({ result, state }) => {
  let links = result.searchResults;
  let lessons = [];
  if (result.article) {
    links = [...result.searchResults].filter((x) => x.type === 0);
    lessons = [...result.searchResults].filter((x) => x.type !== 0);
  }

  // sort the links
  links = [...links].sort((a, b) => (a.title > b.title ? 1 : -1));
  return (
    <>
      {state === states.loading && <Loader center={true} />}
      {state !== states.loading && (
        <>
          <BreadCrumbManager />
          <ScrollPage article={result.article} lessons={lessons} />

          <section className="section pt-0">
            <div className="container">
              {links.map((link, i) => {
                return (
                  <li key={i}>
                    <LessonSubjectLink
                      article={link}
                      key={link.path}
                      path={pages.lesson}
                    />
                  </li>
                );
              })}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default LessonSelection;
