import * as links from "../../externalLinks";
import * as pages from "../../navigation";
import { RevImgFull } from "../../components/common/RevImg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { RevEmptyLink } from "../../components/common/Buttons";

const HomeFeatures = () => {
  const hoofdTitel = "Bereken deskundig de exploitatiekosten van uw vastgoed";
  const hoofdInhoud =
    "Met de Real Estate Valuator (REV®) kunt u exploitatiekosten van uw vastgoed berekenen.";
  const subInhoud =
    "Door middel van referentieobjecten kunt u de kosten berekenen voor het onderhoud, het herstellen van gebreken en duurzaamheidsmaatregelen.";
  const ColumnTargetGroup = ({ isMobile }) => {
    return (
      <div className="column">
        <h3 className="subtitle is-size-5">Ideaal voor:</h3>
        <ul>
          <li>Taxateurs</li>
          <li>Makelaars</li>
          <li>Woningcorporaties</li>
          <li>Bouwkundig adviseurs/inspecteurs</li>
          <li>Installatietechnisch adviseurs/inspecteurs</li>
        </ul>
        <RevEmptyLink
          text={"Meer over REV®"}
          path={pages.overRev}
          className={"button is-primary mb-2 is-rounded"}
        />{" "}
        &nbsp;
        <RevEmptyLink
          text={"Handige links"}
          path={pages.handigeLinks}
          className={"button is-primary is-light mb-2 is-rounded"}
        />
      </div>
    );
  };

  const ColumnMobiel = ({ isMobile }) => {
    const classes = isMobile
      ? "column box is-shadowless has-text-centered has-background-warning-light rev-mobile-margin"
      : "column box is-shadowless has-text-centered has-background-warning-light p-6 m-5";

    return (
      <div className={classes}>
        <div className="rev-mobile-padding">
          <h2 className="subtitle">REV® Web App</h2>
          <p className="content">
            Stel REV® in als Web App op uw mobiele telefoon en zet op uw
            beginscherm.
          </p>
        </div>
        <RevEmptyLink
          text={"Hoe? Ontdek het hier"}
          path={pages.webApp}
          className={
            "button is-warning is-rounded rev-text-link-rounded rev-mobile-bottom-margin rev-tablet-button-margin"
          }
        />
      </div>
    );
  };

  const ColumnPoweredBy = ({ isMobile }) => {
    return (
      <div className="column has-text-centered">
        <p className="has-text-weight-bold">Powered by:</p>
        <a
          href={links.koetervastgoed}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/koeter-logo.svg"
            title="Koeter Vastgoed Adviseurs"
            alt="Koeter Vastgoed Adviseurs Logo"
            className="mb-3"
            style={{ width: "150px" }}
          />
        </a>
        <br></br>
        <a href={links.shop} target="_blank" rel="noopener noreferrer">
          <img
            src="images/vao-logo.svg"
            title="Vastgoed Adviseurs Online"
            alt="Vastgoed Adviseurs Online Logo"
            className="mt-3"
            style={{ width: "150px" }}
          />
        </a>
      </div>
    );
  };

  const SectionEnergieLabelVerbetering = ({ isMobile }) => {
    const classes = isMobile
      ? "container section box is-shadowless has-text-centered is-fullwidth has-background-success-light"
      : "container section box is-shadowless has-text-centered is-fullwidth has-background-success-light";

    const buttonClass1 = isMobile
      ? "button is-rounded is-success rev-text-link-rounded rev-mobile-bottom-margin-small mr-1"
      : "button is-rounded is-success rev-text-link-rounded rev-tablet-button-margin";

    const buttonClass2 = isMobile
      ? "button is-rounded is-success rev-text-link-rounded rev-mobile-bottom-margin ml-1"
      : "button is-rounded is-success rev-text-link-rounded rev-tablet-button-margin";

    return (
      <div className={classes}>
        <div className={"rev-mobile-padding"}>
          <h2 className="title is-size-4 has-text-weight-bold">
            Energielabel verbeteren?
          </h2>
          <p className="content">
            Bereken hoeveel stappen u vooruit kunt gaan in uw energielabel door
            zonnepanelen of een hybride warmtepomp.
          </p>
        </div>
        <RevEmptyLink
          className={buttonClass1}
          path={pages.zonnepaneelcalculatie}
          text={"Zonnepanelen"}
        />
        <RevEmptyLink
          className={buttonClass2}
          path={pages.warmtepompcalculatie}
          text={"Hybride warmtepomp"}
        />
      </div>
    );
  };

  const ColumnActueleInfo = () => {
    return (
      <div className="column is-two-thirds-tablet">
        <div className="content pb-4">
          <div className="icon-text">
            <span className="icon mr-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="icon has-text-primary"
              />
            </span>
            <span className="subtitle has-text-weight-medium rev-subtitle-responsive-font">
              Actuele exploitatiekosten
            </span>
          </div>
          <p className="block mt-2">
            Toegang tot de meest actuele exploitatiekosten van vastgoed,
            inclusief OZB tarieven en rioollasten per gemeente.
          </p>
        </div>
        <div className="content pb-4">
          <h4 className="icon-text">
            <span className="icon mr-4">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="icon has-text-primary"
              />
            </span>
            <span className="subtitle has-text-weight-medium rev-subtitle-responsive-font">
              Actuele kostenkengetallen
            </span>
          </h4>
          <p className="block mt-2">
            Toegang tot de meest actuele kostenkengetallen voor het bouwkundig
            en installatietechnisch onderhoud van het vastgoed volgens de NL-SfB
            methode.
          </p>
        </div>
        <div className="content pb-4">
          <h2 className="icon-text">
            <span className="icon mr-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="icon has-text-primary"
              />
            </span>
            <span className="subtitle  has-text-weight-medium rev-subtitle-responsive-font">
              Veilige data & back-ups
            </span>
          </h2>
          <p className="block mt-2">
            Sla uw projecten data op in onze cloud omgeving.
          </p>
        </div>
        <div className="content pb-4">
          <h2 className="icon-text">
            <span className="icon mr-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="icon has-text-primary"
              />
            </span>
            <span className="subtitle has-text-weight-medium rev-subtitle-responsive-font">
              Professionele helpdesk
            </span>
          </h2>
          <p className="block">
            U wordt geholpen voor al uw bouwkundige, installatietechnische,
            verduurzaming en taxatie vragen.
          </p>
        </div>
      </div>
    );
  };

  return (
    <LazyLoadComponent visibleByDefault={false}>
      <div className="rev-responsive">
        <section className="section is-hidden-mobile">
          <div className="container pb-3">
            <h2 className="title is-size-4 has-text-weight-bold">
              <b>{hoofdTitel}</b>
            </h2>
            <p>{hoofdInhoud}</p>
            <p>{subInhoud}</p>
            <br></br>
            <div className="columns is-vcentered content mb-5 ">
              <ColumnTargetGroup />
              <ColumnMobiel />
              <ColumnPoweredBy />
            </div>
          </div>
          <SectionEnergieLabelVerbetering />
          <div className="container pt-6">
            <div className="columns is-vcentered">
              <ColumnActueleInfo />
              <div className="column">
                <RevImgFull
                  src="/images/ipad-iphone-voorbeelden-web.png"
                  title="tablet scherm referentieobjecten"
                  alt="overzicht tabletscherm en iphonescherm referentiegebouw onderhoudskosten"
                  className="image is-full-width"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section is-hidden-tablet">
          <div className="container pb-3">
            <h2 className="title is-size-4 is-size-4-desktop">
              <b>{hoofdTitel}</b>
            </h2>
            <p>{hoofdInhoud}</p>
            <p>{subInhoud}</p>
            <br></br>
            <div
              className="columns is-vcentered content mb-5"
              // style={{ display: "flex" }}
            >
              <ColumnTargetGroup isMobile={true} />
              <ColumnMobiel isMobile={true} />
              <ColumnPoweredBy isMobile={true} />
            </div>
          </div>
          <SectionEnergieLabelVerbetering isMobile={true} />
          <div className="container pt-6">
            <div className="columns is-vcentered">
              <ColumnActueleInfo isMobile={true} />
              <div className="column">
                <RevImgFull
                  src="/images/ipad-iphone-voorbeelden-web.png"
                  title="tablet scherm referentieobjecten"
                  alt="overzicht tabletscherm en iphonescherm referentiegebouw onderhoudskosten"
                  className="image is-full-width"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </LazyLoadComponent>
  );
};

export default HomeFeatures;
