import * as projectProps from "../pages/project/projectConstants";

export const CalculateBeheerObject = ({
  beheerObject,
  bouwdelen,
  handelingen,
}) => {
  const calculationBouwdelen = Object.values(bouwdelen)
    .filter((bouwdeel) => bouwdeel.active)
    .sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    )
    .map((bouwdeel) => {
      const bouwdeelHandelingen = Object.values(handelingen)
        .filter((handeling) => handeling.objectBouwdeelId === bouwdeel.id)
        .sort((a, b) =>
          a.nlsfbCode > b.nlsfbCode ? 1 : b.nlsfbCode > a.nlsfbCode ? -1 : 0
        );
      return CaculateBouwdeel({ beheerObject, bouwdeel, bouwdeelHandelingen });
    });

  const basisBouwdelen = calculationBouwdelen.filter(
    (bouwdeel) => bouwdeel.source.soortId === 1 || bouwdeel.source.soortId === 0
  );
  const duurzaamheidBouwdelen = calculationBouwdelen.filter((bouwdeel) =>
    IsDuurzaam(bouwdeel)
  );
  const gebrekBouwdelen = calculationBouwdelen.filter((bouwdeel) =>
    IsGebrek(bouwdeel)
  );
  const verbeteringBouwdelen = calculationBouwdelen.filter((bouwdeel) =>
    IsVerbetering(bouwdeel)
  );

  const calculationBeheerObject = {
    source: beheerObject,
    total: SumArray(calculationBouwdelen, "total"),
    basisTotal: SumArray(basisBouwdelen, "total"),
    duurzaamheidTotal: SumArray(duurzaamheidBouwdelen, "total"),
    gebrekTotal: SumArray(gebrekBouwdelen, "total"),
    verbeteringTotal: SumArray(verbeteringBouwdelen, "total"),
    basisBouwdelen: basisBouwdelen,
    duurzaamheidBouwdelen: duurzaamheidBouwdelen,
    gebrekBouwdelen: gebrekBouwdelen,
    verbeteringBouwdelen: verbeteringBouwdelen,
  };

  return calculationBeheerObject;
};

export const CaculateBouwdeel = ({
  beheerObject,
  bouwdeel,
  bouwdeelHandelingen,
}) => {
  const calculationAmount = bouwdeel.amount
    ? bouwdeel.amount
    : bouwdeel.scalesWithParentObject
    ? beheerObject.bvo
    : bouwdeel.referenceAmount;

  const calculatedHandelingen = Object.values(bouwdeelHandelingen)
    .filter((handeling) => handeling.objectBouwdeelId === bouwdeel.id)
    .map((handeling) => {
      return CaculateHandeling({
        beheerObject,
        bouwdeel,
        calculationAmount,
        handeling,
      });
    });

  return {
    calculationAmount: calculationAmount,
    source: bouwdeel,
    total: SumArray(calculatedHandelingen, "total"),
    handelingen: calculatedHandelingen,
  };
};

const IsVerbetering = (bouwdeel) => {
  if (bouwdeel.source) {
    return bouwdeel.source.soortId === 4;
  }
  return bouwdeel.soortId === 4;
};
const IsGebrek = (bouwdeel) => {
  if (bouwdeel.source) {
    return bouwdeel.source.soortId === 3;
  }
  return bouwdeel.soortId === 3;
};

const IsDuurzaam = (bouwdeel) => {
  if (bouwdeel.source) {
    return bouwdeel.source.soortId === 2;
  }
  return bouwdeel.soortId === 2;
};

export const BouwdeelIsAppliedOnce = (bouwdeel) => {
  return IsGebrek(bouwdeel) || IsVerbetering(bouwdeel) || IsDuurzaam(bouwdeel);
};

export const CaculateHandeling = ({
  beheerObject,
  bouwdeel,
  calculationAmount,
  handeling,
}) => {
  const bouwdeelIsAppliedOnce = BouwdeelIsAppliedOnce(bouwdeel);
  const livingUnits = bouwdeel.livingUnits === 0 ? 1 : bouwdeel.livingUnits;

  if (beheerObject.cycle >= handeling.cycle || bouwdeelIsAppliedOnce) {
    // for non businses users
    const btwIncrement = handeling.vatRate / 100;
    if (handeling.remotelyCalculated) {
      let bedragHandeling = handeling.costs;
      let vatAddition;
      if (!bouwdeelIsAppliedOnce) {
        bedragHandeling =
          bedragHandeling / bouwdeel.referenceAmount / livingUnits;
      } else {
        // as a correction for sever side cycle devision
        if (handeling.cycle !== 0) {
          bedragHandeling *= handeling.cycle;
        }
        bedragHandeling /= bouwdeel.referenceAmount;
      }
      bedragHandeling *= calculationAmount;

      vatAddition = beheerObject.includeVat
        ? bedragHandeling * btwIncrement
        : 0;

      return {
        source: handeling,
        vatAddition: vatAddition,
        total: bedragHandeling + vatAddition,
      };
    }

    const hiddenAfwijkingIncrement = 1 + handeling.afwijkingIncrement / 100;
    const hiddenAlgemeneEnWinstEnRisico =
      1 + (handeling.algemeneKosten + handeling.winstEnRisico) / 100;

    const hiddenCommercieleAfwijking =
      1 +
      (beheerObject.reference.isCommercialProperty !==
      beheerObject.commercialProperty
        ? beheerObject.potentialCommercialAdjustment
        : 0) /
        100;

    const afwijkingsFactor =
      hiddenAfwijkingIncrement *
      hiddenAlgemeneEnWinstEnRisico *
      hiddenCommercieleAfwijking;

    const cycle = handeling.cycle > 0 ? handeling.cycle : 1;
    const bedragMateriaal = handeling.bedragMateriaal * afwijkingsFactor;

    const bedragArbeid = handeling.bedragArbeid * afwijkingsFactor;
    const bedragMaterieel = handeling.bedragMaterieel * afwijkingsFactor;

    const bedragOnderaannemer =
      handeling.bedragOnderaannemer * afwijkingsFactor;

    const bedragStelpost = handeling.bedragStelpost * afwijkingsFactor;

    const totaalHandeling =
      bedragArbeid +
      bedragMateriaal +
      bedragMaterieel +
      bedragOnderaannemer +
      bedragStelpost;

    // bedrag afwijking negeert de winst en risico en algemene kosten
    const bedragAfwijking =
      handeling.bedragAfwijking *
      hiddenAfwijkingIncrement *
      hiddenCommercieleAfwijking;

    const bedragPerEenheid = totaalHandeling + bedragAfwijking;
    const bedragHandeling = bedragPerEenheid * handeling.quantity;

    let bedragJaarReservering;
    let bedragPerJaarPerEenheidPerWooneenheid;
    let bedragPerJaarPerWooneenheid;
    let vatAddition;

    if (bouwdeelIsAppliedOnce) {
      bedragJaarReservering = bedragHandeling;

      bedragPerJaarPerEenheidPerWooneenheid = bedragJaarReservering;

      bedragPerJaarPerWooneenheid =
        (bedragPerJaarPerEenheidPerWooneenheid * calculationAmount) /
        bouwdeel.referenceAmount /
        livingUnits;

      vatAddition = beheerObject.includeVat
        ? bedragPerJaarPerWooneenheid * btwIncrement
        : 0;
    } else {
      bedragJaarReservering = bedragHandeling / cycle;

      bedragPerJaarPerEenheidPerWooneenheid =
        bedragJaarReservering / bouwdeel.referenceAmount / livingUnits;

      bedragPerJaarPerWooneenheid =
        bedragPerJaarPerEenheidPerWooneenheid * calculationAmount;

      vatAddition = beheerObject.includeVat
        ? bedragPerJaarPerWooneenheid * btwIncrement
        : 0;
    }

    return {
      source: handeling,
      bedragAfwijking: bedragAfwijking,
      totaalHandeling: totaalHandeling,
      bedragMateriaal: bedragMateriaal,
      bedragMaterieel: bedragMaterieel,
      bedragOnderaannemer: bedragOnderaannemer,
      bedragStelpost: bedragStelpost,
      bedragArbeid: bedragArbeid,
      bedragPerEenheid: bedragPerEenheid,
      bedragHandeling: bedragHandeling,
      bedragJaarReservering: bedragJaarReservering,
      bedragPerJaarPerEenheidPerWooneenheid:
        bedragPerJaarPerEenheidPerWooneenheid,
      bedragPerJaarPerWooneenheid: bedragPerJaarPerWooneenheid,
      vatAddition: vatAddition,
      total: bedragPerJaarPerWooneenheid + vatAddition,
    };
  } // empty result
  else {
    return {
      source: handeling,
      bedragAfwijking: 0,
      totaalHandeling: 0,
      bedragMateriaal: 0,
      bedragMaterieel: 0,
      bedragOnderaannemer: 0,
      bedragStelpost: 0,
      bedragArbeid: 0,
      bedragPerEenheid: 0,
      bedragHandeling: 0,
      bedragJaarReservering: 0,
      bedragPerJaarPerEenheidPerWooneenheid: 0,
      bedragPerJaarPerWooneenheid: 0,
      vatAddition: 0,
      total: 0,
    };
  }
};

const SumArray = function (items, prop) {
  return items.reduce(function (a, b) {
    return a + b[prop];
  }, 0);
};

/* Help functions for the filtering of beheerObjecten  */

export const PeriodsForBeheerObjecten = function (beheerObjecten) {
  return PeriodOverlappingChoices.filter((period) => {
    return (
      beheerObjecten.findIndex(
        (beheerObject) =>
          beheerObject.bouwjaar >= period.min &&
          beheerObject.bouwjaar <= period.max
      ) > -1
    );
  });
};

export const PeriodOverlappingChoices = [
  { id: 0, name: "alles", min: 0, max: 9999 },
  { id: 1, name: "alles voor 1900", min: 0, max: 1900 },
  { id: 2, name: "1900 - 1935", min: 1900, max: 1935 },
  { id: 3, name: "1925 - 1955", min: 1925, max: 1955 },
  { id: 4, name: "1945 - 1975", min: 1945, max: 1975 },
  { id: 5, name: "1965 - 1995", min: 1965, max: 1995 },
  { id: 6, name: "1985 - 2015", min: 1985, max: 2015 },
  { id: 7, name: "alles na 2005", min: 2005, max: 9999 },
];

export const PeriodForBeheerObject = function (beheerObject) {
  return PeriodChoices.find((period) => {
    return (
      beheerObject.bouwjaar >= period.min &&
      beheerObject.bouwjaar <= period.max &&
      period.id > 0
    );
  });
};

export const recalibrateHerbouwInput = function ({
  herbouwWaardeBerekening,
  herbouwKosten,
  inhoud,
  bvo,
  herbouwKostenRate,
}) {
  switch (Number(herbouwWaardeBerekening)) {
    case 0:
      return herbouwKostenRate * inhoud;
    case 1:
      return herbouwKosten;
    case 2:
      return herbouwKosten / inhoud;
    case 3:
      return herbouwKosten / bvo;

    default:
      // undefined herbouwwaarde?
      break;
  }
};

export const calculateHerbouwWaarde = function ({
  herbouwWaardeBerekening,
  inhoud,
  bvo,
  herbouwKostenRate,
}) {
  switch (Number(herbouwWaardeBerekening)) {
    case 0:
    case 1:
      return herbouwKostenRate;
    case 2:
      let result = Number(herbouwKostenRate) * Number(inhoud);
      return result;

    case 3:
      return Number(herbouwKostenRate) * Number(bvo);

    default:
      // undefined
      break;
  }
};

export const PeriodChoices = [
  { id: 0, name: "alles", min: 0, max: 9999 },
  { id: 1, name: "alles voor 1900", min: 0, max: 1900 },
  { id: 2, name: "1900 - 1930", min: 1900, max: 1930 },
  { id: 3, name: "1930 - 1950", min: 1930, max: 1950 },
  { id: 4, name: "1950 - 1970", min: 1950, max: 1970 },
  { id: 5, name: "1970 - 1990", min: 1970, max: 1990 },
  { id: 6, name: "1990 - 2010", min: 1990, max: 2010 },
  { id: 7, name: "alles na 2019", min: 2010, max: 9999 },
];

export const FilterForPeriod = function (beheerObjecten, periodId) {
  const period = PeriodOverlappingChoices[periodId];
  return beheerObjecten.filter(
    (beheerObject) =>
      beheerObject.bouwjaar >= period.min && beheerObject.bouwjaar <= period.max
  );
};

export const RoofTypesForBeheerObjecten = function (beheerObjecten) {
  return RoofTypes.filter((type) => {
    return (
      type.id === 0 ||
      beheerObjecten.findIndex(
        (beheerObject) => beheerObject.roofTypeId === type.id
      ) > -1
    );
  });
};

export const FilterBeheerObjectenForObjectKind = function (
  beheerObjecten,
  objectSoortId
) {
  beheerObjecten = beheerObjecten.filter(
    (gebouw) => gebouw.objectSoortId === objectSoortId
  );
};

export const FilterBeheerObjectenForObjectType = function (
  beheerObjecten,
  objectTypeId
) {
  return beheerObjecten.filter(
    (gebouw) => gebouw.objectTypeId === objectTypeId
  );
};

export const RoofTypes = [
  { id: 0, name: "alle daktypen" },
  { id: 1, name: "hellend" },
  { id: 2, name: "plat" },
  { id: 3, name: "plat en hellend" },
];

export const FilterForRoofType = function (beheerObjecten, roofId) {
  if (roofId === 0) {
    return beheerObjecten;
  }
  return beheerObjecten.filter(
    (beheerObject) => beheerObject.roofTypeId === roofId
  );
};

const CalculateValueWithRateType = (rate, type, project) => {
  switch (type) {
    case 1: // fixed amount
      return rate;

    case 2: // volume
      return project[projectProps.inhoud] * rate;
    default:
      // 3 => ozb
      const wozwaarde = RevStringNumberToNumber(
        project[projectProps.wozwaarde]
      );
      if (wozwaarde) {
        return (rate * wozwaarde) / 100.0;
      }
      return 0;
  }
};

export const CalculateMunicipalityDependentValues = (
  municipalityData,
  project,
  waterAuthorities
) => {
  const financialOwner = project[projectProps.financialOwner];
  if (financialOwner) {
    let calculatedValues = {};
    calculatedValues[projectProps.rioolRate] = 0;
    calculatedValues[projectProps.waterRate] = 0;
    calculatedValues[projectProps.ozbRate] = 0;

    const owner = 1;
    const user = 2;
    const both = 3;
    if (municipalityData.length > 0) {
      const buildingType = project.reference[projectProps.isHousing] ? 1 : 2;

      if (Number(financialOwner) === owner || Number(financialOwner) === both) {
        // eigenaar only
        // ozb, rate type = 1
        const ownerRate = municipalityData.find(
          (rate) =>
            rate.financialOwner === owner &&
            rate.type === 1 &&
            rate.buildingType === buildingType
        );

        if (ownerRate) {
          calculatedValues[projectProps.ozbRate] += CalculateValueWithRateType(
            ownerRate.rate,
            ownerRate.paymentCalculation,
            project
          );
        }

        // riool
        const RioolRates = municipalityData.filter(
          (rate) =>
            rate.financialOwner === owner &&
            rate.type === 2 &&
            rate.buildingType === buildingType
        );

        RioolRates.forEach(
          (RioolRate) =>
            (calculatedValues[projectProps.rioolRate] +=
              CalculateValueWithRateType(
                RioolRate.rate,
                RioolRate.paymentCalculation,
                project
              ))
        );

        const waterRate = municipalityData.find((rate) => rate.type === 3);
        calculatedValues[projectProps.waterRate] = CalculateValueWithRateType(
          waterRate.rate,
          waterRate.paymentCalculation,
          project
        );
      }

      if (Number(financialOwner) === user || Number(financialOwner) === both) {
        // eigenaar only
        // ozb, rate type = 1
        const userOzbRate = municipalityData.find(
          (rate) =>
            rate.financialOwner === user &&
            rate.type === 1 &&
            rate.buildingType === buildingType
        );

        if (userOzbRate) {
          calculatedValues[projectProps.ozbRate] += CalculateValueWithRateType(
            userOzbRate.rate,
            userOzbRate.paymentCalculation,
            project
          );
        }

        // riool
        const userRioolRates = municipalityData.filter(
          (rate) =>
            rate.financialOwner === user &&
            rate.type === 2 &&
            rate.buildingType === buildingType
        );
        userRioolRates.forEach(
          (RioolRate) =>
            (calculatedValues[projectProps.rioolRate] +=
              CalculateValueWithRateType(
                RioolRate.rate,
                RioolRate.paymentCalculation,
                project
              ))
        );
      }
    }

    if (Number(financialOwner) === owner || Number(financialOwner) === both) {
      if (waterAuthorities && waterAuthorities.length > 0) {
        const waterAuthorityRate = waterAuthorities[0];

        if (waterAuthorityRate.rate && waterAuthorityRate.rate !== 0) {
          calculatedValues[projectProps.waterRate] = CalculateValueWithRateType(
            waterAuthorityRate.rate,
            waterAuthorityRate.paymentCalculation,
            project
          );
        }
      }
      // convert to string
    }
    calculatedValues[projectProps.waterRate] = RevRoundNumber(
      calculatedValues[projectProps.waterRate]
    );
    calculatedValues[projectProps.ozbRate] = RevRoundNumber(
      calculatedValues[projectProps.ozbRate]
    );
    calculatedValues[projectProps.rioolRate] = RevRoundNumber(
      calculatedValues[projectProps.rioolRate]
    );
    return calculatedValues;
  }
};

export const RevStringNumberToNumber = (stringValue) => {
  if (stringValue && stringValue !== undefined) {
    var number = Number(
      stringValue.toString().replace(".", "").replace(",", ".")
    );
    return number;
  }

  return 0;
};

export const RevStringToInteger = (stringValue) => {
  if (stringValue && stringValue !== undefined) {
    var number = Number.parseInt(
      stringValue.toString().replace(".", "").replace(",", ".")
    );
    return number;
  }

  return 0;
};

export const RevNumberToString = (numberValue) => {
  if (numberValue) {
    return Number.parseFloat(Number(numberValue).toFixed(6))
      .toString()
      .replace(".", ",");
  }
  return 0;
};

export const RevRoundNumber = (valueToRound, ignoreDecimals) => {
  if (valueToRound) {
    let round = "";
    if (ignoreDecimals) {
      round = Number(valueToRound).toFixed(0).toString();
    } else {
      let decimalVal = 0.0;
      if (isNaN(valueToRound)) {
        let temp = valueToRound.slice();
        temp = temp.replace(",", ".");
        decimalVal = Number(temp);
      } else {
        decimalVal += Number(valueToRound);
      }
      round = Number(decimalVal).toFixed(2).replace(".", ",");
    }
    return round.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return "0,00";
};

// fincacial owner values :
/*
1: Owner
2: User
3: UserAndOwner
4: unknownd
*/
