import * as classes from "../custom-scripts/custom-javascript-classes";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
export const ToggleDisplayButton = ({
  className,
  dataTarget,
  isClosed,
  onClick,
  ...props
}) => {
  // transitioning is set on mag-height. If current height is 0, then the transition animation will fail

  const clearMaxHeightStyleContainer = (e) => {
    let target = document.querySelector(`#${dataTarget}`);
    target.style.maxHeight = null;
  };

  const onClickWrapper = (e) => {
    const target = document.querySelector(`#${dataTarget}`);
    target.removeEventListener("click", clearMaxHeightStyleContainer);
    target.addEventListener("click", clearMaxHeightStyleContainer, {
      once: true,
    });
    if (onClick) {
      onClick(e);
    }
  };

  const prepareToggleBox = (e) => {
    let target = document.querySelector(`#${dataTarget}`);
    if (target) {
      // dangerous transitioning, due to the dependency to scrollheight.
      const scrollHeight = target.scrollHeight;
      const display = target.style.display;
      target.style.display = "none";
      target.style.maxHeight = `${scrollHeight}px`;

      if (isClosed) {
        target.classList.add(classes.shrinkTab);
      }
      target.style.display = display;
      target.removeEventListener("click", clearMaxHeightStyleContainer);
      target.addEventListener("click", clearMaxHeightStyleContainer, {
        once: true,
      });
    }
  };

  useEffect(() => {
    prepareToggleBox();
  });

  return (
    <div
      className={`${classes.toggleBox} ${className ? className : ""}`}
      data-target={dataTarget}
      onClick={onClickWrapper}
    >
      {/* eslint-disable-next-line  */}
      {/* <a style={{ width: "100%" }}> */}

      <span
        className={`icon is-small rev-carret ${isClosed ? "is-hidden" : ""}`}
        style={{ float: "left", verticalAlign: "bottom", display: "inline" }}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
      <span
        className={`icon is-small rev-carret ${isClosed ? "" : "is-hidden"}`}
        style={{ float: "left", verticalAlign: "bottom", display: "inline" }}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </span>
      {props.children}
      {/* </a> */}
    </div>
  );
};

// the simple toggle box, nesting as a param
export const ToggleDisplayLink = ({
  text,
  className,
  dataTarget,
  isClosed,
  onClick,
  includeNested,
}) => {
  // transitioning is set on mag-height. If current height is 0, then the transition animation will fail
  const clearMaxHeightStyleContainer = (e) => {
    let target = document.querySelector(`#${dataTarget}`);
    target.style.maxHeight = null;
  };

  const onClickWrapper = (e) => {
    if (includeNested) {
      const target = document.querySelector(`#${dataTarget}`);
      target.removeEventListener("click", clearMaxHeightStyleContainer);
      target.addEventListener("click", clearMaxHeightStyleContainer, {
        once: true,
      });
    }
    if (onClick) {
      onClick(e);
    }
  };

  const prepareToggleBox = (e) => {
    let target = document.querySelector(`#${dataTarget}`);
    if (target) {
      // dangerous transitioning, due to the dependency to scrollheight.
      const scrollHeight = target.scrollHeight;
      const display = target.style.display;
      target.style.display = "none";
      target.style.maxHeight = `${scrollHeight}px`;

      target.style.display = display;
      if (includeNested) {
        target.removeEventListener("click", clearMaxHeightStyleContainer);
        target.addEventListener("click", clearMaxHeightStyleContainer, {
          once: true,
        });
      }
    }
  };

  useEffect(() => {
    prepareToggleBox();
  });

  return (
    <div
      className={`${classes.toggleBox} ${className ? className : ""}`}
      data-target={dataTarget}
      onClick={onClickWrapper}
    >
      {/* eslint-disable-next-line  */}
      <a style={{ width: "100%" }}>
        {text}
        <span
          className={`icon is-small rev-carret ${isClosed ? "is-hidden" : ""}`}
          style={{ float: "right", verticalAlign: "bottom", display: "inline" }}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </span>
        <span
          className={`icon is-small rev-carret ${isClosed ? "" : "is-hidden"}`}
          style={{ float: "right", verticalAlign: "bottom", display: "inline" }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      </a>
    </div>
  );
};
