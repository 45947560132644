import * as pages from "./../../navigation";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
import { useEffect, useState } from "react";

const HeaderSlider = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    const timer = setTimeout(() => {
      if (
        window.innerHeight !== dimensions.height ||
        window.innerWidth !== dimensions.innerWidth
      ) {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }
      return () => clearTimeout(timer);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    bulmaCarousel.attach(".carousel", {
      slidesToShow: 1,
      slidesToScrol: 2,
      pagination: true,
      autoplay: true,
      autoplaySpeed: 6000,
      loop: true,
      breakpoints: [],
    });
  });

  const HeaderCarousel = () => {
    return (
      <div
        id="header-slider"
        className="carousel"
        style={{ overflow: "hidden" }}
      >
        {/*Slider 1 BEGIN*/}
        <section className="hero is-medium is-small-mobile rev-header-slider rev-header-slider-1">
          <div className="container hero-body center">
            <div className="content has-text-centered has-text-white">
              <h1 className="title is-1 is-size-4-mobile has-text-white has-text-weight-bold">
                Real Estate Valuator
              </h1>
              <h2 className="title is-4 is-size-6-mobile has-text-white">
                Vastgoedkennis in de pocket
              </h2>
              <button className="button is-primary is-info-light is-rounded mt-4 mr-2">
                {/* eslint-disable-next-line  */}
                <a href="#rev-abotabs" style={{ color: "white" }}>
                  Bekijk abonnementen
                </a>
              </button>

              <button className="button is-warning is-rounded mt-4 ml-2">
                <a href={pages.realEstateValuator} style={{ color: "white" }}>
                  Probeer het uit
                </a>
              </button>
              <p
                className="is-italic is-size-6 is-size-7-touch"
                style={{
                  color: "rgb(255 255 255 / 55%)",
                  paddingTop: "50px",
                }}
              >
                Duinhotel Tientorens fotografie: ©Lokerse Architecten
              </p>
            </div>
          </div>
        </section>
        {/*Slider 1 EIND*/}
        {/*Slider 2 BEGIN*/}
        <section className="hero is-medium is-small-mobile rev-header-slider rev-header-slider-2">
          <div className="container hero-body center has-text-centered">
            <div className="content has-text-white">
              <h2 className="title is-4 is-size-6-mobile has-text-white">
                Toegang in combinatie met de Vastgoed Taxatiewijzer
              </h2>
              <button className="button is-warning is-info-light is-rounded mt-4 mr-2">
                {/* eslint-disable-next-line  */}
                <a
                  href="https://www.vaoshop.nl/"
                  rel="noreferrer noopener"
                  target="blank"
                  style={{ color: "white" }}
                >
                  Meer info op VAOshop.nl
                </a>
              </button>
            </div>
          </div>
        </section>
        {/*Slider 2 EIND*/}
      </div>
    );
  };

  return <HeaderCarousel />;
};

export default HeaderSlider;
