import { Link } from "react-router-dom";

const defaultLabelClasses = "input is-static";
//const defaultPrimaryTagClasses = "tag is-primary";
const defaultSecondaryTagClasses = "tag is-primary is-light";
// https://bulma.io/documentation/form/input/
// extra class options:
// medium/large
export const DisplayLabel = ({
  label,
  text,
  className,
  addonText,
  isCompact,
}) => {
  // set bulma classes
  className = className + " normal input rev-project-readonly-input";
  return (
    <div className={`field is-horizontal ${isCompact ? "rev-is-compact" : ""}`}>
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field has-addons rev-project-input">
          <p className="control" style={{ width: "100%" }}>
            <input
              type="text"
              className={className}
              value={text || ""}
              readOnly
            />
          </p>
          {addonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{addonText}</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export const DisplayTextArea = ({ label, text, className }) => {
  // set bulma classes

  return (
    <div className={`field is-horizontal inline`}>
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control has-text-left">
            <textarea
              className="pull-left"
              defaultValue={text}
              readOnly={true}
              style={{
                borderColor: "transparent",

                resize: "none",
                marginTop: "6px",
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export const ProjectDisplayLabel = ({
  label,
  text,
  labelIsHtml,
  textIsHtml,
  textIsAnchor,
  anchorPath,
  className,
}) => {
  // set bulma classes
  const classes = className
    ? defaultLabelClasses.concat(" ", className)
    : defaultLabelClasses;

  return (
    <>
      {/* Mobiele uitwerking */}
      <div className="content mb-2 is-hidden-tablet">
        {labelIsHtml && (
          <span
            className="label has-text-left"
            style={{ marginLeft: "10px", fontSize: "16px" }}
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
        )}
        {!labelIsHtml && (
          <span
            className="label has-text-left"
            style={{ marginLeft: "10px", fontSize: "16px" }}
          >
            {label}
            {textIsHtml && (
              <span className="has-text-weight-normal ml-2">
                <span
                  className={""}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </span>
            )}
            {!textIsHtml && !textIsAnchor && (
              <span className="has-text-weight-normal ml-2">
                <label>{text}</label>
              </span>
            )}
            {textIsAnchor && (
              <span className="has-text-weight-normal ml-2">
                <Link
                  className={""}
                  to={anchorPath}
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  <u>{text}</u>
                </Link>
              </span>
            )}
          </span>
        )}
      </div>

      {/*Desktop uitwerking */}
      <div className="field is-horizontal mb-0 is-hidden-mobile">
        <div
          className="field-label is-normal rev-text-center-on-touch"
          style={{ flexGrow: "1" }}
        >
          {labelIsHtml && (
            <div
              className="label"
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          )}
          {!labelIsHtml && <div className="label">{label} </div>}
        </div>
        <div className="field-body rev-project-center">
          <div className="field">
            {textIsHtml && (
              <div className="control rev-text-center-on-touch ">
                <div
                  className={classes}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </div>
            )}
            {!textIsHtml && !textIsAnchor && (
              <div className="control rev-text-center-on-touch ">
                <input
                  type="text"
                  className={classes}
                  value={text || ""}
                  readOnly
                />
              </div>
            )}
            {textIsAnchor && (
              <div className="control rev-text-center-on-touch">
                <Link
                  className={classes}
                  to={anchorPath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>{text}</u>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const ProjectHeaderDisplayLabel = ({ label, text, className }) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className="field-label has-text-left pl-2"
        style={{ width: "50%", float: "left", color: "white" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      </div>
      <div
        className="field-body rev-project-center has-text-right"
        style={{ width: "40%", float: "left", paddingTop: "3px" }}
      >
        <div className="field">
          <p className="control rev-text-center-on-touch ">
            <label type="label" className={className}>
              {text}
            </label>
          </p>
        </div>
      </div>
    </div>
  );
};

export const SpanLabel = ({
  label,
  text,
  text2,
  className,
  onClick,
  dataTarget,
}) => {
  // set bulma classes
  const classes = className
    ? defaultSecondaryTagClasses.concat(" ", className)
    : defaultSecondaryTagClasses;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <span className={classes} onClick={onClick} data-target={dataTarget}>
          {label}
        </span>
      </div>
      <div className="field-body">
        <div className="field">
          <span>{text}</span>
          {text2 && <span>{text2}</span>}
        </div>
      </div>
    </div>
  );
};

export const SecondarySpanLabel = ({ text, text2, className }) => {
  // set bulma classes
  const classes = className
    ? defaultSecondaryTagClasses.concat(" ", className)
    : defaultSecondaryTagClasses;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <span>&nbsp;</span>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            <span className={classes}>{text}</span>
            {text2 && <span className={classes}>{text2}</span>}
          </p>
        </div>
      </div>
    </div>
  );
};
