import {
  ProjectDisplayLabel,
  ProjectHeaderDisplayLabel,
} from "../../components/common/DisplayLabel";

import { ToggleDisplayButton } from "../../components/toggleDisplayButton";
import {
  RevRoundNumber,
  BouwdeelIsAppliedOnce,
} from "../../modules/revCalculation";

const BouwdeelHandeling = ({
  bouwdeel,
  handeling,
  beheerObjectAmount,
  onChangeHandeling,
  handleDisplayStateChange,
  displayState,
  onderhoudAccess,
}) => {
  const boxId = `bouwdeelHandeling${handeling.source.id}`;

  return (
    <>
      {handeling.source.remotelyCalculated && (
        <div className="rev-folding-box-tertiary rev-toggle-box">
          <div className={`rev-box-title`}>
            <ProjectHeaderDisplayLabel
              label={`${handeling.source.element} ${handeling.source.materiaal} ${handeling.source.handeling}:`}
              text={`€ ${RevRoundNumber(handeling.total)}`}
            />
          </div>
        </div>
      )}
      {!handeling.source.remotelyCalculated && (
        <div className="rev-folding-box-tertiary rev-toggle-box">
          <ToggleDisplayButton
            dataTarget={boxId}
            className={"rev-box-title"}
            isClosed={!displayState[boxId]}
            type={"basis"}
            onClick={handleDisplayStateChange}
          >
            {
              <ProjectHeaderDisplayLabel
                label={`${handeling.source.element} ${handeling.source.materiaal} ${handeling.source.handeling}:`}
                text={`€ ${RevRoundNumber(handeling.total)}`}
              />
            }
          </ToggleDisplayButton>

          <div id={boxId} className="rev-menu-open">
            <div className="rev-project-box-internal-padding">
              {onderhoudAccess && (
                <ProjectDisplayLabel
                  label={"NL/SfB Code:"}
                  text={handeling.source.nlsfbCode}
                  anchorPath={handeling.source.cmsPath}
                  className={"has-text-link"}
                />
              )}
              {!onderhoudAccess && (
                <ProjectDisplayLabel
                  label={"NL/SfB Code:"}
                  text={handeling.source.nlsfbCode}
                />
              )}
              {handeling.source.unit && (
                <ProjectDisplayLabel
                  label={"Referentie hoeveelheid:"}
                  text={`${handeling.source.quantity} ${handeling.source.unit}`}
                  textIsHtml={true}
                />
              )}

              <ProjectDisplayLabel
                label={"Urgentie:"}
                text={handeling.source.urgency}
              />

              <ProjectDisplayLabel
                label={"Cyclus:"}
                text={handeling.source.cycle}
              />

              <ProjectDisplayLabel
                label={"Disciplines:"}
                text={handeling.source.disciplines}
              />

              {handeling.source.werkUren > 0 && (
                <ProjectDisplayLabel
                  label={"Werkuren:"}
                  text={handeling.source.werkUren}
                />
              )}

              <ProjectDisplayLabel
                label={"Kosten drager:"}
                text={handeling.source.financialOwnerString}
              />

              {handeling.bedragMateriaal > 0 && (
                <ProjectDisplayLabel
                  label={`Materiaal:`}
                  text={`€ ${RevRoundNumber(handeling.bedragMateriaal)}`}
                />
              )}
              {handeling.bedragMaterieel > 0 && (
                <ProjectDisplayLabel
                  label={`Materieel:`}
                  text={`€ ${RevRoundNumber(handeling.bedragMaterieel)}`}
                />
              )}
              {handeling.bedragOnderaannemer > 0 && (
                <ProjectDisplayLabel
                  label={`Onderaannemer:`}
                  text={`€ ${RevRoundNumber(handeling.bedragOnderaannemer)}`}
                />
              )}
              {handeling.bedragStelpost > 0 && (
                <ProjectDisplayLabel
                  label={`Stelposten:`}
                  text={`€ ${RevRoundNumber(handeling.bedragStelpost)}`}
                />
              )}
              {handeling.bedragAfwijking > 0 && (
                <ProjectDisplayLabel
                  label={`Correctie:`}
                  text={`€ ${RevRoundNumber(handeling.bedragAfwijking)}`}
                />
              )}
              {handeling.bedragArbeid > 0 && (
                <ProjectDisplayLabel
                  label={`Totaal arbeid:`}
                  text={`€ ${RevRoundNumber(handeling.bedragArbeid)}`}
                />
              )}

              <ProjectDisplayLabel
                label={`Eenheidsprijs:`}
                text={`€ ${RevRoundNumber(handeling.bedragPerEenheid)}`}
              />
              <ProjectDisplayLabel
                label={`Referentie gebouw cyclus kosten:`}
                text={`€ ${RevRoundNumber(handeling.bedragHandeling)}`}
              />

              {!BouwdeelIsAppliedOnce(bouwdeel) && (
                <>
                  <ProjectDisplayLabel
                    label={`Referentie gebouw jaarlijkse kosten:`}
                    text={`€ ${RevRoundNumber(
                      handeling.bedragJaarReservering
                    )}`}
                  />

                  <ProjectDisplayLabel
                    label={`Kosten per 1 ${
                      bouwdeel.source.unit ? bouwdeel.source.unit : ""
                    } per wooneenheid:`}
                    labelIsHtml={true}
                    text={`€ ${RevRoundNumber(
                      handeling.bedragPerJaarPerEenheidPerWooneenheid
                    )}`}
                  />
                </>
              )}
              <ProjectDisplayLabel
                label={`Kosten voor ${bouwdeel.calculationAmount} ${
                  bouwdeel.source.unit ? bouwdeel.source.unit : ""
                }:`}
                labelIsHtml={true}
                text={`€ ${RevRoundNumber(
                  handeling.bedragPerJaarPerWooneenheid
                )}`}
              />
              {handeling.vatAddition > 0 && (
                <>
                  <ProjectDisplayLabel
                    label={`Totaal BTW:`}
                    text={`€ ${RevRoundNumber(handeling.vatAddition)}`}
                  />
                  <ProjectDisplayLabel
                    label={`Totaal inclusief:`}
                    text={`€ ${RevRoundNumber(handeling.total)}`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BouwdeelHandeling;
