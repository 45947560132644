import { useParams, useLocation } from "react-router-dom";
import * as pages from "../navigation";

const getPathValue = (path) => {
  if (path) {
    return `/${path.toLowerCase()}`;
  }
  return "";
};

export const GetSearchPath = ({ search }) => {
  const { primary, secondary, tertiary, quaternary } = useParams();
  const cmsType = GetCmsType();
  return (
    getPathValue(cmsType) +
    getPathValue(primary) +
    getPathValue(secondary) +
    getPathValue(tertiary) +
    getPathValue(quaternary) +
    getPathValue(search)
  );
};

export const GetLessonPath = ({ cmsType }) => {
  const { primary, secondary, tertiary, quaternary } = useParams();

  return (
    getPathValue(cmsType) +
    getPathValue(primary) +
    getPathValue(secondary) +
    getPathValue(tertiary) +
    getPathValue(quaternary)
  );
};

export const IsCmsPath = (path) => {
  if (path) {
    if (path.startsWith(pages.kengetallen)) {
      return true;
    }
    if (pages.cmsIndexSearch.some((x) => path.includes(x))) {
      return true;
    }
  }
  return false;
};

export const GetPathParams = () => {
  const { primary, secondary, tertiary, quaternary } = useParams();
  const cmsType = GetCmsType();
  return [cmsType, primary, secondary, tertiary, quaternary];
};

export const GetBreadCrumbsPaths = ({ crumbs }) => {
  // cms path is expected in this array
  const [cmsType, primary, secondary, tertiary, quaternary] = crumbs;

  let returnarr = [`/${cmsType}`];

  if (primary) {
    returnarr.push(`/${cmsType}/${primary}`);
  }

  if (secondary) {
    returnarr.push(`/${cmsType}/${primary}/${secondary}`);
  }

  if (tertiary) {
    returnarr.push(`/${cmsType}/${primary}/${secondary}/${tertiary}`);
  }

  if (quaternary) {
    returnarr.push(
      `/${cmsType}/${primary}/${secondary}/${tertiary}/${quaternary}`
    );
  }

  return returnarr;
};

export const GetCmsType = () => {
  // oeps, safari kan dit niet aan.
  // const regex = "((?<=/)[^/]*)";
  // const location = useLocation();
  // const result = location.pathname.match(regex);
  // if (result.length > 0) {
  //   return result[0];
  // }
  // return "";

  const location = useLocation();
  let path = location.pathname;
  while (path.charAt(0) === "/") {
    path = path.substring(1);
  }
  const paths = path.split("/");
  if (paths.length > 0) {
    return paths[0];
  }
  return "";
};
