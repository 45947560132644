import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavMenu from "./NavMenu";
import { signinRedirect, signoutRedirect } from "../oidc/userService";
import * as states from "../redux/states";
import * as pages from "../navigation";

import {
  loadUserData,
  selectIsAuthorized,
  selectProfile,
  selectUserDataLoadState,
} from "../redux/slices/userSlice";

import {
  fetchCmsNavMenu,
  selectNavMenu,
  selectNavMenuState,
} from "../redux/slices/cmsNavMenuSlice";

function NavMenuManager() {
  const dispatch = useDispatch();
  const cmsMenuItems = useSelector(selectNavMenu);
  const cmsMenuState = useSelector(selectNavMenuState);
  const userData = useSelector(selectProfile);
  const profileState = useSelector(selectUserDataLoadState);
  const isAuthorized = useSelector(selectIsAuthorized);

  const preSignIn = (e) => {
    const env = process.env.REACT_APP_ENVIRONMENT;
    const expectedHost = process.env[`REACT_APP_${env}_HOST`];
    if (expectedHost !== window.location.host) {
      const redirectUrl =
        window.location.protocol + "//" + expectedHost + pages.redirectSignIn;
      window.location.href = redirectUrl;
    } else {
      signinRedirect(e);
    }
  };

  useEffect(() => {
    if (profileState === states.idle) {
      dispatch(loadUserData());
    } else if (
      cmsMenuState === states.idle &&
      profileState === states.succeeded
    ) {
      dispatch(fetchCmsNavMenu());
    }
  }, [dispatch, cmsMenuState, profileState]);

  return (
    <NavMenu
      cmsMenuItems={cmsMenuItems}
      userDataState={profileState}
      userData={userData}
      login={preSignIn}
      logout={signoutRedirect}
      isAuthorized={isAuthorized}
    />
  );
}

export default NavMenuManager;
