import ArticleCategory from "./articleCategory";
import CmsIndexNewsArticle from "./news/cmsIndexNewsArticle";

const SearchResults = ({ data }) => {
  const unaccesibleArticles = data.filter((a) => a.contentRequiresAccount);
  const articlesCurrentlyAccessible = data.filter(
    (a) => !a.contentRequiresAccount
  );

  const GeneralArticle = ({ article, contentRequiresAccount }) => {
    return (
      <div
        className={`${contentRequiresAccount ? "rev-authorized-content" : ""}`}
      >
        {article.type === 4 && (
          <div
            className="is-flex-grow-0"
            style={{ width: "670px", padding: "5px" }}
          >
            <CmsIndexNewsArticle article={article} />
          </div>
        )}
        {article.type !== 4 && (
          <div
            className="is-flex-grow-0"
            style={{ width: "335px", padding: "5px" }}
          >
            <ArticleCategory article={article} />
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-stretch">
          {articlesCurrentlyAccessible.map((article, i) => {
            return <GeneralArticle article={article} key={i} />;
          })}
        </div>
      </div>
      <br />
      <div className="container">
        <div className="columns is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-stretch">
          {unaccesibleArticles.map((article, i) => {
            return (
              <GeneralArticle
                article={article}
                key={i}
                contentRequiresAccount={true}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SearchResults;
