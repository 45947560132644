import {
  GetBreadCrumbsPaths,
  GetPathParams,
} from "../modules/revCmsNavigation";
import BreadCrumb from "./breadCrumb";

function BreadCrumbManager() {
  const paths = GetPathParams();
  const breadcrumarr = GetBreadCrumbsPaths({ crumbs: paths });
  return <BreadCrumb paths={paths} breadcrumarr={breadcrumarr} />;
}

export default BreadCrumbManager;
