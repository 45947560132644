import { DangerousDiv } from "../../components/dangerousGerneric";

const GeneralCmsPage = ({ article }) => {
  const showImage = article.image && !article.image.isThumbnail;

  return (
    <section className="section hero is-small">
      <div className="hero-body pt-0" style={{ alignItems: "start" }}></div>
      <div className="container">
        {showImage && (
          <div className="columns">
            <div className="column is-5">
              <figure className="image is-4by3">
                <img src={article.image.src} alt={article.image.altText} />
              </figure>
            </div>
            <div className="column is-6 is-offset-1">
              <h1 className="title has-text-weight-bold is-size-4-mobile is-3">
                {article.title}
              </h1>
              {(article.hoofdCategorie || article.subCategorie) && (
                <h2 className="subtitle has-text-weight-bold is-size-5-mobile is-4">
                  {article.hoofdCategorie} {article.subCategorie}
                </h2>
              )}
              <DangerousDiv
                content={
                  article.contents ? article.contents[0]?.content : undefined
                }
                className="content"
              />
            </div>
          </div>
        )}
        {!showImage && (
          <div className="columns">
            <div className="column is-12">
              <h1 className="title has-text-weight-bold is-size-4-mobile is-3">
                {article.title}
              </h1>
              {(article.hoofdCategorie || article.subCategorie) && (
                <h2 className="subtitle has-text-weight-bold is-size-5-mobile is-4">
                  {article.hoofdCategorie} {article.subCategorie}
                </h2>
              )}
              <DangerousDiv
                content={
                  article.contents ? article.contents[0]?.content : undefined
                }
                className="content mb-6"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default GeneralCmsPage;
