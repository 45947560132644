import * as states from "../states";

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../api/client";

const cmsContentAdapter = createEntityAdapter();

const initialState = cmsContentAdapter.getInitialState({
  status: states.idle,
  error: null,
});

export const fetchCmsForPath = createAsyncThunk(
  "cms/fetchforpath",
  async (path) => {
    const response = await client.get(
      `/api/cmscontent/ArticleAndSearchResults${path}`
    );
    return JSON.stringify(response.data);
  }
);

export const refreshCms = createAsyncThunk("cms/refresh", async () => {
  const response = await client.post("/api/cmscontent/refresh");
  return response.data;
});

const cmsContentSlice = createSlice({
  name: "cmsContent",
  initialState,
  reducers: {
    resetCmsContent: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCmsForPath.pending, (state, action) => {
        state.status = states.loading;
      })
      .addCase(fetchCmsForPath.fulfilled, (state, action) => {
        state.status = states.succeeded;
        // Add any fetched posts to the array
        cmsContentAdapter.upsertOne(state, JSON.parse(action.payload));
      })
      .addCase(fetchCmsForPath.rejected, (state, action) => {
        if (action.error && action.error.message) {
          const errorResult = JSON.parse(action.error.message);
          if (errorResult.status === 401) {
            state.status = states.unauthorized;
          } else if (errorResult.status === 404) {
            state.status = states.notfound;
          }
        } else {
          state.error = action.error.message;
          state.status = states.failed;
        }
      });
  },
});

export default cmsContentSlice.reducer;

export const { resetCmsContent } = cmsContentSlice.actions;

export const { selectById: selectCmsByPath } = cmsContentAdapter.getSelectors(
  (state) => state.cmsContent
);

export const selectCmsContentState = (state) => state.cmsContent.status;
