import { useState, useEffect } from "react";
import { client } from "../../api/client";
import { useSelector } from "react-redux";
import ProjectKenmerken from "./ProjectKenmerken";
import * as projectProps from "./projectConstants";
import {
  calculateHerbouwWaarde,
  recalibrateHerbouwInput,
  RevStringNumberToNumber,
  CalculateMunicipalityDependentValues,
} from "../../modules/revCalculation";

import {
  selectCalculationYears,
  updateProject,
  selectProjectBouwdelen,
  selectMunicipalityRates,
  selectWaterAuthorityRates,
  getMunicipalityRateForIdAndYear,
  TryGetPublicAddressData,
  getWaterAuthorityRatesForPostalcode,
  changeObjectYear,
} from "../../redux/slices/userProjectSlice";

import { selectmunicipalities } from "../../redux/slices/masterDataSlice";
import {
  SetCanSave,
  GetProjectImage,
  SetProjectImage,
  GetProjectServerImage,
  SetProjectServerImage,
  ClearProjectServerImage,
} from "./ProjectSession";
import {
  selectIsProjectAuthorized,
  userHasProSubscription,
} from "../../redux/slices/userSlice";

const ProjectKenmerkenManager = ({
  id,
  project,
  dispatcher,
  errors,
  setErrors,
}) => {
  const projectImage = GetProjectImage();
  const [municipalityLoading, setMunicipalityLoading] = useState(false);
  const [herbouwInputValue, setHerbouwInputValue] = useState("0");
  const [currentImagePath, setCurrentImagePath] = useState();
  const projectServerImage = GetProjectServerImage();

  // for the herbouw waarde

  useEffect(() => {
    if (project.herbouwWaardeBerekening !== undefined) {
      const herbouwWaardeInputValue = recalibrateHerbouwInput({
        herbouwWaardeBerekening: project.herbouwWaardeBerekening,
        herbouwKosten: project.herbouwKosten,
        inhoud: project.inhoud,
        bvo: project.bvo,
        herbouwKostenRate: project.herbouwKostenRate,
      });

      setHerbouwInputValue(herbouwWaardeInputValue);
      const expected = calculateHerbouwWaarde({
        herbouwWaardeBerekening: project.herbouwWaardeBerekening,
        inhoud: project.inhoud,
        bvo: project.bvo,
        herbouwKostenRate: herbouwWaardeInputValue,
      });

      if (project.herbouwKosten !== expected) {
        dispatcher(
          updateProject({
            id,
            name: projectProps.herbouwKosten,
            value: expected,
          })
        );
      }
    }
  }, [
    dispatcher,
    id,
    project.bvo,
    project.herbouwKosten,
    project.herbouwKostenRate,
    project.herbouwWaardeBerekening,
    project.inhoud,
  ]);

  const municipalityRates = useSelector(selectMunicipalityRates);
  const waterAuthorities = useSelector(selectWaterAuthorityRates);
  const calculatedValues = CalculateMunicipalityDependentValues(
    municipalityRates,
    project,
    waterAuthorities
  );

  const yearSelection = useSelector(selectCalculationYears);
  const municipalities = useSelector(selectmunicipalities);
  const objectBouwdeelIds = Object.values(useSelector(selectProjectBouwdelen))
    .filter((bouwdeel) => bouwdeel.active)
    .map((bouwdeel) => bouwdeel.id);

  const activeBouwdelen = Object.values(useSelector(selectProjectBouwdelen))
    .filter((bouwdeel) => bouwdeel.active)
    .map((bouwdeel) => bouwdeel);
  // move the retrieve to the onderhoudskosten?
  const onProjectChange = (e) => {
    const { name, value } = e.target;
    SetCanSave(true, name);
    let previousYear = project.year;
    dispatcher(updateProject({ id, name, value }));

    if (name === projectProps.postalCode) {
      dispatcher(
        getWaterAuthorityRatesForPostalcode({
          year: project.year,
          postalcode: value,
        })
      );
    }
    if (name === projectProps.year) {
      dispatcher(
        changeObjectYear({
          year: value,
          previousYear: previousYear,
          activeBouwdelen,
          objectBouwdeelIds,
          postalCode: project.postalCode,
          UserGroupBeheerObjectId: id,
          municipalityId: project.municipalityId,
          vtwCode: project.reference.vtwCode,
          loadBouwdelen: true,
        })
      );
    }
    if (name === projectProps.municipalityId) {
      dispatcher(
        getMunicipalityRateForIdAndYear({ year: project.year, id: value })
      );
    }
  };

  const onHerbouwWaardeChange = (e) => {
    const herbouwWaardeTemp = e.target.value;
    setHerbouwInputValue(e.target.value);
    const expected = calculateHerbouwWaarde({
      herbouwWaardeBerekening: project.herbouwWaardeBerekening,
      inhoud: project.inhoud,
      bvo: project.bvo,
      herbouwKostenRate: herbouwWaardeTemp,
    });

    if (RevStringNumberToNumber(project.herbouwKosten) !== expected) {
      SetCanSave(true);
      dispatcher(
        updateProject({
          id,
          name: projectProps.herbouwKosten,
          value: expected,
        })
      );
    }
  };

  const onVolumeChange = (e) => {
    // volume of a building depends on the BVO and the floor heights. With the change of these, it needs to be recaculated.
    const { name, value } = e.target;
    const calcValue = value;
    let inhoud = project.inhoud;
    let go = project.go;
    let bvo = project.bvo;
    let hoogte = project.hoogte;
    const goFactor =
      project.reference.brutoVloerOppervlak /
      project.reference.gebruiksOppervlak;

    switch (name) {
      case projectProps.bvo:
        go = Number.parseInt(calcValue / goFactor);
        dispatcher(
          updateProject({
            id,
            name: projectProps.go,
            value: go,
          })
        );

        inhoud = Number.parseInt(calcValue * hoogte);
        dispatcher(
          updateProject({
            id,
            name: projectProps.inhoud,
            value: inhoud,
          })
        );

        break;
      case projectProps.hoogte:
        inhoud = (calcValue * bvo).toFixed();
        dispatcher(
          updateProject({
            id,
            name: projectProps.inhoud,
            value: inhoud,
          })
        );

        break;
      case projectProps.go:
        bvo = Number.parseInt((calcValue * goFactor).toFixed());
        dispatcher(
          updateProject({
            id,
            name: projectProps.bvo,
            value: bvo,
          })
        );

        inhoud = Number.parseInt(bvo * hoogte).toFixed();
        dispatcher(
          updateProject({
            id,
            name: projectProps.inhoud,
            value: inhoud,
          })
        );
        break;
      default:
        break;
    }
    onProjectChange(e);
  };

  const onSelectImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let tempErrors = errors || {};
      if (file.size > 20971520) {
        tempErrors = errors || {
          "Project.image": "bestand is te groot (meer als 20mb)",
        };
        e.preventDefault();
      } else if (!["image/png", "image/jpeg"].includes(file.type)) {
        tempErrors = errors || { "Project.image": "Bestand type onjuist." };
        e.preventDefault();
      } else {
        tempErrors = errors || { "Project.image": undefined };
        SetCanSave(true);
        const newimageUrl = URL.createObjectURL(e.target.files[0]);
        setCurrentImagePath(newimageUrl);
        SetProjectImage(newimageUrl);
        ClearProjectServerImage();

        dispatcher(
          updateProject({ id: project.id, name: "imageUpdated", value: true })
        );
      }
      setErrors(tempErrors);
    }
  };

  // this is to load the image
  useEffect(() => {
    const checkForUrl = /(https:\/\/)/g;
    async function fetchData() {
      // check if the url is local
      if (!project.image.src.match(checkForUrl)) {
        // local url
        // always download
        const res = await client.downloadGet(project.image.src);
        if (res.data) {
          const imageBlob = await res.data;
          const imageObjectURL = URL.createObjectURL(imageBlob);
          SetProjectServerImage(imageObjectURL);
        }

        setCurrentImagePath(project.image.src);
      } else {
        if (currentImagePath !== project.image.src) {
          SetProjectServerImage(project.image.src);
          setCurrentImagePath(project.image.src);
        }
      }
    }

    if (project && project.image && project.image.src) {
      fetchData();
    }
  }, [project, currentImagePath]);

  const onRadioButtonBoolChange = (e) => {
    const { name, value } = e.target;
    const setVal = value.toString() === "true";
    SetCanSave(true, name);
    dispatcher(updateProject({ id, name, value: setVal }));
  };

  const toggleAutomatic = (e) => {
    const { name, value } = e.target;
    if (value.toString() === "false") {
      dispatcher(updateProject({ id, name, value: null }));
    } else {
      dispatcher(updateProject({ id, name, value: "0" }));
    }
    SetCanSave(true, name);
  };

  const onGetEnergyLabel = async (e, forceUpdateFields) => {
    setMunicipalityLoading(true);
    dispatcher(
      await TryGetPublicAddressData({
        id: project.id,
        postalCode: project.postalCode,
        houseNumber: project.houseNumber,
        year: project.year,
      })
    ).then((e) => {
      try {
        const data = JSON.parse(e.payload);
        // onVolumeChange
        if (data.epData?.go) {
          onVolumeChange({
            target: {
              name: projectProps.go,
              value: data.epData?.go.toFixed(),
            },
          });
        }
      } catch {}
      setMunicipalityLoading(false);
    });
  };

  const userIsProSubscription = useSelector(userHasProSubscription);
  const userIsAuthorized = useSelector(selectIsProjectAuthorized);
  return (
    <ProjectKenmerken
      onProjectChange={onProjectChange}
      onRadioButtonBoolChange={onRadioButtonBoolChange}
      yearSelection={yearSelection}
      project={project}
      municipalities={municipalities}
      municipalityLoading={municipalityLoading}
      onSelectImage={onSelectImage}
      onGetEnergyLabel={onGetEnergyLabel}
      projectImage={projectImage}
      projectServerImage={projectServerImage}
      errors={errors}
      onHerbouwWaardeChange={onHerbouwWaardeChange}
      herbouwWaardeInputValue={herbouwInputValue}
      onVolumeChange={onVolumeChange}
      calculatedValues={calculatedValues}
      toggleAutomatic={toggleAutomatic}
      userIsAuthorized={userIsAuthorized}
      userIsProSubscription={userIsProSubscription}
    />
  );
};

export default ProjectKenmerkenManager;
