import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
import { useEffect, useState } from "react";
import { RevImgFull } from "../../components/common/RevImg";

const HomeReview = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    const timer = setTimeout(() => {
      if (
        window.innerHeight !== dimensions.height ||
        window.innerWidth !== dimensions.innerWidth
      ) {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }
      return () => clearTimeout(timer);
    }, 100);
  };

  useEffect(() => {
    bulmaCarousel.attach(".rev-carousel", {
      navigation: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      pagination: false,
      autoplay: true,
      autoplaySpeed: 9000,
      loop: true,
      breakpoints: [],
    });

    window.addEventListener("resize", handleResize);
  });

  const ReviewCarousel = () => {
    return (
      <div
        id="carousel-demo"
        className="rev-carousel"
        style={{ overflow: "hidden" }}
      >
        <CaroselItem
          index={1}
          title={"J. Gijsbers - Stima Valuation & Advisory"}
          review={`"De VTW en REV behoren tegenwoordig tot de standaarduitrusting van de vastgoedtaxateur, mede vanwege de compleetheid en het gebruiksgemak."`}
        />
        <CaroselItem
          index={2}
          title={"Roderick"}
          review={`"Dit is voor een taxateur bijna onmisbaar. Zeker als er een object in verhuurde staat dient te worden getaxeerd, geeft dit alle mogelijke kengetallen en oplossingen. Een absolute must dus!"`}
        />
        <CaroselItem
          index={3}
          title={"Marjan Groeneveld - GNM Makelaardij"}
          review={`"Handig hanteerbaar, tijdbesparend."`}
        />
      </div>
    );
  };

  const CaroselItem = ({ index, title, review }) => {
    return (
      <section className="section is-small pt-0">
        <div className="is-max-desktop container">
          <div className={`item-${index}`}>
            <div className="box is-shadowless has-background-primary">
              <article className="media">
                <div className="media-content">
                  <div className="content has-text-white rev-text-example">
                    <p>
                      <strong className="has-text-white">{title}</strong>
                      <br />
                      {review}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="rev-responsive">
      <section className="section">
        <div className="container box is-shadowless is-fullwidth has-background-link-light">
          <h2 className="title is-size-4 has-text-primary has-text-weight-bold rev-subtitle-responsive-font">
            Gebruikers van REV®
          </h2>
          <div className="columns is-tablet has-text-centered is-vcentered rev-center-contents">
            <div className="column">
              <RevImgFull
                src="/images/rijksoverheid.svg"
                alt="Logo van Rijksoverheid met beeldmerk"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/logo-vng.svg"
                alt="Logo van VNG"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/ortec-finance-logo.svg"
                alt="Logo van Ortec Finance "
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/logo-flux.png"
                alt="Logo van Flux"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/logo-cushman.svg"
                alt="Logo van Cushman & Wakefield"
                className="image revlogoimg"
              />
            </div>
          </div>
          <div className="columns is-tablet has-text-centered is-vcentered rev-center-contents">
            <div className="column">
              <RevImgFull
                src="/images/woningstichting-havensteder-logo.svg"
                alt="Logo van Havensteder"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/logo-rochdale.png"
                alt="Logo van Rochdale"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/talis-logo.svg"
                alt="Logo van Talis"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <RevImgFull
                src="/images/wonen-breburg-logo.png"
                alt="Logo van Breburg"
                className="image revlogoimg"
              />
            </div>
            <div className="column">
              <h3 className="content">
                <span className="has-text-weight-bold">+</span> nog 3.000 meer!
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-small pt-0">
        <div className="is-max-desktop container">
          <h2 className="title is-size-4 has-text-primary has-text-centered has-text-weight-bold rev-subtitle-responsive-font">
            Reviews van gebruikers
          </h2>
        </div>
      </section>
      <ReviewCarousel />
    </div>
  );
};

export default HomeReview;
