import { useEffect, useState } from "react";
import * as events from "../../custom-scripts/custom-javascript-events";
import { useSelector } from "react-redux";
import * as states from "../../redux/states";
import SearchBar from "../../components/searchBar";
import { useParams } from "react-router";
import {
  fetchCmsContentFilters,
  fetchCmsContentIndexScroll,
  selectCmsContentCategoriesByType,
  selectCmsContentCategoriesState,
  selectCmsContentIndexByType,
  selectCmsContentIndexFilterByType,
  selectCmsContentIndexStateByType,
} from "../../redux/slices/cmsTypeContentIndexSlice";
import { selectIsAuthorized } from "../../redux/slices/userSlice";
import Loader from "../../components/loader";
import KengetallenContent from "./kengetallenContent";
import useInfiniteScroll from "react-infinite-scroll-hook";
import KengetallenScrollPage from "./kengetallenScrollPage";
import RevHeader from "../../components/header";
import BreadCrumbManager from "../../components/breadCrumbManager";

const KengetallenSelectionManager = ({ dispatcher }) => {
  const cmsType = "kengetallen";
  const categoryState = useSelector(selectCmsContentCategoriesState);
  const ready = categoryState === states.completed;
  const { primary } = useParams();

  useEffect(() => {
    if (categoryState === states.idle) {
      dispatcher(fetchCmsContentFilters());
    }
  }, [categoryState, dispatcher]);

  useEffect(() => {
    const customEventNoCopy = new Event(events.eventPreventCopyAmounts);
    document.dispatchEvent(customEventNoCopy);
  });

  const searchOnChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setFilter(lowerCase);
    setState(states.searchChanged);
  };

  const [filter, setFilter] = useState(
    useSelector((state) => {
      return selectCmsContentIndexFilterByType(state, cmsType);
    })
  );

  const tempstate = useSelector((state) => {
    return selectCmsContentIndexStateByType(state, cmsType);
  });

  const [state, setState] = useState(tempstate);
  const index = useSelector((state) => {
    return selectCmsContentIndexByType(state, cmsType);
  });

  const [categoryList, setCategories] = useState();
  const tempCategories = useSelector((state) => {
    return selectCmsContentCategoriesByType(state, cmsType);
  });

  useEffect(() => {
    if (ready && !categoryList) {
      // create deep copy to unscrew the redux shizzle
      const newCategories = JSON.parse(JSON.stringify(tempCategories));
      if (primary) {
        const index = newCategories.findIndex((obj) => obj.name === primary);
        newCategories[index] = { name: primary, value: true };
      }
      setCategories(newCategories);
    }
    if (state !== tempstate) {
      setState(tempstate);
    }
  }, [state, tempstate, tempCategories, ready, categoryList, filter, primary]);

  const filterListOnChange = (e) => {
    setFilter("");
    const index = categoryList.findIndex((obj) => obj.name === e.target.text);
    let tempFilters = categoryList.map((e) => ({ ...e, value: false }));
    let oldValue = categoryList[index];
    tempFilters[index] = { name: oldValue.name, value: !oldValue.value };
    setCategories(tempFilters);
    setState(states.searchChanged);
  };

  // hier moet je de categoryList aanpassen

  useEffect(() => {
    if (state === states.idle) {
      dispatcher(
        fetchCmsContentIndexScroll({
          cmsType: cmsType,
          filter: filter,
          categories: categoryList,
          continueFromCurrentRecord: state !== states.searchChanged,
        })
      );
    } else if (state === states.searchChanged) {
      const timer = setTimeout(() => {
        dispatcher(
          fetchCmsContentIndexScroll({
            cmsType: cmsType,
            filter: filter,
            categories: categoryList,
            continueFromCurrentRecord: false,
          })
        ).then(() => {
          return () => clearTimeout(timer);
        });
      }, 600);
    }
  }, [dispatcher, index, filter, state, categoryList]);

  // add infinity loading page functions
  const loadMore = () => {
    dispatcher(
      fetchCmsContentIndexScroll({
        cmsType: cmsType,
        filter: filter,
        categories: categoryList,
        continueFromCurrentRecord: state !== states.searchChanged,
      })
    );
  };

  const isAuthorized = useSelector(selectIsAuthorized);
  const loading = state === states.loading;
  const hasNextPage = state !== states.completed;
  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    error: state === states.failed,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      {primary && <BreadCrumbManager />}
      <RevHeader title={"Kengetallen"} />
      <SearchBar searchOnChange={searchOnChange} startValue={filter} />
      {ready && categoryList && !primary && (
        <KengetallenContent
          categories={categoryList}
          filterListOnChange={filterListOnChange}
        />
      )}
      {!ready && <Loader center={true} />}
      {ready && index && (
        <KengetallenScrollPage
          sentryRef={sentryRef}
          loading={loading}
          hasNextPage={hasNextPage}
          articles={index}
          categories={categoryList}
          filterListOnChange={filterListOnChange}
          isAuthorized={isAuthorized}
        />
      )}
    </>
  );
};

export default KengetallenSelectionManager;
