import { useEffect, useState } from "react";
import FetchCmsLink from "./fetchCmsLink";
import { GetSearchPath, IsCmsPath } from "../../modules/revCmsNavigation";
import { useSelector } from "react-redux";

import {
  selectCmsByPath,
  fetchCmsForPath,
  selectCmsContentState,
} from "../../redux/slices/cmsSearchDataSlice";
import { selectIsAuthorized } from "../../redux/slices/userSlice";

const FetchCmsLinkManager = ({ dispatcher }) => {
  const [filter, setFilter] = useState("");
  const state = useSelector(selectCmsContentState);
  const isAuthorized = useSelector(selectIsAuthorized);

  const searchOnChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setFilter(lowerCase);
  };

  const querystring = GetSearchPath({ search: filter });
  const result = useSelector((state) => {
    if (IsCmsPath(querystring)) {
      return selectCmsByPath(state, querystring);
    }
  });

  useEffect(() => {
    if (!result && IsCmsPath(querystring)) {
      dispatcher(fetchCmsForPath(querystring));
    }
  }, [dispatcher, querystring, result]);

  return (
    <FetchCmsLink
      state={state}
      result={result}
      searchOnChange={searchOnChange}
      isAuthorized={isAuthorized}
    />
  );
};

export default FetchCmsLinkManager;
