import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as states from "../../redux/states";

import {
  loadUserData,
  selectProfile,
  selectUserDataLoadState,
  selectProjectSubscription,
  userApiKey,
  selectCmsAccess,
  selectUserHas2FA,
  userHasProSubscription,
  getAdditionalUserData,
  selectUserLogo,
  addUserLogo,
  selectUserEndDate,
} from "../../redux/slices/userSlice";

import ProfilePage from "./profilePage";
import { client } from "../../api/client";

const ProfilePageManager = () => {
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const profileState = useSelector(selectUserDataLoadState);
  const [userLogo, setUserLogo] = useState();
  useEffect(() => {
    if (profileState === states.idle) {
      dispatch(loadUserData());
    } else if (!isUserDataLoaded) {
      setIsUserDataLoaded(true);
      dispatch(getAdditionalUserData());
    }
  }, [dispatch, profileState, isUserDataLoaded]);

  const userData = useSelector(selectProfile);
  const apiKey = useSelector(userApiKey);
  const subscription = useSelector(selectProjectSubscription);
  const cmsRoles = useSelector(selectCmsAccess);
  const has2FA = useSelector(selectUserHas2FA);
  const hasPro = useSelector(userHasProSubscription);
  const userLogoPath = useSelector(selectUserLogo);
  const endDate = useSelector(selectUserEndDate);

  const onSelectImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let tempErrors = errors || {};
      if (file.size > 1048576) {
        tempErrors = errors || {
          "Project.image": "bestand is te groot (meer als 1mb)",
        };
        e.preventDefault();
      } else if (!["image/png", "image/jpeg"].includes(file.type)) {
        tempErrors = errors || { "Project.image": "Bestand type onjuist." };
        e.preventDefault();
      } else {
        tempErrors = errors || { "Project.image": undefined };

        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            dispatch(
              addUserLogo({
                fileName: file.name,
                mimeType: file.type,
                imageData: reader.result,
              })
            ).then(() => {
              setIsUserDataLoaded(false);
            });
          };

          reader.readAsDataURL(file);
        }
      }

      setErrors(tempErrors);
    }
  };

  // this is to load the image
  useEffect(() => {
    const checkForUrl = /(https:\/\/)/g;
    async function fetchData() {
      // check if the url is local
      if (!userLogoPath.match(checkForUrl)) {
        // local url
        // always download
        const res = await client.downloadGet(userLogoPath);
        if (res.data) {
          const imageBlob = await res.data;
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setUserLogo(imageObjectURL);
        }
      }
    }

    if (userLogoPath) {
      fetchData();
    }
  }, [userLogoPath]);

  return (
    <ProfilePage
      userDataState={profileState}
      userData={userData}
      apiKey={apiKey}
      cmsRoles={cmsRoles}
      subscription={subscription}
      has2FA={has2FA}
      hasPro={hasPro}
      userLogo={userLogo}
      errors={errors}
      endDate={endDate}
      onSelectImage={onSelectImage}
    />
  );
};

export default ProfilePageManager;
