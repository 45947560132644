import CookieConsent from "react-cookie-consent";
import { AnchorButton, RevEmptyLink } from "./common/Buttons";
import * as pages from "../navigation";
import Cookies from "js-cookie";

import { useDispatch, useSelector } from "react-redux";
import {
  setCookiesRead,
  selectCookieMessageRead,
  selectIsAuthorized,
} from "../redux/slices/userSlice";
import { useLocation } from "react-router";
import { signinRedirect } from "../oidc/userService";

const consentCookie = "revCookieConsent";
const recommendCookie = "recommendRevCookie";

// The cookies class is a javascript class, if cookie manipulation is needed, this will not do.

export const CookieConsentQuestion = () => {
  const location = useLocation();
  const dispatcher = useDispatch();
  const pagesToShowOn = [
    pages.bouwgebreken,
    pages.bouwgebrekenbouwkundig,
    pages.bouwgebrekeninstallaties,
    pages.kennisbank,
    pages.all,
    pages.lesson,
  ];
  const isRead =
    useSelector(selectCookieMessageRead) || Cookies.get(consentCookie);
  const isAuthorized = useSelector(selectIsAuthorized);

  const onAccept = () => {
    dispatcher(setCookiesRead());
  };

  return (
    <div className="has-text-centered">
      <CookieConsent
        location="bottom"
        buttonText="Accepteer"
        cookieName={consentCookie}
        style={{
          background: "rgb(38 49 53 / 89%)",
          width: "100%",
          display: "block",
        }}
        buttonStyle={{
          backgroundColor: "rgb(255 140 0)",
          color: "white",
          fontSize: "18px",
          fontWeight: "400",
          padding: "15px",
          borderRadius: "5px",
        }}
        onAccept={onAccept}
        expires={150}
        sameSite="lax"
      >
        <div>
          We gebruiken cookies om ervoor te zorgen dat we u de beste ervaring op
          onze website kunnen bieden. <br></br> Als u doorgaat met het gebruiken
          van de website, gaan we er vanuit dat u hiermee instemt. Bekijk ons{" "}
          <RevEmptyLink path={pages.cookies} text={"cookiebeleid."} />
        </div>
      </CookieConsent>
      {/* pagesToShowOn.find((waarde) => { location.pathname.startsWith(waarde)}).length >0 */}

      {isRead &&
        !isAuthorized &&
        pagesToShowOn.find((waarde) => {
          return location.pathname.startsWith(waarde);
        }) && (
          <CookieConsent
            location="bottom"
            buttonText=""
            cookieName={recommendCookie}
            buttonClasses={"delete rev-cookie-delete"}
            style={{
              background: "#646c56",
              width: "100%",
              display: "block",
            }}
            buttonStyle={{ padding: "0" }}
            expires={7}
            sameSite="lax"
            disableButtonStyles={true}
          >
            <div
              className="notification rev-abo-melding"
              style={{ backgroundColor: "#646c56" }}
            >
              <div className="content">
                U heeft een abonnement nodig om alle informatie in te zien.
                <br></br>
                Al abonnee? {""}
                {/* // signinRedirect */}
                <AnchorButton
                  className={"content has-text-white is-underlined"}
                  text={"Log in."}
                  onClick={signinRedirect}
                />
              </div>
              <a
                className="button"
                style={{
                  color: "white",
                  backgroundColor: "#f39300",
                  border: "0px",
                }}
                href={"https://www.vaoshop.nl/abonnement"}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Meer info op VAOshop.nl
              </a>
            </div>
          </CookieConsent>
        )}
    </div>
  );
};
