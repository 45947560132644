import { useEffect } from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";
const isAcc = process.env.REACT_APP_ENVIRONMENT === "ACC";

const gaOptions = {
  cookie_flags: "SameSite=None;Secure",
};

const gtagOptions = {
  cookie_flags: "SameSite=None;Secure",
};

const initHotjar = () => {
    const options = {
        id: 5190019,
        sv: 6,
        nonce: "8IBTHwOdqNKAWeKl7plt8g==",
    };
    hotjar.initialize(options);
}

export const IntitializeAnalytics = () => {
  if (isProd) {
    const TRACKING_ID = "G-4WN887XH55"; // OUR_TRACKING_ID
    ReactGA.initialize([
      {
        trackingId: TRACKING_ID,
        gaOptions: gaOptions,
        gtagOptions: gtagOptions,
      },
    ]);

    initHotjar();
  }

  if (isAcc) {
    const TRACKING_ID = "G-91ES312BTX"; // OUR_TRACKING_ID
    ReactGA.initialize([
      {
        trackingId: TRACKING_ID,
        gaOptions: gaOptions,
        gtagOptions: gtagOptions,
      },
    ]);

    initHotjar()
    }
};
export const VisualAnalytics = () => {
  const customStyle = {
    visibility: {
      desktop: {
        xOffset: "0",
        yOffset: "200",
        position: "cr",
      },

      mobile: {
        xOffset: "0",
        yOffset: "150",
        position: "bl",
      },
    },
  };

  return (
    <>
      {isProd && (
        <TawkMessengerReact
          propertyId="64a3d5c994cf5d49dc61699d"
          widgetId="1h4fvm7ea"
          customStyle={customStyle}
        />
      )}
    </>
  );
};

export const SendArticlePage = (data) => {
  useEffect(() => {
    if (isProd || isAcc) {
      if (data) {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: data.title,
        });
      }
    }
  }, [data]);
};
