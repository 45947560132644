import * as states from "../states";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAsync } from "../../oidc/userService";
import { client } from "../../api/client";

// rolen
const business = "Business";
const pro = "Pro";
const basis = "Basic";
const onbekend = "Onbekend";
const onderhoudsKosten = "OnderhoudsKosten";
const kennisbank = "Kennisbank";
const bouwgebreken = "Bouwgebreken";
const api = "Api";

const initialState = {
  profile: null,
  token: "",
  status: states.idle,
  error: null,
  isAuthorized: false,
  isProjectAuthorized: false,
  userHas2FA: "",
  userLogo: undefined,
  cookieMessagesRead: false,
};

// Thunks!
export const loadUserData = createAsyncThunk("getuserasync", async () => {
  let response;
  await getUserAsync()
    .then((x) => {
      response = JSON.stringify(x);
    })
    .catch((e) => {
      console.error(e);
    });
  return response;
});

//addUserLogo
export const addUserLogo = createAsyncThunk(
  "addUserLogo",
  async (imageData) => {
    const response = await client.post(`/api/user/addUserLogo`, imageData);
    return JSON.stringify(response.data || "{}");
  }
);

export const getAdditionalUserData = createAsyncThunk(
  "GetAdditionalUserData",
  async () => {
    const response = await client.get(`/api/user/GetAdditionalUserData`);
    return JSON.stringify(response.data || "{}");
  }
);

export const requestMJOPForComplex = createAsyncThunk(
  "requestMJOPForComplex",
  async (payload) => {
    const response = await client.post(
      `/api/user/requestMjopForComplex`,
      payload
    );
    return JSON.stringify(response.data || "{}");
  }
);

const clearUserDataFunc = (state, message) => {
  state.status = states.failed;
  state.profile = null;
  state.token = "";
  state.isAuthorized = false;
  state.isProjectAuthorized = false;
  state.error = message;
  state.has2FA = "";
};

const setUserDataFunc = (state, data) => {
  state.status = states.succeeded;
  state.profile = data?.profile ?? null;
  state.token = data?.access_token ?? "";
  state.isAuthorized = state.token.length > 0;
  state.isProjectAuthorized = checkProfileForAdminAccess(state.profile);
  if (!state.isProjectAuthorized) {
    const roles = state.profile?.rev_role;
    if (roles) {
      state.isProjectAuthorized =
        roles.includes(business) ||
        roles.includes(pro) ||
        roles.includes(basis);
    }
  }
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setCookiesRead(state) {
      state.cookieMessagesRead = true;
    },
    setUserData(state, action) {
      var data = JSON.parse(action.payload);
      setUserDataFunc(state, data);
    },
    clearUserData(state, _) {
      clearUserDataFunc(state);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadUserData.pending, (state, action) => {
        clearUserDataFunc(state);
        state.status = states.loading;
      })
      .addCase(loadUserData.fulfilled, (state, action) => {
        var data = JSON.parse(action.payload);
        if (state.status !== states.succeeded) {
          setUserDataFunc(state, data);
        }
      })
      .addCase(loadUserData.rejected, (state, action) => {
        clearUserDataFunc(state, action.error.message);
      })
      .addCase(addUserLogo.fulfilled, (state, action) => {
        state.userLogo = action.fileName;
      })
      .addCase(requestMJOPForComplex.rejected, (state, action) => {})
      .addCase(requestMJOPForComplex.fulfilled, (state, action) => {})
      .addCase(getAdditionalUserData.fulfilled, (state, action) => {
        var data = JSON.parse(action.payload);
        state.has2FA = data.has2FA;
        state.userLogo = data.userLogo;
        state.endDate = data.endDate;
      });
  },
});

export const { setCookiesRead, setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;

export const selectUserDataLoadState = (state) => state.userData.status;

export const selectProfile = (state) => state.userData.profile;

export const selectUserHas2FA = (state) => state.userData.has2FA;

export const selectUserEndDate = (state) => state.userData.endDate;

export const selectCookieMessageRead = (state) =>
  state.userData.cookieMessagesRead;
export const selectUserLogo = (state) => state.userData.userLogo;

export const selectBearerToken = (state) => state.userData.token;

export const selectIsProjectAuthorized = (state) =>
  state.userData.isProjectAuthorized;

export const selectIsAuthorized = (state) => state.userData.isAuthorized;

// deze functionaliteit veranderd als de rechten functionaliteit veranderd
export const selectProjectSubscription = (state) => {
  if (state.userData.profile && state.userData.profile !== null) {
    let roles = state.userData.profile.rev_role;
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    if (roles) {
      if (roles.includes(pro)) {
        return pro;
      }

      if (roles.includes(business)) {
        return business;
      }

      if (roles.includes(basis)) {
        return "Basis";
      }
    }
  }
  return onbekend;
};

const checkProfileForAdminAccess = (profile) => {
  if (profile && profile !== null) {
    return profile.length > 0 || profile.admin_access;
  }
  return false;
};

const hasAdminAccess = (state) => {
  const profile = state.userData.profile;
  return checkProfileForAdminAccess(profile);
};

export const hasOnderhoudAccess = (state) => {
  if (hasAdminAccess(state)) {
    return true;
  }

  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles) {
      return roles.includes(onderhoudsKosten);
    }
  }
  return false;
};

export const selectCmsAccess = (state) => {
  let cmsAccess = [onderhoudsKosten, bouwgebreken, kennisbank];
  const projecten = "Projecten";
  const projectenArr = [basis, business, pro];
  if (hasAdminAccess(state)) {
    cmsAccess.push(projecten);
    return cmsAccess;
  }

  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles) {
      if (Array.isArray(roles)) {
        let returnVal = roles.filter((x) => cmsAccess.includes(x));
        const projectResult = roles.filter((x) => projectenArr.includes(x));
        if (projectResult.length > 0) {
          returnVal.push(projecten);
        }
        return returnVal;
      }
      // role could be singular
      if (cmsAccess.includes(roles)) {
        return [roles];
      }

      if (projectenArr.includes(roles)) {
        return [projecten];
      }
    }
  }
  return [];
};

export const userApiKey = (state) => {
  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles && roles.includes(api)) {
      return state.userData.profile.rev_userid;
    }
  }
  return undefined;
};

export const userHasBasicSubscription = (state) => {
  if (hasAdminAccess(state)) {
    return true;
  }

  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles) {
      return (
        roles.includes(business) || roles.includes(basis) || roles.includes(pro)
      );
    }
  }

  return false;
};

export const userHasBusinessSubscription = (state) => {
  if (hasAdminAccess(state)) {
    return true;
  }

  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles) {
      return roles.includes(business) || roles.includes(pro);
    }
  }

  return false;
};

export const userHasProSubscription = (state) => {
  if (hasAdminAccess(state)) {
    return true;
  }

  if (state.userData.profile && state.userData.profile !== null) {
    const roles = state.userData.profile.rev_role;
    if (roles) {
      return roles.includes(pro);
    }
  }

  return false;
};
