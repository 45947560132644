import * as states from "../states";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  beheerObjecten: [],
  objectSoorten: [],
  objectTypen: [],
  status: states.idle,
  error: null,
};

// Thunks!
export const loadVtwData = createAsyncThunk("loadvtwdata", async () => {
  const response = await client.get(`/api/vtw/stamdata`);
  return JSON.stringify(response.data);
});

const vtwStamSlice = createSlice({
  name: "vtwStamData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadVtwData.pending, (state, action) => {
        if (state.status !== states.failedonce) {
          state.status = states.loading;
        }
      })
      .addCase(loadVtwData.fulfilled, (state, action) => {
        state.status = states.succeeded;
        var data = JSON.parse(action.payload);
        state.beheerObjecten = data.beheerObjecten;
        state.objectTypen = data.objectTypen;
        state.objectSoorten = data.objectSoorten;
      })
      .addCase(loadVtwData.rejected, (state, action) => {
        if (state.status === states.failedonce) {
          state.status = states.failed;
        } else {
          state.status = states.failedonce;
        }
        state.error = action.error.message;
      });
  },
});

export default vtwStamSlice.reducer;

export const selectVtwLoadStatus = (state) => state.vtwStamData.status;

export const selectBeheerObjecten = (state) => state.vtwStamData.beheerObjecten;

export const selectBeheerObjectenByKey = (state, id) =>
  state.vtwStamData.beheerObjecten.find((soort) => soort.id === id);

export const selectObjectTypen = (state) => state.vtwStamData.objectTypen;

export const selectObjectSoorten = (state) => state.vtwStamData.objectSoorten;

export const selectObjectSoortByKey = (state, id) => {
  return state.vtwStamData.objectSoorten.find((soort) => soort.id === id);
};
