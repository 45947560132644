import { DangerousDiv } from "../../components/dangerousGerneric";
import * as classes from "../../custom-scripts/custom-javascript-classes";
function ArticleNews({ article }) {
  return (
    <>
      <section className="hero is-small">
        <div className="hero-body pt-0" style={{ alignItems: "start" }}>
          <div className="container">
            <h1 className="title is-size-4-mobile is-3 is-flex-touch has-text-weight-bold">
              {article.title}
            </h1>
            {article.subTitle && (
              <h2 className="subtitle is-size-5-mobile is-4 is-flex-touch has-text-weight-bold">
                {article.subTitle}
              </h2>
            )}

            <figure
              className={classes.image}
              style={{ float: "left", paddingRight: "20px", maxWidth: "400px" }}
            >
              <img src={article.image.src} alt={article.image.altText} />
            </figure>
            <DangerousDiv
              content={
                article.contents ? article.contents[0]?.content : undefined
              }
              className="content"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ArticleNews;

// https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/cards.html
