import { useLocation } from "react-router";
import NavMenuManager from "../navbar/NavMenuManager";
import Footer from "./footer";
import { useEffect, useState } from "react";
import RevScrollToTop from "./scrollToTop";
import * as pages from "../navigation";

function Layout(props) {
  const paths = [
    pages.newproject,
    pages.userProjectsPage,
    pages.projectPage,
    pages.lesson,
    pages.kengetallen,
    pages.kennisbank,
    pages.bouwgebreken,
    pages.home,
    pages.all,
    pages.nieuws,
  ];

  const scrollElementId = "layout-scroll";

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const scrollToTop = () => {
    document
      .getElementById(scrollElementId)
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  // always scroll to top
  useEffect(() => {
    setTimeout(function () {
      scrollToTop();
      //window.scrollTo(0, 0);
    }, 200);
  }, [path]);

  const onScroll = () => {
    const element = document.getElementById(scrollElementId);
    if (element.scrollTop > 20 && !showScrollToTop) {
      setShowScrollToTop(true);
    } else if (element.scrollTop < 20 && showScrollToTop) {
      setShowScrollToTop(false);
    }
  };

  const isPageWithScroll = paths.find((x) => path.includes(x));

  return (
    <div
      id={scrollElementId}
      style={{ height: "100vh", overflowY: "scroll" }}
      onScroll={isPageWithScroll ? onScroll : undefined}
    >
      <NavMenuManager />
      <div className="hero is-fullheight">
        {props.children}
        <Footer />
        {isPageWithScroll && showScrollToTop && (
          <RevScrollToTop scrollToTopEvent={scrollToTop} />
        )}
      </div>
    </div>
  );
}

export default Layout;
