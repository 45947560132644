import { Helmet } from "react-helmet-async";

const RevHelmet = ({ data }) => {
  // type 4 = nieuws
  const defaultAfbeelding = `${window.location.protocol}//${window.location.hostname}/images/rev-real-estate-valuator-webapp.jpg`;

  const alt = data?.image
    ? data.image.alt
    : "Met myREV als online tool kunt u snel en deskundig de exploitatiekosten van uw vastgoed berekenen";

  // const mime = data.image.mime ? data.image.mime : "image/jpeg";
  const ogType = data?.cmsType === "nieuws" ? "article" : "website";
  const imageUrl = data?.image ? data.image.src : defaultAfbeelding;

  if (data) {
    var metaTags = document.head.querySelectorAll('[data-type="static"]');
    metaTags.forEach((x) => document.head.removeChild(x));
  }

  return (
    <>
      {data && (
        <Helmet>
          <title>{data.title}</title>
          {/* standaard */}
          {data.publishedDate ? (
            <meta name="last-updated" content={data.publishedDate} />
          ) : (
            ""
          )}
          {data.metaTag && <meta name="keywords" content={data.metaTag} />}
          {/* open graph */}

          <meta name="type" property="og:type" content={ogType}></meta>
          <meta
            name="title"
            property="og:title"
            content={data.metaTitle}
          ></meta>
          <meta
            name="description"
            property="og:description"
            content={data.metaDescription}
          ></meta>
          <meta name="image" property="og:image" content={imageUrl}></meta>
          <meta property="og:image:alt" content={alt}></meta>
          {/* <meta property="og:image:mime" content={mime} /> */}
          <meta property="og:image:width" content="1200"></meta>
          <meta property="og:image:height" content="350"></meta>
          <meta property="og:site_name" content="myREV"></meta>
          <meta property="og:locale" content="nl_NL"></meta>
          <meta property="og:url" content={window.location.href}></meta>
          {data.canonical && <link rel="canonical" href={data.canonical} />}
        </Helmet>
      )}
    </>
  );
};

export default RevHelmet;
