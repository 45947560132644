import { useState } from "react";
import { NumberInputRekenhulp } from "../../components/common/NumberInput";
import { SelectInputRowRekenhulp } from "../../components/common/SelectInput";
import { useLocation } from "react-router";
import { RevNumberToString } from "../../modules/revCalculation";
import {
  updateHeatPumpSustanibilityProperties,
  pompSoorten,
} from "./revEnergyCalculations";
import { CalculationResult, DisplayRow, DisplayRowLabel } from "./components";

function HybridHeatPump() {
  const location = useLocation();
  const startLabel = "C";
  const pompNaam = "pompNaam";

  const defaultProjectProperties = {
    typeWarmtepomp: 1,
    GO: 100,
    KetelRendement: 95,
    totaalGas: 2040,
    totaalGasKoken: 40,
    kostenGas: 1.5,
    kostenElectra: 0.35,
    factorGasElectra: 9.27, //kw/m3
    pompNaam: "Lucht-water 4kw",
    energyLabel: startLabel,
    pompCycle: 15,
    pompBesparing: 60,
    pompRendement: 400,
    pompKosten: 6000,
    subsidy: 2400,
    bewoners: 4,
  };
  const [project, setProject] = useState(
    updateHeatPumpSustanibilityProperties(defaultProjectProperties)
  );

  const handleChange = (e) => {
    let updatedValue = {};
    updatedValue[e.target.name] = e.target.value;
    const newProjectValues = updateHeatPumpSustanibilityProperties(
      {
        ...project,
        ...updatedValue,
      },
      e.target.name === pompNaam
    );

    setProject(newProjectValues);
  };

  const pomp = pompSoorten.find(
    (x) => Number(x.id) === Number(project.typeWarmtepomp)
  );

  const TableDisplay = () => {
    return (
      <div className="card-content" style={{ padding: "20px 0px" }}>
        <table
          width="100%"
          style={{
            tableLayout: "fixed",
          }}
        >
          <tbody>
            <DisplayRow
              left={"Type warmtepomp"}
              right={`${pomp.name}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Gebruikersoppervlakte woning (GO)"}
              right={`${project.GO} m² GO`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Aantal bewoners"}
              right={`${project.bewoners} personen`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Gasverbruik totaal"}
              right={`${project.totaalGas} m³ gas/jaar`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Gasverbruik koken"}
              right={`${project.totaalGasKoken} m³ gas/jaar`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Gasverbruik verwarmen en warm water"}
              right={`${project.totaalGasVerwarmen} m³ gas/jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Kosten gasverbruik"}
              right={`€ ${RevNumberToString(project.kostenGas)} per m³`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Kosten elektraverbruik"}
              right={`€ ${RevNumberToString(project.kostenElectra)} per kWh`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Totaal kosten verwarming en warm water"}
              right={`€ ${RevNumberToString(
                project.totaalKostenVervarmingEnWarmWater
              )} per jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Omrekeningsfactor gas naar opgewekt vermogen"}
              right={`${RevNumberToString(project.factorGasElectra)} kWh/m³`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Totaal energie verwarming en warm water"}
              right={`${RevNumberToString(
                project.totaalEnergieVervarmingEnWarmWater
              )} kWh/jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Besparing gas"}
              right={`${RevNumberToString(project.pompBesparing)} %`}
              color={"#8E8E8E"}
            />
            <DisplayRow
              left={"Besparing gas per jaar"}
              right={`${RevNumberToString(project.besparingGasPerjaar)} m³`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Besparing energie verwarming"}
              right={`${RevNumberToString(
                project.besparingEnergiePerJaar
              )} kWh/jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Gebruik elektriciteit warmtepomp"}
              right={`${RevNumberToString(
                project.electriciteitPompGebruik
              )} kWh/GO/jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Kosten elektriciteit warmtepomp"}
              right={`${RevNumberToString(
                project.electriciteitPompKosten
              )} kWh/GO/jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Besparingenergie per GO"}
              right={`${RevNumberToString(
                project.energiebesparing
              )} kWh/GO/jaar`}
              color={"#195B2D"}
            />
            <DisplayRow
              left={"Kosten warmtepomp"}
              right={`€ ${project.pompKosten} incl. btw`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Subsidie"}
              right={`€ ${project.subsidy}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Werkelijke kosten pomp"}
              right={`€ ${project.pompKostenNaSubsidie}`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Levensduur warmtepomp"}
              right={`${RevNumberToString(project.pompCycle)} jaar`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Rendement warmtepomp"}
              right={`${RevNumberToString(project.pompRendement)} %`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Minimaal benodigd vermogen warmtepomp"}
              right={`${RevNumberToString(project.requiredCapacity)} kW`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Besparing gas"}
              right={`€ ${RevNumberToString(
                project.besparingKostenGasPerjaar
              )} per jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Netto besparing energiekosten"}
              right={`€ ${project.nettoBesparingEnergieKosten}`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Afschrijving per jaar"}
              right={`${project.afschrijvingPerJaar}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Netto besparing totaal"}
              right={`€ ${project.nettoBesparingTotaal} p.j.`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Terugverdientijd"}
              right={`${project.terugverdientijd} jaar`}
              color={"#195B2D"}
            />

            <DisplayRow
              left={"Opmerking"}
              right={`${project.opmerking}`}
              color={"#195B2D"}
            />

            <DisplayRowLabel
              left={"Huidig energielabel"}
              label={`${project.energyLabel}`}
              color={"#8E8E8E"}
            />

            <DisplayRow
              left={"Huidig EP2"}
              right={`${RevNumberToString(
                project.huidigEp2.toFixed(2)
              )} kWh/m² GO jaar`}
              color={"#195B2D"}
              simpleTooltipText={"Primair fossiel energiegebruik"}
            />

            <DisplayRow
              left={"Vermindering EP2"}
              right={`${RevNumberToString(
                project.nieuwEp2.toFixed(2)
              )} kWh/m² GO jaar`}
              color={"#195B2D"}
            />
            <DisplayRowLabel
              left={"Nieuw energielabel"}
              label={`${project.newEnergyLabel}`}
              color={"#195B2D"}
            />
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <section className="is-small pb-6" style={{ backgroundColor: "#D4E3D9" }}>
      <div className="container">
        <h1 className="title is-size-4-mobile is-3 is-flex-touch has-text-weight-bold p-4 m-4">
          Rekentool energielabel verbeteren door hybride warmtepomp
        </h1>
        <div className="columns is-vcentered m-0">
          <div className="column" id="top">
            <div
              className="card"
              style={{ padding: "10px", borderRadius: "20px" }}
            >
              <div className="card-content">
                <SelectInputRowRekenhulp
                  label="Type warmtepomp"
                  name={"typeWarmtepomp"}
                  simpleTooltipText={
                    "In een huishouden gebruikt een persoon gemiddeld 100 kuub gas voor warm water per jaar. Het overgebleven gasgebruik van het huishouden kan gebruikt worden voor een indicatie voor het benodigde vermogen van de warmte pomp."
                  }
                  value={project.typeWarmtepomp}
                  onChange={handleChange}
                  options={pompSoorten}
                />

                <NumberInputRekenhulp
                  label="Gebruikersoppervlakte woning (GO)"
                  name={"GO"}
                  value={project.GO}
                  onChange={handleChange}
                  addonText={"m²"}
                  noDecimals={true}
                  simpleTooltipText={
                    "Betreft het energetisch gebruiksoppervlak (zie energielabel)"
                  }
                />

                <NumberInputRekenhulp
                  label="Aantal bewoners"
                  name={"bewoners"}
                  value={project.bewoners}
                  onChange={handleChange}
                  addonText={"personen"}
                  noDecimals={true}
                />

                <NumberInputRekenhulp
                  label="Gasverbruik totaal"
                  name={"totaalGas"}
                  value={project.totaalGas}
                  onChange={handleChange}
                  addonText={"m³ gas/jaar"}
                  noDecimals={true}
                />

                <NumberInputRekenhulp
                  label="Gasverbruik koken"
                  name={"totaalGasKoken"}
                  value={project.totaalGasKoken}
                  onChange={handleChange}
                  addonText={"m³ gas/jaar"}
                  noDecimals={true}
                />

                <NumberInputRekenhulp
                  label="Kosten gasverbruik"
                  name={"kostenGas"}
                  prefixAddonText={"€"}
                  value={project.kostenGas}
                  addonText={"per m³"}
                  onChange={handleChange}
                  noDecimals={false}
                  step={0.01}
                />
                <NumberInputRekenhulp
                  label="Kosten elektraverbruik"
                  name={"kostenElectra"}
                  prefixAddonText={"€"}
                  value={project.kostenElectra}
                  addonText={"per kWh"}
                  onChange={handleChange}
                  noDecimals={false}
                  step={0.01}
                />

                <NumberInputRekenhulp
                  label="Omrekeningsfactor"
                  name={"factorGasElectra"}
                  value={project.factorGasElectra}
                  addonText={"kWh per m³ gas"}
                  onChange={handleChange}
                  noDecimals={false}
                  step={0.01}
                  simpleTooltipText={"Omrekeningsfactor gas verwarmen naar kWh"}
                />


                <NumberInputRekenhulp
                  label="Besparing gas door warmtepomp"
                  name={"pompBesparing"}
                  value={project.pompBesparing}
                  addonText={"%"}
                  onChange={handleChange}
                  noDecimals={true}
                  step={5}
                />

                <NumberInputRekenhulp
                  label={"Rendement warmtepomp"}
                  name={"pompRendement"}
                  value={project.pompRendement}
                  addonText={"%"}
                  onChange={handleChange}
                  noDecimals={true}
                  step={10}
                />

                <NumberInputRekenhulp
                  label={"Kosten warmtepomp"}
                  name={"pompKosten"}
                  value={project.pompKosten}
                  prefixAddonText={"€"}
                  onChange={handleChange}
                  noDecimals={true}
                  step={10}
                />

                <NumberInputRekenhulp
                  label={"Subsidie"}
                  name={"subsidy"}
                  value={project.subsidy}
                  prefixAddonText={"€"}
                  onChange={handleChange}
                  noDecimals={true}
                  step={10}
                  addonText={"incl. BTW"}
                />

                <NumberInputRekenhulp
                  label={"Levensduur warmtepomp"}
                  extraClass={`${
                    project.pomp.cycle !== project.pompCycle ? "is-warning" : ""
                  }`}
                  name={"pompCycle"}
                  value={project.pompCycle}
                  addonText={"jaar"}
                  onChange={handleChange}
                  noDecimals={true}
                />

                <SelectInputRowRekenhulp
                  label="Huidig energielabel"
                  name={"energyLabel"}
                  simpleTooltipText={
                    "De beste rekenresultaten worden bereikt indien gebruik gemaakt wordt van een NTA 8800 energielabel. Bij een oud label kan er een afwijking ontstaan."
                  }
                  value={project.energyLabel}
                  onChange={handleChange}
                  options={[
                    { id: "A++++", name: "A++++" },
                    { id: "A+++", name: "A+++" },
                    { id: "A++", name: "A++" },
                    { id: "A+", name: "A+" },
                    { id: "A", name: "A" },
                    { id: "B", name: "B" },
                    { id: "C", name: "C" },
                    { id: "D", name: "D" },
                    { id: "E", name: "E" },
                    { id: "F", name: "F" },
                    { id: "G", name: "G" },
                  ]}
                />
              </div>
            </div>
          </div>

          <CalculationResult
            pathname={location.pathname}
            text={project.nettoBesparingTotaal}
            startLabel={project.energyLabel}
            endLabel={project.newEnergyLabel}
          />

          <div className="column" id="bottom">
            <div
              className="card is-shadowless"
              style={{
                padding: "10px",
                borderRadius: "20px",
                backgroundColor: "#D4E3D9",
              }}
            >
              <TableDisplay />
            </div>
          </div>
        </div>

        <p className="content is-italic p-4 m-4">
          <span className="has-text-weight-bold">Disclaimer:</span>
          <br></br>
          De berekeningsmethodiek is naar beste weten samengesteld, maar VAO,
          Koëter Vastgoed Adviseurs en eventuele andere auteurs zijn op geen
          enkele wijze aansprakelijk voor de juistheid of volledigheid van de
          geleverde informatie en/of kostenkengetallen. Met de berekeningen
          worden er handreikingen gegeven die gericht zijn op de benodigde
          investeringen voor een labelsprong. De lezer/gebruiker kan aan de
          uitkomsten geen rechten ontlenen, noch er in het economisch en
          maatschappelijk verkeer vertrouwen aan ontlenen. De berekeningen zijn
          deels vereenvoudigd en zijn niet te vergelijken met de volledige
          berekeningen van een gecertificeerde EPA-U of EPA-W adviseur.
        </p>
      </div>
    </section>
  );
}

export default HybridHeatPump;
