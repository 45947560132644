import { Link } from "react-router-dom";

const CmsIndexNewsArticle = ({ article }) => {
  return (
    <Link to={article.cmsPath}>
      <div className="box">
        <div className="media">
          <div className="media-content">
            {article.image && (
              <figure className="image is-hidden-mobile">
                <img
                  style={{
                    height: "240px",
                    width: "335px",
                    maxWidth: "100%",
                    float: "left",
                    objectFit: "cover",
                    marginLeft: "0px",
                    marginRight: "auto",
                    marginBottom: "1rem",
                    paddingRight: "1rem",
                    borderRadius: "1rem",
                  }}
                  src={article.image.src}
                  alt={article.image.src}
                />
              </figure>
            )}
          </div>
          <div className="media-content">
            <p className="title is-size-5 has-text-weight-bold">
              {article.title}
            </p>
            <p className="has-text-grey-light" style={{ fontSize: "0.8rem" }}>
              {article.publishedOn}
            </p>
            <br />
            <p className="content is-size-6 p-0">
              {article.metaDescription?.substring(0, 75)}
              {"..."}
            </p>
            <button className="button is-primary">Lees meer</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CmsIndexNewsArticle;
