import Loader from "../../components/loader";
import * as states from "../../redux/states";
import * as pages from "../../externalLinks";
import { RevIdentityLinkLink } from "../../components/common/Buttons";
import { FileInput } from "../../components/common/FileInput";
import { RevImgRepportLogo } from "../../components/common/RevImg";
import { formatDateWithLocale } from "../../modules/dateTimeFormats";

const ProfilePage = ({
  userDataState,
  userData,
  subscription,
  apiKey,
  cmsRoles,
  has2FA,
  hasPro,
  onSelectImage,
  userLogo,
  errors,
  endDate,
}) => {
  return (
    <>
      {userDataState !== states.succeeded && <Loader center={true} />}

      {userDataState === states.succeeded && (
        <section className="hero is-medium">
          <header className="hero-body">
            <div className="container">
              <h1 className="title is-size-4-mobile has-text-weight-bold">
                Welkom {userData.given_name}
              </h1>

              {endDate && (
                <h4 className={`subtitle is-6 is-size-4-mobile is-italic`}>
                  Uw abonnement verloopt op {formatDateWithLocale(endDate)}
                </h4>
              )}
              <hr />
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <h2
                className={`subtitle is-4 is-size-5-mobile has-text-weight-bold has-text-warning`}
              >
                Uw huidig abonnement: '{subscription}'
              </h2>

              {cmsRoles.length > 0 && (
                <>
                  <p className="has-text-weight-bold">
                    {" "}
                    Dit omvat onderstaande onderdelen:{" "}
                  </p>
                  {cmsRoles.map((role, i) => {
                    return <p key={i}>{role}</p>;
                  })}
                </>
              )}
              <br></br>
              {!hasPro && (
                <a
                  className="button is-medium is-warning"
                  href={pages.shopUpgrade}
                  target="blank"
                  rel="noopener"
                >
                  Upgrade uw abonnement
                </a>
              )}
              <br />

              <FileInput
                label={"Wijzig rapport logo:"}
                onChange={onSelectImage}
                errors={errors}
              />
              {userLogo && <RevImgRepportLogo src={userLogo} />}
              <hr />

              <RevIdentityLinkLink
                className={"button is-mobile is-danger is-light mb-3"}
                text="Wijzig uw wachtwoord"
              />
              <br></br>
              {!has2FA && (
                <RevIdentityLinkLink
                  className={"button is-mobile is-danger is-light mb-3"}
                  text="Registreer two factor authenticatie"
                  set2FA={true}
                />
              )}

              {has2FA && (
                <RevIdentityLinkLink
                  className={"button is-mobile is-danger is-light mb-3"}
                  text="Wijzig two factor authenticatie"
                  set2FA={true}
                />
              )}

              {apiKey && (
                <>
                  <p className={`has-text-weight-bold m-1`}>
                    Api key: {apiKey}
                  </p>
                </>
              )}
            </div>
          </header>
        </section>
      )}
    </>
  );
};

export default ProfilePage;
